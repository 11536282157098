import { IMessageByUser } from '../../models/Messages/messages';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'MessageSlice';

export interface MessageSliceType {
  isLoading: boolean;
  messages: IMessageByUser[];
}

export const initialState: MessageSliceType = {
  isLoading: false,
  messages: [],
};

export const MessageSelectionReducer = createAndMergeSliceReducer(sliceName, initialState);
