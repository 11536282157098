import React from 'react';
import { SocketBasicMessageAdmin } from '../../../models/Socket/socket-request';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { BitrixServiceClient } from '../../services/bitrix-service';
declare global {
  interface Window {
    BXLiveChat: any;
  }
}

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: 'rgb(18, 18, 18) !important',
  },
  cardContent: {
    minHeight: '200px !important',
  },
  cardActions: {
    display: 'flow-root !important',
  },
  float: {
    float: 'right',
  },
  dialogContent: {
    padding: '20px !important',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    borderBottom: '2px solid #c8a964',
    padding: '10px',
  },
}));

export interface ExternalProps {
  request: SocketBasicMessageAdmin;
  open: boolean;
}

export interface ExternalActionProps {
  setOpen: (data: boolean) => void;
  handlerDialog: () => void;
}

export const DialogSocketMessagesComponent = (props: ExternalProps & ExternalActionProps) => {
  const { request, open, handlerDialog } = props;
  const classes = useStyles();
  const handlerOpenChat = () => {
    BitrixServiceClient.openChat();

    handlerDialog();
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handlerDialog} aria-labelledby='max-width-dialog-title'>
      <DialogContent className={classes.dialogContent}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography dangerouslySetInnerHTML={{ __html: request.message }}></Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {request.showOpenDialogButton && (
              <Button
                variant='contained'
                color='primary'
                className={classes.float}
                onClick={handlerOpenChat}
                style={{ marginRight: '10px' }}
              >
                Open Chat
              </Button>
            )}
            <Button variant='contained' color='secondary' className={classes.float} onClick={handlerDialog} style={{ marginRight: '10px' }}>
              Close
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
