import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { TagComponentsEnum } from '../../../enum/components/component-enum';
import { SocketMessagesDto } from '../../../models/Socket/socket-messages';
import { GetLocalFingerPrint, GetLocalUser } from '../../../shared/functions/common';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { SocketReducer, SocketSliceType } from '../socket-reducer';

const suffix = `/${SocketReducer.sliceName}/app`;

const MARK_NOTIFIED_SO_MESSAGES_PLAYER = `MARK_NOTIFIED_SO_MESSAGES_PLAYER${suffix}`;

export const MarkNotifiedAsReadPlayerAction = createAction<SocketMessagesDto>(MARK_NOTIFIED_SO_MESSAGES_PLAYER);

const MARK_NOTIFIED_SO_MESSAGES_PLAYER_SUCCESS = `MARK_NOTIFIED_SO_MESSAGES_PLAYER_SUCCESS${suffix}`;
const MarkNotifiedAsReadSuccessAction = createAction(MARK_NOTIFIED_SO_MESSAGES_PLAYER_SUCCESS);

const MARK_NOTIFIED_SO_MESSAGES_PLAYER_ERROR = `MARK_NOTIFIED_SO_MESSAGES_PLAYER_ERROR${suffix}`;
const MarkNotifiedAsReadErrorAction = createAction<string>(MARK_NOTIFIED_SO_MESSAGES_PLAYER_ERROR);

function* MarkNotifiedAsReadWatcher(): SagaIterator {
  yield takeEvery(MARK_NOTIFIED_SO_MESSAGES_PLAYER, MarkNotifiedAsReadWorker);
}

mergeSaga(MarkNotifiedAsReadWatcher);

function* MarkNotifiedAsReadWorker(action: Action<SocketMessagesDto>): SagaIterator {
  try {
    yield put(MarkNotifiedAsReadSuccessAction(action.payload));
  } catch (e: any) {
    yield put(MarkNotifiedAsReadErrorAction(e));
  }
}

const reduceHandlers = {
  [MARK_NOTIFIED_SO_MESSAGES_PLAYER]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),

  [MARK_NOTIFIED_SO_MESSAGES_PLAYER_SUCCESS]: (slice: SocketSliceType, action: Action<SocketMessagesDto>): SocketSliceType => {
    const msgs = slice.socketMessages;

    let username: string | null = GetLocalUser();
    if (!username) username = GetLocalFingerPrint();

    msgs.forEach(element => {
      if (element.user_perm_component_id == action.payload.user_perm_component_id) element.isNotified = true;
    });

    try {
      action.payload = { ...action.payload, username: username ?? null };
      if (action.payload.component.tag_component_id == TagComponentsEnum.INTERNAL) {
        let resp: SocketMessagesDto[] = [];
        if (localStorage.getItem('notifications')) {
          resp = JSON.parse(localStorage.getItem('notifications') ?? '');
          if (resp && resp.length && resp.length > 0) {
            const checkIfNotificationsExists = !username
              ? resp.find(x => x.user_perm_component_id == action.payload.user_perm_component_id)
              : resp.find(x => x.user_perm_component_id == action.payload.user_perm_component_id && x.username && x.username == username);
            if (!checkIfNotificationsExists) resp.push(action.payload);
            else {
              resp = !username
                ? resp.filter(x => x.user_perm_component_id != action.payload.user_perm_component_id)
                : resp.filter(
                    x => x.user_perm_component_id != action.payload.user_perm_component_id && x.username && x.username == username,
                  );
              resp.push(action.payload);
            }
          } else {
            resp.push(action.payload);
          }
        } else {
          resp.push(action.payload);
        }
        localStorage.removeItem('notifications');
        localStorage.setItem('notifications', JSON.stringify(resp));
      }
    } catch (error) {
      console.log('MarkNotifiedAsReadPlayerAction', { error });
    }

    return {
      ...slice,
      socketMessages: msgs,
    };
  },

  [MARK_NOTIFIED_SO_MESSAGES_PLAYER_ERROR]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),
};

SocketReducer.addActionReducerMap(reduceHandlers);
