import MockAdapter from 'axios-mock-adapter';
import { mockApi as landingPagesMockApi } from './api/landing-page/mock/landing-pages.mock-api';
import { mockApi as loginMockApi } from './api/user/mock/login.mock-api';

export function setupMock(appAxios: any): any {
  const axiosMock = new MockAdapter(appAxios, { delayResponse: 800 });

  loginMockApi(axiosMock);
  landingPagesMockApi(axiosMock);
  axiosMock.onAny().passThrough();
}
