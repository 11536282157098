import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { CashierApiResponse } from '../../../models/cashier-response';
import { API_PATH } from '../../../constants';

const suffix = `/${userReducer.sliceName}/app`;

const VALIDATE_EMAIL_VERIFICATION_CODE = `VALIDATE_EMAIL_VERIFICATION_CODE${suffix}`;
export const ValidateEmailVerificationCodeAction = createAction<string>(VALIDATE_EMAIL_VERIFICATION_CODE);

const VALIDATE_EMAIL_VERIFICATION_CODE_ERROR = `VALIDATE_EMAIL_VERIFICATION_CODE_ERROR${suffix}`;
const ValidateEmailVerificationCodeErrorAction = createAction(VALIDATE_EMAIL_VERIFICATION_CODE_ERROR);
const VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS = `VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS${suffix}`;
const ValidateEmailVerificationCodeSuccessAction = createAction(VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS);

function* ValidateEmailVerificationCodeWatcher(): SagaIterator {
  yield takeEvery(VALIDATE_EMAIL_VERIFICATION_CODE, ValidateEmailVerificationCodeWorker);
}
mergeSaga(ValidateEmailVerificationCodeWatcher);

function* ValidateEmailVerificationCodeWorker(action: Action<string>): SagaIterator {
  try {
    const response: CashierApiResponse = yield call(ValidateEmailVerificationCodeApi, action.payload);
    yield put(ValidateEmailVerificationCodeSuccessAction(response.data));
    yield call(showToast, response);
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(ValidateEmailVerificationCodeErrorAction(e.response.data));
  }
}

const ValidateEmailVerificationCodeApi = (data: string): Request => {
  return vipApi(API_PATH.USER.VALIDATE_EMAIL_VERIFICATION_CODE, 'post', { code: data }, 'json');
};

const reduceHandlers = {
  [VALIDATE_EMAIL_VERIFICATION_CODE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    isVerificationSuccess: null,
  }),
  [VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS]: (slice: UserSliceType): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      isVerificationSuccess: true,
    };
  },
  [VALIDATE_EMAIL_VERIFICATION_CODE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
    isVerificationSuccess: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
