import React from 'react';

export const VipCrownIcon = (props: any) => {
  const { style } = props;
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' fill='none' style={style} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 20L4.2602 20.1233C4.32048 20.4849 4.63337 20.75 5 20.75V20ZM19 20V20.75C19.3666 20.75 19.6795 20.4849 19.7398 20.1233L19 20ZM15.5 12.5L14.8673 12.9027C14.9824 13.0836 15.1694 13.2065 15.3811 13.2405C15.5928 13.2745 15.809 13.2162 15.9749 13.0805L15.5 12.5ZM8.5 12.5L8.02507 13.0805C8.19103 13.2162 8.40716 13.2745 8.61887 13.2405C8.83059 13.2065 9.01763 13.0836 9.13275 12.9027L8.5 12.5ZM2.2602 8.1233L4.2602 20.1233L5.7398 19.8767L3.7398 7.8767L2.2602 8.1233ZM5 20.75H19V19.25H5V20.75ZM19.7398 20.1233L21.7398 8.1233L20.2602 7.8767L18.2602 19.8767L19.7398 20.1233ZM20.5251 7.41953L15.0251 11.9195L15.9749 13.0805L21.4749 8.58047L20.5251 7.41953ZM2.52507 8.58047L8.02507 13.0805L8.97493 11.9195L3.47493 7.41953L2.52507 8.58047ZM9.13275 12.9027L12.6327 7.40266L11.3673 6.59734L7.86725 12.0973L9.13275 12.9027ZM11.3673 7.40266L14.8673 12.9027L16.1327 12.0973L12.6327 6.59734L11.3673 7.40266ZM12.75 5.5C12.75 5.91421 12.4142 6.25 12 6.25V7.75C13.2426 7.75 14.25 6.74264 14.25 5.5H12.75ZM12 6.25C11.5858 6.25 11.25 5.91421 11.25 5.5H9.75C9.75 6.74264 10.7574 7.75 12 7.75V6.25ZM11.25 5.5C11.25 5.08579 11.5858 4.75 12 4.75V3.25C10.7574 3.25 9.75 4.25736 9.75 5.5H11.25ZM12 4.75C12.4142 4.75 12.75 5.08579 12.75 5.5H14.25C14.25 4.25736 13.2426 3.25 12 3.25V4.75ZM3.75 6.5C3.75 6.91421 3.41421 7.25 3 7.25V8.75C4.24264 8.75 5.25 7.74264 5.25 6.5H3.75ZM3 7.25C2.58579 7.25 2.25 6.91421 2.25 6.5H0.75C0.75 7.74264 1.75736 8.75 3 8.75V7.25ZM2.25 6.5C2.25 6.08579 2.58579 5.75 3 5.75V4.25C1.75736 4.25 0.75 5.25736 0.75 6.5H2.25ZM3 5.75C3.41421 5.75 3.75 6.08579 3.75 6.5H5.25C5.25 5.25736 4.24264 4.25 3 4.25V5.75ZM21.75 6.5C21.75 6.91421 21.4142 7.25 21 7.25V8.75C22.2426 8.75 23.25 7.74264 23.25 6.5H21.75ZM21 7.25C20.5858 7.25 20.25 6.91421 20.25 6.5H18.75C18.75 7.74264 19.7574 8.75 21 8.75V7.25ZM20.25 6.5C20.25 6.08579 20.5858 5.75 21 5.75V4.25C19.7574 4.25 18.75 5.25736 18.75 6.5H20.25ZM21 5.75C21.4142 5.75 21.75 6.08579 21.75 6.5H23.25C23.25 5.25736 22.2426 4.25 21 4.25V5.75Z'
        fill='#c8a964'
      />
    </svg>
  );
};
