import { toastr } from 'react-redux-toastr';
import { TOASTR_OPTIONS } from '../../constants';
export function showReason(reason: any): void {
  showError(reason);
}
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function showError(error: any): void {
  let logTitle = 'Error';
  let msgObj = error;
  if (error.response && error.response.data) {
    msgObj = error.response.data;
    logTitle = error.toString();
  }
  const msg = msgObj.message ? msgObj.message : msgObj.toString();
  console.error(logTitle, '-', msg);
}

export function showErrors(promise: any): Promise<any> {
  return promise.catch((reason: any) => {
    showError(reason);
    return Promise.reject(reason);
  });
}

export const showToast = (error: any, type?: any) => {
  if (error?.messages) {
    const _errors = error?.messages;
    for (const item in _errors) {
      if (+item > 4) return;
      setTimeout(async () => await getToast(_errors[item]), +item * 1500);
    }
  } else getStaticToast(error, type);
};

const getToast = (message: any) => {
  return new Promise((resolve: any) => {
    switch (message.color) {
      case 0: //Green
        toastr.success('Success', message?.msg, TOASTR_OPTIONS);
        break;
      case 1: //Blue
        toastr.info('', message?.msg, TOASTR_OPTIONS);
        break;
      case 2: //Yellow
        toastr.warning('', message?.msg, TOASTR_OPTIONS);
        break;
      case 3: //Red
        toastr.error('', message?.msg, TOASTR_OPTIONS);
        break;
      default:
        break;
    }
    return resolve();
  });
};

const getStaticToast = (message: any, type: any) => {
  if (type == 'error') toastr.error('', message, TOASTR_OPTIONS);
  else if (type == 'success') toastr.success('Success', message, TOASTR_OPTIONS);
  else if (type == 'warning') toastr.warning('', message, TOASTR_OPTIONS);
  else if (type == 'info') toastr.info('', message, TOASTR_OPTIONS);
};

export function showToastV2(pMessages: any): void {
  const messages = pMessages ? pMessages : [];
  messages.forEach(async (message: any, index: number) => {
    const delay = index * 1000;
    await sleep(delay);
    const msg = message.msg;
    const timeOut = 5000;
    switch (message.color) {
      case 0: //Green
        toastr.success('Success', msg, { timeOut });
        break;
      case 1: //BLUE
        toastr.info('Info', msg, { timeOut });
        break;
      case 2: //YELLOW
        toastr.warning('Warning', msg, { timeOut });
        break;
      case 3: //RED
        toastr.error('Error', msg, { timeOut });
        break;
      default:
        break;
    }
  });
}
