import { Right } from '../login';
import { IRight } from '../rights';

export interface ICasinoLobbyV2 {
  categories: CasinoCategory[];
  providers: Right[];
}

export interface CasinoCategory {
  category_id: number;
  category_description: string;
  category_icon: any;
  category_class: string;
  category_order: number;
  category_image: string;
  games: CasinoGameDto[];
}

export interface CasinoGameDto {
  category_id: number;
  category_description: string;
  category_icon: string;
  category_class: string;
  category_order: number;
  category_image: string;
  game_id: number;
  game_description: string;
  game_icon: string;
  game_class: string;
  game_image: string;
  game_device_source_id: string;
  game_order: number;
  game_updated_at: Date;
  provider_id: number;
  provider_description: string;
  provider_image: string;
  favorite_games_by_player_id?: number;
  favorite_games_by_player_game_id?: number;
  favorite_games_by_player_is_favorite?: boolean;
  isNew: boolean;
  isHot: boolean;
  tags: any;

  has_free_rounds?: boolean;
  has_jackpot?: boolean;
  has_gamble?: boolean;
  has_auto_spin?: boolean;
}

export interface GameRatingDto {
  id: number;
  tpi_game_id: number;
  rating: number;
  comment: string;
}

export interface GameFavoriteResponseDto {
  id: number;
  game_id: number;
  is_favorite: number;
}

export interface IProvider extends IRight {
  games: CasinoGameDto[];
}

export interface IGetGameRequestDto {
  device_id: number;
  categories: string;
  providers: string;
  use_free_play: number | null;
}

export const defaultCategory: CasinoCategory = {
  category_class: '',
  category_description: '',
  category_icon: '',
  category_id: -1,
  category_image: '',
  category_order: -1,
  games: [],
};
