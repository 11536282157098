import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { LiveDealerViewEnum } from '../../../enum/tpi/LiveDealerView';
import { Right } from '../../../models/login';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
    border: 'none',
    height: '85%',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute',
  },
  disabledA: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  containerHref: {
    height: '100px',
    lineHeight: '100px',
  },
  description: {
    color: '#E3DAC9',
  },
});

export interface ExternalProps {
  isLoading: boolean;
  gameUrl: any;
  providerSelected: Right;
  LiveDealerView: number;
  userDevice: number;
  is_freeplay: number;
}

export interface ExternalActionProps {
  openCasinoGameAction: (x: any) => void;
}

export const LiveDealerDisplayIframeComponent = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();

  const { LiveDealerView, gameUrl, openCasinoGameAction, providerSelected, userDevice, is_freeplay } = props;

  useEffect(() => {
    openCasinoGameAction({
      device_id: userDevice,
      site_id: 2,
      provider_id: providerSelected.id,
      game_id: -1,
      is_freeplay: is_freeplay,
    });
  }, []);

  return (
    <>
      {LiveDealerView == LiveDealerViewEnum.LIVE_DEALER_ONLY ? (
        <iframe className={clsx(classes.root)} src={gameUrl} height='100%' />
      ) : (
        <iframe className={clsx(classes.root)} src={gameUrl} height='100%' />
      )}
    </>
  );
};
