import React from 'react';

export const VipExchangeIcon = (props: any) => {
  const { style } = props;

  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 511.984 511.984' style={style}>
      <g>
        <path
          style={{ fill: '#434A54' }}
          d='M466.75,137.152c-22.232-25.983-44.436-39.139-45.373-39.686l0,0
		c-1.578-0.938-3.422-1.469-5.391-1.469c-5.891,0-10.672,4.766-10.672,10.656c0,3.922,2.125,7.343,5.281,9.203l0,0
		c0.188,0.125,20.016,11.875,39.953,35.171c11.688,13.641,20.982,28.437,27.639,43.937c8.266,19.296,12.469,39.826,12.469,61.029
		c0,38.31-13.344,73.435-39.623,104.402c-19.938,23.469-40.25,35.609-40.438,35.719l10.781,18.404
		c0.938-0.547,23.141-13.717,45.373-39.686c29.594-34.576,45.234-75.67,45.234-118.839
		C511.984,212.806,496.344,171.714,466.75,137.152z'
        />
        <path
          style={{ fill: '#434A54' }}
          d='M101.403,396.129L101.403,396.129c-0.203-0.125-20.515-12.266-40.452-35.734
		c-26.28-30.967-39.623-66.092-39.623-104.403c0-38.327,13.343-73.451,39.624-104.403c19.937-23.483,40.249-35.624,40.436-35.733
		l-10.781-18.39c-0.938,0.547-23.14,13.703-45.374,39.686C15.64,171.714,0,212.806,0,255.992c0,43.169,15.64,84.263,45.233,118.839
		c22.234,25.969,44.436,39.139,45.374,39.686v-0.016c1.578,0.938,3.422,1.484,5.391,1.484c5.89,0,10.671-4.781,10.671-10.672
		C106.669,401.41,104.544,397.988,101.403,396.129z'
        />
      </g>
      <path
        style={{ fill: '#967ADC' }}
        d='M362.66,405.314c0,58.906-47.764,106.67-106.668,106.67s-106.668-47.764-106.668-106.67
	c0-58.902,47.764-106.668,106.668-106.668S362.66,346.412,362.66,405.314z'
      />
      <g>
        <path
          style={{ fill: '#656D78' }}
          d='M469.313,394.644h-42.654v-42.656c0-5.889-4.781-10.67-10.672-10.67s-10.672,4.781-10.672,10.67
		v53.326c0,5.891,4.781,10.672,10.672,10.672h53.326c5.891,0,10.672-4.781,10.672-10.672
		C479.984,399.426,475.203,394.644,469.313,394.644z'
        />
        <path
          style={{ fill: '#656D78' }}
          d='M95.997,95.997H42.671c-5.89,0-10.671,4.781-10.671,10.656c0,5.906,4.781,10.671,10.671,10.671
		h42.655v42.671c0,5.891,4.781,10.656,10.672,10.656c5.89,0,10.671-4.765,10.671-10.656v-53.342
		C106.669,100.779,101.888,95.997,95.997,95.997z'
        />
      </g>
      <path
        style={{ fill: '#F6BB42' }}
        d='M353.77,95.997c-16.451-37.671-54.045-63.998-97.777-63.998c-43.733,0-81.326,26.327-97.778,63.998
	l-8.891,10.656v31.999c0,58.92,47.764,106.668,106.668,106.668S362.66,197.572,362.66,138.652v-31.999L353.77,95.997z'
      />
      <g>
        <path
          style={{ fill: '#E8AA3D' }}
          d='M245.321,244.789c3.516,0.359,7.078,0.531,10.671,0.531c3.593,0,7.156-0.172,10.672-0.531V198.65
		h-21.343V244.789z'
        />
        <path style={{ fill: '#E8AA3D' }} d='M202.666,231.055c6.687,3.859,13.828,7.031,21.327,9.375v-53.107h-21.327V231.055z' />
        <path style={{ fill: '#E8AA3D' }} d='M309.318,231.055v-41.733H287.99v51.107C295.49,238.086,302.632,234.914,309.318,231.055z' />
      </g>
      <path
        style={{ fill: '#AC92EB' }}
        d='M362.66,373.316c0,58.904-47.764,106.668-106.668,106.668S149.324,432.22,149.324,373.316
	s47.764-106.668,106.668-106.668S362.66,314.412,362.66,373.316z'
      />
      <path
        style={{ fill: '#E6E9ED' }}
        d='M330.663,373.316c0,41.232-33.438,74.67-74.67,74.67c-41.233,0-74.669-33.438-74.669-74.67
	s33.437-74.67,74.669-74.67C297.224,298.646,330.663,332.084,330.663,373.316z'
      />
      <polygon
        style={{ fill: '#AC92EB' }}
        points='255.992,394.863 230.774,408.111 235.602,380.035 215.197,360.16 243.383,356.066 
	255.992,330.521 268.602,356.066 296.788,360.16 276.383,380.035 281.21,408.111 '
      />
      <path
        style={{ fill: '#FFCE54' }}
        d='M362.66,106.653c0,58.92-47.764,106.668-106.668,106.668s-106.668-47.748-106.668-106.668
	C149.324,47.749,197.088,0,255.992,0S362.66,47.749,362.66,106.653z'
      />
      <path
        style={{ fill: '#E8AA3D' }}
        d='M255.992,63.998c-5.89,0-10.671,4.766-10.671,10.656v63.998c0,5.906,4.781,10.671,10.671,10.671
	c5.891,0,10.672-4.766,10.672-10.671V74.655C266.664,68.764,261.883,63.998,255.992,63.998z'
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
