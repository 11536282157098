import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'navSlice';

export interface NavSliceType {
  title: string;
}

const initialState: NavSliceType = {
  title: 'Home',
};

export const reducer = createAndMergeSliceReducer(sliceName, initialState);
