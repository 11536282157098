import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'promotionSelectionSlice';

export interface PromotionSelectionSliceType {
  isLoading: boolean;
  isSaving: boolean;
  promotions: any[];
}

export const initialState: PromotionSelectionSliceType = {
  isLoading: false,
  isSaving: false,
  promotions: [],
};

export const promotionSelectionReducer = createAndMergeSliceReducer(sliceName, initialState);
