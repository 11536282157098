import { Switch, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import { NotFoundPage } from './area/not-found/not-found-page.component';
import { lazily } from 'react-lazily';
import { Loading } from './shared/loading/loading.component';
import { LiveDealerContainer } from './area/live-dealer/live-dealer.container';
import { VerifyEmailPageContainer } from './area/auth/verify-email/verify-email-page.container';

//React Lazy Loading by Route
//Lazily is a wrapper that allows to destruct imported module, so it will work with non default exports
const { LoginPageContainer } = lazily(() => import('./area/auth/login-page/login-page.container'));
const { SignupPageContainer } = lazily(() => import('./area/auth/signup-page/signup-page.container'));
const { AdminPrivateRouteContainer } = lazily(() => import('./shared/private-route/admin-private-route.container'));
const { ForgetPasswordPageContainer } = lazily(() => import('./area/auth/forget-password-page/forgot-password-page.container'));
const { PromotionsPageContainer } = lazily(() => import('./area/promotions/promotions.container'));
const { ReferAFriendPageContainer } = lazily(() => import('./area/raf/raf.container'));
const { RulesPageContainer } = lazily(() => import('./area/rules/rules.container'));
const { GuidesPageContainer } = lazily(() => import('./area/guides/guides.container'));
const { VerifyMobilePageContainer } = lazily(() => import('./area/auth/verify-mobile-page/verify-mobile-page.container'));
const { ResetPasswordPageContainer } = lazily(() => import('./area/auth/reset-password-page/reset-password-page.container'));
const { PlayerHistoryPageContainer } = lazily(() => import('./area/player-history/player-history.container'));
const { PendingBetsPageContainer } = lazily(() => import('./area/pending-bets/pending-bets.container'));
const { ESportsPageContainer } = lazily(() => import('./area/esports/esports.container'));
const { BonusHistoryPageContainer } = lazily(() => import('./area/bonus-history/bonus-history.container'));
const { TransactionHistoryPageContainer } = lazily(() => import('./area/transaction-history/transaction-history.container'));
const { LiveChatPageContainer } = lazily(() => import('./area/live-chat/live-chat.container'));
const { ProfilePageContainer } = lazily(() => import('./area/profile-settings/profile-page.container'));
const { AgentDashboardpageContainer } = lazily(() => import('./area/agent-dashboard/agent-page.container'));
const { LobbyProviderContainer } = lazily(() => import('./area/lobby-provider/lobby-provider.container'));
const { BracketPageContainer } = lazily(() => import('./area/bracket/bracket-page.container'));
const { SportsBookPageContainer } = lazily(() => import('./area/sportsbook/sportsbook.container'));
const { LiveSportsPageContainer } = lazily(() => import('./area/live-sports/live-sports.container'));
const { CasinoLobbyV2PageContainer } = lazily(() => import('./area/casino-lobby-v2/CasinoLobbyV2.container'));
const { PicksPageContainer } = lazily(() => import('./area/picks/picks-page.container'));
const { LoyaltyContainer } = lazily(() => import('./area/loyalty/loyalty.component'));
const { InternalResetPasswordContainer } = lazily(() => import('./area/reset-password/internal-reset-password.container'));

// const { TransactionMethodPageContainer } = lazily(() => import('./area/cashier/transaction-method-page/transaction-method-page.container'));
// const { BonusSelectPageContainer } = lazily(() => import('./area/cashier/bonus-select-page/bonus-select-page.container'));
// const { TransactionInfoPageContainer } = lazily(() => import('./area/cashier/transaction-info-page/transaction-info-page.container'));
// const { RequestReceivedPageContainer } = lazily(() => import('./area/cashier/request-received-page/request-received-page.container'));
// const { CompletePagePageContainer } = lazily(() => import('./area/cashier/complete-page/complete-page.container'));
// const { PlayerBankSelectPageContainer } = lazily(() => import('./area/cashier/player-bank-select-page/player-bank-select-page.container'));
// const { CashierPageContainer } = lazily(() => import('./area/cashier/cashier-home-page/cashier.container'));
// const { ConfirmTransactionPageContainer } = lazily(
//   () => import('./area/cashier/confirm-transaction-page/confirm-transaction-page.container'),
// );
export const Routes = (): React.ReactElement<void> => (
  <Suspense fallback={<Loading loading={true} />}>
    <Switch>
      <Route exact path='/signup/:code?' component={SignupPageContainer} />
      <Route exact path='/login' component={LoginPageContainer} />
      {/* <Route exact path='/login-2' component={LoginHorizonPageContainer} /> */}
      <Route exact path='/forget-password' component={ForgetPasswordPageContainer} />
      <Route exact path='/reset-password/:code' component={ResetPasswordPageContainer} />
      <Route exact path='/validate-mobile' component={VerifyMobilePageContainer} />
      <Route exact path='/validate-mobile' component={VerifyMobilePageContainer} />
      <Route exact path='/house-rules' component={RulesPageContainer} />
      <Route exact path='/verify-email/:code' component={VerifyEmailPageContainer} />

      <AdminPrivateRouteContainer path='/horses' component={LobbyProviderContainer} />
      <AdminPrivateRouteContainer path='/racebook' component={LobbyProviderContainer} />
      <AdminPrivateRouteContainer path='/live-dealer' component={LiveDealerContainer} />
      <AdminPrivateRouteContainer path='/props-builder' component={LobbyProviderContainer} />
      <AdminPrivateRouteContainer path='/sportsbook' component={SportsBookPageContainer} />
      <AdminPrivateRouteContainer path='/live-sports' component={LiveSportsPageContainer} />
      <AdminPrivateRouteContainer path='/esports' component={ESportsPageContainer} />
      <AdminPrivateRouteContainer path='/casino/:type/:bonus' component={CasinoLobbyV2PageContainer} />
      <AdminPrivateRouteContainer path='/casino' component={CasinoLobbyV2PageContainer} />

      <AdminPrivateRouteContainer path='/promotions' component={PromotionsPageContainer} />
      <AdminPrivateRouteContainer path='/refer-a-friend' component={ReferAFriendPageContainer} />
      <AdminPrivateRouteContainer path='/rules' component={RulesPageContainer} />
      <AdminPrivateRouteContainer path='/guides' component={GuidesPageContainer} />
      <AdminPrivateRouteContainer path='/player-history' component={PlayerHistoryPageContainer} />
      <AdminPrivateRouteContainer path='/pending-bets' component={PendingBetsPageContainer} />
      <AdminPrivateRouteContainer path='/bonus-history' component={BonusHistoryPageContainer} />
      <AdminPrivateRouteContainer path='/transaction-history' component={TransactionHistoryPageContainer} />
      <AdminPrivateRouteContainer path='/live-chat' component={LiveChatPageContainer} />
      <AdminPrivateRouteContainer path='/profile' component={ProfilePageContainer} />
      <AdminPrivateRouteContainer path='/agent' component={AgentDashboardpageContainer} />
      <AdminPrivateRouteContainer path='/internal-reset-password' component={InternalResetPasswordContainer} />

      {/* Cashier Routes */}
      {/* <AdminPrivateRouteContainer path='/cashier/complete' component={CompletePagePageContainer} />
      <AdminPrivateRouteContainer path='/cashier/payment' component={RequestReceivedPageContainer} />
      <AdminPrivateRouteContainer path='/cashier/confirm' component={ConfirmTransactionPageContainer} />
      <AdminPrivateRouteContainer path='/cashier/info' component={TransactionInfoPageContainer} />
      <AdminPrivateRouteContainer path='/cashier/bonus' component={BonusSelectPageContainer} />
      <AdminPrivateRouteContainer path='/cashier/bank-select' component={PlayerBankSelectPageContainer} />
      <AdminPrivateRouteContainer path='/cashier/method' component={TransactionMethodPageContainer} />
      <AdminPrivateRouteContainer path='/cashier' component={CashierPageContainer} /> */}

      {/* Remove CasinoLobbyV2 */}

      <AdminPrivateRouteContainer path='/casinolobbyv2/:type/:bonus' component={CasinoLobbyV2PageContainer} />
      <AdminPrivateRouteContainer path='/casinolobbyv2' component={CasinoLobbyV2PageContainer} />
      <AdminPrivateRouteContainer path='/bracket' component={BracketPageContainer} />
      <AdminPrivateRouteContainer path='/picks' component={PicksPageContainer} />
      <AdminPrivateRouteContainer path='/loyalty' component={LoyaltyContainer} />
      <AdminPrivateRouteContainer path='/' component={SportsBookPageContainer} />
      <Route path='' component={NotFoundPage} />
    </Switch>
  </Suspense>
);
