import { createSelector } from 'reselect';
import { gameSelectionReducer, GameSelectionSliceType } from './game-selection.reducer';

export const gameSelectionSliceSelector = (state: any): any => state[gameSelectionReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.isLoading,
);

export const gamesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.games,
);

export const linesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.lines,
);

export const betResponsesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.betResponses,
);

export const previewBetResponsesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.previewBetResponses,
);

export const createBetResponsesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.createBetResponses,
);

export const pendingBetsSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.pendingBets,
);

export const betPlacedSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.betPlaced,
);

export const teasersSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.teasers,
);

export const tvOnOffResponsesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.tvOnOffResponses,
);

export const tvUrlSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.tvUrl,
);

export const isSetUpSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.isSetUp,
);

export const playerLinesSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.playerLines,
);
export const betResultsSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.betResults,
);

export const leagueSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.league,
);

export const wagerTypeSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.wagerType,
);

export const stepWagerSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.stepWager,
);

export const teaserSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.teaser,
);

export const rightOpenSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.rightOpen,
);

export const tvChannelsListSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.tvChannelsList,
);

export const ApiErrorMessageSelector = createSelector(
  gameSelectionSliceSelector,
  (gameSelectionSlice: GameSelectionSliceType) => gameSelectionSlice.apiErrorMessage,
);
