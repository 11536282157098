import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';

interface InternalProps {
  classes: any;
}

function NotFoundPageComp(props: InternalProps): React.ReactElement<InternalProps> {
  const { classes } = props;
  return (
    <article className={classes.root}>
      <div>
        <img src='//i.imgur.com/l8LnIGg.png' alt='tow-truck' />
      </div>
      <h6>Not Found</h6>
      <body>Sorry the page you requested was not found.</body>
    </article>
  );
}

const styles: any = createStyles({
  root: {
    textAlign: 'center',
    marginTop: 150,
  },
  title: {
    marginTop: 15,
  },
  description: {
    marginTop: 15,
  },
});

export const NotFoundPage = withStyles(styles)(NotFoundPageComp);
