import { createSelector } from 'reselect';
import { TransactionContestReducer, TransactionContestSliceType } from './transaction-contest.reducer';

export const TransactionContestSliceSelector = (state: any): any => state[TransactionContestReducer.sliceName];
export const isLoadingTransactionContestSliceSelector = createSelector(
  TransactionContestSliceSelector,
  (transactionContestSliceSelector: TransactionContestSliceType) => transactionContestSliceSelector.isLoading,
);

export const transactionContestSliceSelector = createSelector(
  TransactionContestSliceSelector,
  (transactionContestSliceSelector: TransactionContestSliceType) => transactionContestSliceSelector.transactionContest,
);

export const openDialogContestSliceSelector = createSelector(
  TransactionContestSliceSelector,
  (transactionContestSliceSelector: TransactionContestSliceType) => transactionContestSliceSelector.openDialog,
);
