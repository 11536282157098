import { connect } from 'react-redux';
import { openCasinoGameAction } from '../../api/tpi/actions/open-game.action';
import { isLoadingSliceSelector, gameUrlSliceSelector, gameRatingsSliceSelector, casinoLobbySelector } from '../../api/tpi/tpi.selector';
import { pathSelector } from '../../shared/nav/router.selectors';
import { ExternalActionProps, ExternalProps, LiveDealerComponent } from './live-dealer.component';
import {
  UserInfoSelector,
  userBalanceSelector,
  isLoadingSelector as isLoadingUser,
  isLoadingUserInfoSelector,
} from '../../api/user/user.selector';
import { getUserBalanceAction } from '../../api/user/actions/get-balance.action';
import { openCasinoGameActionV2 } from '../../api/tpi/actions/open-game.actionV2';
import { addUpdateFavoriteGamePlayerAction } from '../../api/tpi/actions/add-update-favorite-game-player';
import { addUpdateGameRatingPlayerAction } from '../../api/tpi/actions/add-update-game-rating';
import { getGameRatingAction } from '../../api/tpi/actions/get-game-rating.action';
import { getCasinoLobbyAction, clearCasinoLobbyAction } from '../../api/tpi/actions/get-casino-lobby.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state) || isLoadingUser(state) || isLoadingUserInfoSelector(state),
    gameUrl: gameUrlSliceSelector(state),
    path: pathSelector(state),
    user: UserInfoSelector(state),
    gameRatings: gameRatingsSliceSelector(state),
    userBalance: userBalanceSelector(state),
    casinoLobby: casinoLobbySelector(state),
  };
}
const mapDispatchToProps: ExternalActionProps = {
  addUpdateFavoriteGamePlayerAction,
  addUpdateGameRatingPlayerAction,
  clearCasinoLobbyAction,
  getCasinoLobbyAction,
  getGameRatingAction,
  getUserBalanceAction,
  openCasinoGameAction,
  openCasinoGameActionV2,
};

export const LiveDealerContainer = connect(mapStateToProps, mapDispatchToProps)(LiveDealerComponent);
