import React, { useRef, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
// import { CashierPageContainer } from '../../../../area/cashier/cashier-home-page/cashier.container';
// import { TransactionInfoPageContainer } from '../../../../area/cashier/transaction-info-page/transaction-info-page.container';
// import { TransactionMethodPageContainer } from '../../../../area/cashier/transaction-method-page/transaction-method-page.container';
// import { ConfirmTransactionPageContainer } from '../../../../area/cashier/confirm-transaction-page/confirm-transaction-page.container';
// import { RequestReceivedPageContainer } from '../../../../area/cashier/request-received-page/request-received-page.container';
// import { CompletePagePageContainer } from '../../../../area/cashier/complete-page/complete-page.container';
// import { BonusSelectPageContainer } from '../../../../area/cashier/bonus-select-page/bonus-select-page.container';
// import { PlayerBankSelectPageContainer } from '../../../../area/cashier/player-bank-select-page/player-bank-select-page.container';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles: any = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .75)',
  },
  dialogeHeader: {
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 10px',
    alignItems: 'center',
    '& p': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
});

export interface ExternalProps {
  cashierDialog: any;
  user: any;
}

export interface ExternalActionProps {
  onClose: () => void;
}

export const CashierDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { cashierDialog, onClose, user } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLocal, setIsLocal] = useState<boolean>(true);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [available, setAvailable] = useState<boolean>(true);

  useEffect(() => {
    if (
      user.reset_password_required != null &&
      user.reset_password_required == true &&
      (user.reset_password_expiration_minutes_left || (user.reset_password_expiration_minutes_left ?? 1) > 0) &&
      localStorage.getItem('login_process') != null
    ) {
      setAvailable(false);
    } else {
      setAvailable(true);
    }
  }, [user]);

  // UnComment to use regular cashier
  // const getComponent = () => {
  //   const type = cashierDialog?.type;
  //   switch (type) {
  //     case 'cashier':
  //       return <CashierPageContainer />;
  //       break;
  //     case 'cashier/method':
  //       return <TransactionMethodPageContainer />;
  //       break;
  //     case 'cashier/info':
  //       return <TransactionInfoPageContainer />;
  //       break;
  //     case 'cashier/confirm':
  //       return <ConfirmTransactionPageContainer />;
  //       break;
  //     case 'cashier/complete':
  //       return <CompletePagePageContainer />;
  //       break;
  //     case 'cashier/payment':
  //       return <RequestReceivedPageContainer />;
  //       break;
  //     case 'cashier/bonus':
  //       return <BonusSelectPageContainer />;
  //       break;
  //     case 'cashier/bank-select':
  //       return <PlayerBankSelectPageContainer />;
  //       break;
  //   }
  // };

  useEffect(() => {
    switch (window.location.hostname) {
      case 'localhost':
        setBaseUrl('http://localhost:3002/ext-login');
        break;

      case 'dev-wager.vipclub.lv':
        setBaseUrl('https://dev-cashier.vipclub.lv/ext-login');
        setIsLocal(false);
        break;

      case 'staging-wager.vipclub.lv':
        setBaseUrl('https://staging-cashier.vipclub.lv/ext-login');
        setIsLocal(false);
        break;

      case 'wager-sandbox.vipclub.lv':
        setBaseUrl('https://cashier-sandbox.vipclub.lv/ext-login');
        setIsLocal(false);
        break;

      case 'vipclub.lv':
      case 'www.vipclub.lv':
        setBaseUrl('https://cashier.vipclub.lv/ext-login');
        setIsLocal(false);
        break;

      default:
        setBaseUrl('http://localhost:3000/ext-login');
        break;
    }

    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && !isLocal && iframe.contentWindow.document) {
      const height = iframe.contentWindow.document.body.scrollHeight;
      iframe.contentWindow.postMessage('load');
      iframe.contentWindow.postMessage({ data: { ht: `${height}px` }, action: 'set-height' });
    }
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={cashierDialog?.isShow}
        onClose={onClose}
        TransitionComponent={Transition as React.ComponentType}
        maxWidth='md'
        fullWidth
        aria-labelledby='max-width-dialog-title'
        className={classes.root}
        style={{ maxHeight: '99vh' }}
      >
        <div className={classes.dialogeHeader}>
          <Typography>Cashier</Typography>
          <IconButton
            aria-label='close'
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 2,
              color: 'white',
            }}
          >
            <CloseIcon style={{ fontSize: '17px' }} />
          </IconButton>
        </div>

        {/* UnComment to bring back old Cashier */}
        {/* <DialogContent style={{ backgroundColor: '#121212', padding: '8px 1px' }}>{getComponent()}</DialogContent> */}

        <DialogContent style={{ padding: 0 }}>
          {/* <Grid item xs={12} style={{ padding: '1px 8px' }}>
            <Alert severity='error' style={{ marginTop: '.5rem' }}>
              <AlertTitle>
                {`Dear VIP customer.
                          "Cash App" will be unavailable tonight due to connection issues with Cash App. We apologize for the
                          inconvenience! VIP TEAM`}
              </AlertTitle>
            </Alert>
          </Grid> */}
          {available && (
            <iframe
              title='iframeCashier'
              src={`${baseUrl}?username=${user.username}&token=${localStorage.getItem('token') ?? '-1'}`}
              width='100%'
              ref={iframeRef}
              style={{ height: '70vh', border: '0px' }}
              allow='clipboard-read; clipboard-write'
            />
          )}

          {!available && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Typography variant='h6' style={{ color: 'white' }}>
                Please reset your password to continue.
              </Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
