import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { getUserBalanceAction } from '../../user/actions/get-balance.action';
import { API_PATH } from '../../../constants';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const OPEN_CASINO_GAME = `OPEN_CASINO_GAME${suffix}V2`;

export const openCasinoGameActionV2 = createAction<any>(OPEN_CASINO_GAME);

const OPEN_CASINO_GAME_SUCCESS = `OPEN_CASINO_GAME_SUCCESS${suffix}V2`;
const openCasinoGameSuccessAction = createAction<any[]>(OPEN_CASINO_GAME_SUCCESS);

const OPEN_CASINO_GAME_ERROR = `OPEN_CASINO_GAME_ERROR${suffix}V2`;
const openCasinoGameErrorAction = createAction<string>(OPEN_CASINO_GAME_ERROR);

function* openCasinoGameWatcher(): SagaIterator {
  yield takeEvery(OPEN_CASINO_GAME, openCasinoGameWorker);
}
mergeSaga(openCasinoGameWatcher);

function* openCasinoGameWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(openCasinoGameApi, action.payload);
    if (result.statusCode == 0) {
      yield put(openCasinoGameSuccessAction(result));
      if (!action.payload.casinoVersion || (action.payload.casinoVersion && action.payload.casinoVersion != 2)) {
        if (action.payload.device_id != 1) {
          const newWindow = window.open(result.data.lauch_url);
          if (newWindow == null || typeof newWindow == 'undefined') {
            showToast('Please disable your pop-up blocker and click the play button again.', 'error');
          } else {
            newWindow.focus();
          }
          const timer = setInterval(function () {
            if (newWindow != null && newWindow.closed) {
              clearInterval(timer);
              getUserBalanceAction();
            }
          }, 1000);
        }
      }
    } else {
      console.log('failed launching game.');
    }
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(openCasinoGameErrorAction(e));
  }
}

const openCasinoGameApi = (data: any): Request => {
  return vipApi(
    API_PATH.TPI.INIT_PROVIDER,
    'post',
    data.bonus_history_id
      ? {
          device_id: data.device_id,
          site_id: data.site_id ?? 1,
          provider_id: data.provider_id,
          game_id: data.game_id,
          is_freeplay: data.is_freeplay,
          bonus_history_id: data.bonus_history_id,
        }
      : {
          device_id: data.device_id,
          site_id: data.site_id ?? 1,
          provider_id: data.provider_id,
          game_id: data.game_id,
          is_freeplay: data.is_freeplay,
        },
  );
};

const reduceHandlers = {
  [OPEN_CASINO_GAME]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
  }),
  [OPEN_CASINO_GAME_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    gameUrl: action.payload.data.lauch_url,
  }),
  [OPEN_CASINO_GAME_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
