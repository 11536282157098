import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { API_PATH } from '../../../constants';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const PTPI_CASINO_BONUS = `PTPI_CASINO_BONUS${suffix}`;

export const ptpiGetCasinoBonusAction = createAction(PTPI_CASINO_BONUS);

const PTPI_CASINO_BONUS_SUCCESS = `PTPI_CASINO_BONUS_SUCCESS${suffix}`;
const ptpiCasinoBonusSuccessAction = createAction<any[]>(PTPI_CASINO_BONUS_SUCCESS);

const PTPI_CASINO_BONUS_ERROR = `PTPI_CASINO_BONUS_ERROR${suffix}`;
const ptpiCasinoBonusErrorAction = createAction<string>(PTPI_CASINO_BONUS_ERROR);

function* ptpiCasinoBonusWatcher(): SagaIterator {
  yield takeEvery(PTPI_CASINO_BONUS, ptpiCasinoBonusWorker);
}
mergeSaga(ptpiCasinoBonusWatcher);

function* ptpiCasinoBonusWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(ptpiCasinoBonusApi, action.payload);
    yield put(ptpiCasinoBonusSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(ptpiCasinoBonusErrorAction(e));
  }
}

const ptpiCasinoBonusApi = (id: any): Request => {
  return vipApi(API_PATH.TPI.CASINO_BONUS(id), 'GET', {}, 'json');
};

const reduceHandlers = {
  [PTPI_CASINO_BONUS]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
    casinoBonus: null,
  }),
  [PTPI_CASINO_BONUS_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoBonus: action.payload.data ?? null,
  }),
  [PTPI_CASINO_BONUS_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoBonus: null,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
