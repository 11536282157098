import { ITransactionContest } from '../../models/Cashier/Transactions/TransactionContest';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'TransactionContest';

export interface TransactionContestSliceType {
  isLoading: boolean;
  openDialog: boolean;
  transactionContest: ITransactionContest[];
}

export const initialState: TransactionContestSliceType = {
  isLoading: false,
  openDialog: false,
  transactionContest: [],
};

export const TransactionContestReducer = createAndMergeSliceReducer(sliceName, initialState);
