import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { API_PATH } from '../../../constants';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const PTPI_CASINO_BONUSES = `PTPI_CASINO_BONUSES${suffix}`;

export const ptpiGetCasinoBonusesAction = createAction(PTPI_CASINO_BONUSES);

const PTPI_CASINO_BONUSES_SUCCESS = `PTPI_CASINO_BONUSES_SUCCESS${suffix}`;
const ptpiCasinoBonusesSuccessAction = createAction<any[]>(PTPI_CASINO_BONUSES_SUCCESS);

const PTPI_CASINO_BONUSES_ERROR = `PTPI_CASINO_BONUSES_ERROR${suffix}`;
const ptpiCasinoBonusesErrorAction = createAction<string>(PTPI_CASINO_BONUSES_ERROR);

function* ptpiCasinoBonusesWatcher(): SagaIterator {
  yield takeEvery(PTPI_CASINO_BONUSES, ptpiCasinoBonusesWorker);
}
mergeSaga(ptpiCasinoBonusesWatcher);

function* ptpiCasinoBonusesWorker(): SagaIterator {
  try {
    const result: any = yield call(ptpiCasinoBonusesApi);
    yield put(ptpiCasinoBonusesSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(ptpiCasinoBonusesErrorAction(e));
  }
}

const ptpiCasinoBonusesApi = (): Request => {
  return vipApi(API_PATH.TPI.CASINO_BONUSES, 'GET', {}, 'json');
};

const reduceHandlers = {
  [PTPI_CASINO_BONUSES]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
    casinoBonuses: [],
  }),
  [PTPI_CASINO_BONUSES_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoBonuses: action.payload.data ?? [],
  }),
  [PTPI_CASINO_BONUSES_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoBonuses: [],
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
