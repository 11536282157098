import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../../user.reducer';
import { mergeSaga } from '../../../../utils/redux-saga/merge-saga';

const suffix = `/${userReducer.sliceName}/app`;

const SET_RUN_TOUR = `SET_RUN_TOUR${suffix}`;
export const SetRunTourAction = createAction<boolean>(SET_RUN_TOUR);

const SET_RUN_TOUR_SUCCESS = `SET_RUN_TOUR_SUCCESS${suffix}`;
const SetRunTourSuccessAction = createAction<boolean>(SET_RUN_TOUR_SUCCESS);

const SET_RUN_TOUR_ERROR = `SET_RUN_TOUR_ERROR${suffix}`;
const SetRunTourErrorAction = createAction<any>(SET_RUN_TOUR_ERROR);

function* SetRunTourWatcher(): SagaIterator {
  yield takeEvery(SET_RUN_TOUR, SetRunTourWorker);
}
mergeSaga(SetRunTourWatcher);

function* SetRunTourWorker(request: Action<boolean>): SagaIterator {
  try {
    yield put(SetRunTourSuccessAction(request.payload));
  } catch (e: any) {
    yield put(SetRunTourErrorAction(e));
  }
}

const reduceHandlers = {
  [SET_RUN_TOUR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [SET_RUN_TOUR_SUCCESS]: (slice: UserSliceType, action: Action<boolean>): UserSliceType => ({
    ...slice,
    isLoading: false,
    runTour: action.payload,
  }),
  [SET_RUN_TOUR_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
