import { emptyCashierDialog, ICashierDialog } from '../../models/cashier-transaction';
import { FeeFreeTime } from '../../models/FeeFreeTime';
import { IPlayerTransactionLimitDetails } from '../../models/player-transaction-limit-details';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'cashierTransactionSlice';

export interface CashierTransactionSliceType {
  isLoading: boolean;
  isSaving: boolean;
  rollover?: any[];
  type: number | null;
  method: any | null;
  bonus?: any[] | null;
  player_number: string | null;
  account?: string | null;
  account_number?: string | null;
  routing_number?: string | null;
  amount: number | null;
  name?: string | null;
  fingerprint?: string | null;
  ip?: string | null;
  paymentMethods: any[] | null;
  onlineHours: any[] | null;
  availableBonuses: any[] | null;
  transactionRequest: any[] | null;
  isTransactionRequestSuccess: boolean;
  bankReference: any | null;
  transactionStatus: any | null;
  cbioAllowed: any | null;
  smsCodeCheck: any | null;
  feeFreeTime: FeeFreeTime[];
  playerBanks?: any[] | null;
  institutions?: any[] | null;
  institution?: any | null;
  mfa?: any[] | null;
  selectableBanks?: any[] | null;
  wdHoldAmount: any | null;
  accounts: string[];
  cashierDialog: ICashierDialog;
  transactionLimitDetails?: IPlayerTransactionLimitDetails;
}

export const initialState: CashierTransactionSliceType = {
  isLoading: false,
  isSaving: false,
  rollover: [],
  type: null,
  method: null,
  bonus: [],
  player_number: null,
  account: null,
  amount: null,
  name: '',
  fingerprint: null,
  ip: null,
  paymentMethods: [],
  onlineHours: [],
  availableBonuses: [],
  transactionRequest: null,
  isTransactionRequestSuccess: false,
  bankReference: null,
  transactionStatus: '',
  cbioAllowed: null,
  smsCodeCheck: null,
  feeFreeTime: [],
  wdHoldAmount: null,
  accounts: [],
  cashierDialog: emptyCashierDialog,
  transactionLimitDetails: undefined,
};

export const cashierTransactionReducer = createAndMergeSliceReducer(sliceName, initialState);
