export interface ICashierTransaction {
  site: number;
  type: number;
  method: number;
  bonus?: number;
  player_number: string;
  account?: string;
  amount: number;
  name?: string;
  fingerprint?: string;
  ip?: string;
}

export interface ICashierDialog {
  isShow: boolean;
  type: string;
  transactionType: number;
}

export const emptyCashierDialog: ICashierDialog = {
  isShow: false,
  type: '',
  transactionType: -1,
};
