import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_CASHIER_DIALOG = `SET_CASHIER_DIALOG${suffix}`;

export const setCashierDialogAction = createAction<any>(SET_CASHIER_DIALOG);

const reduceHandlers = {
  [SET_CASHIER_DIALOG]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => {
    return {
      ...slice,
      isLoading: false,
      cashierDialog: {
        isShow: action.payload.isShow,
        type: action.payload.type,
        transactionType: slice.type ?? -1,
      },
    };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
