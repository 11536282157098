import React from 'react';

export const VipAutoSpinIcon = (props: any) => {
  const { style } = props;
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 511.999 511.999' style={style}>
      <path
        style={{ fill: '#F2C500' }}
        d='M256,407.537c-83.559,0-151.538-67.98-151.538-151.538S172.442,104.461,256,104.461
   s151.538,67.98,151.538,151.538S339.558,407.537,256,407.537z'
      />
      <path style={{ fill: '#F59D00' }} d='M256,104.461v303.077c83.559,0,151.538-67.98,151.538-151.538S339.558,104.461,256,104.461z' />
      <path
        style={{ fill: '#48AEE2' }}
        d='M500.519,255.999h-8.973c0-129.881-105.665-235.546-235.546-235.546
   c-11.494,0-20.812,9.318-20.812,20.812S244.506,62.077,256,62.077c106.928,0,193.922,86.993,193.922,193.922h-8.971
   c-8.824,0-14.341,9.553-9.929,17.196l29.785,51.588c4.412,7.642,15.444,7.642,19.856,0l29.784-51.588
   C514.859,265.553,509.343,255.999,500.519,255.999z'
      />
      <path
        style={{ fill: '#65C6BB' }}
        d='M256,449.921c-106.929,0-193.922-86.993-193.922-193.922h8.971c8.824,0,14.341-9.553,9.927-17.196
   l-29.784-51.588c-4.412-7.642-15.444-7.642-19.856,0L1.553,238.804C-2.859,246.446,2.656,256,11.48,256h8.971
   c0,129.881,105.665,235.546,235.546,235.546c11.494,0,20.812-9.318,20.812-20.812C276.812,459.239,267.494,449.921,256,449.921z'
      />
      <path
        style={{ fill: '#F59D00' }}
        d='M263.306,238.92v-22.447c9.994,0.769,12.915,5.689,17.989,5.689c6.765,0,9.532-8.455,9.532-12.607
   c0-10.607-17.989-13.069-27.521-13.375v-3.845c0-5.256-11.684-5.615-11.684,0v4.305c-16.297,2.306-30.749,11.684-30.749,31.21
   c0,19.68,16.605,26.29,30.749,31.672v24.907c-11.377-1.998-16.604-11.071-23.063-11.071c-5.843,0-10.455,7.688-10.455,12.915
   c0,9.84,15.067,19.372,33.517,19.987v3.843c0,5.632,11.684,5.229,11.684,0v-4.458c17.989-2.921,30.287-14.452,30.287-33.516
   C293.594,251.373,277.297,244.147,263.306,238.92z M253.158,235.23c-15.043-6.334-11.19-16.057,0-18.295V235.23z M261.767,284.276
   v-20.603C275.945,270.368,272.824,281.673,261.767,284.276z'
      />
      <path
        style={{ fill: '#E87E04' }}
        d='M263.306,238.92v-22.447c9.994,0.769,12.915,5.689,17.989,5.689c6.765,0,9.532-8.455,9.532-12.607
   c0-10.607-17.989-13.069-27.521-13.375v-3.845c0-3.073-3.988-4.457-7.306-3.967v125.703c3.317,0.484,7.306-0.909,7.306-3.965v-4.458
   c17.989-2.921,30.287-14.452,30.287-33.516C293.594,251.373,277.297,244.147,263.306,238.92z M261.767,284.276v-20.603
   C275.945,270.368,272.824,281.673,261.767,284.276z'
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
