import { createSelector } from 'reselect';
import { SocketReducer, SocketSliceType } from './socket-reducer';

export const SocketSelector = (state: any): any => state[SocketReducer.sliceName];

export const SocketMessageSelector = createSelector(SocketSelector, (SocketSliceType: SocketSliceType) => SocketSliceType.socketMessages);
export const SocketMessageByDateSelector = createSelector(
  SocketSelector,
  (SocketSliceType: SocketSliceType) => SocketSliceType.socketMessagesByDate,
);
