import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { CasinoCategory, CasinoGameDto, ICasinoLobbyV2, defaultCategory } from '../../../models/CasinoLobbyV2/CasinoLobbyDto';
import { GameDisplayComponent } from '../../casino-lobby-v2/components/GameDisplay.component';
import { ILoginResponse, Right } from '../../../models/login';
import { GameCarouselComponent } from '../../casino-lobby-v2/components/GameCarousel.component';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    carrouselGame: {
      width: '75%',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '95%',
      },
    },
  }),
);

export interface ExternalProps {
  gameRatings: any;
  gameUrl: any;
  imageCDNUrl: string;
  isMobile: boolean;
  isPortrait: boolean;
  is_freeplay: number | null;
  isLoading: boolean;
  LiveDealerView: number;
  providerSelected: Right;
  user: ILoginResponse;
  userBalance: any;
  userDevice: number;
  useFreePlay: boolean;
  casinoLobby: ICasinoLobbyV2;
}

export interface ExternalActionProps {
  addUpdateGameRatingPlayerAction: (x: any) => void;
  addUpdateFavoriteGamePlayerAction: (x: any) => void;
  getUserBalanceAction: () => void;
  openCasinoGameAction: (x: any) => void;
  openCasinoGameActionV2: (x: any) => void;
  setShowGoBack: (x: boolean) => void;
}

export const LiveDealerDisplayProvider = (props: ExternalActionProps & ExternalProps) => {
  const [game, setGame] = useState<CasinoGameDto>();
  const [gameURLState, setGameUrl] = useState('');
  const classes = useStyles();
  const [localCategories, setLocalCategories] = useState<CasinoCategory[]>([]);

  const {
    addUpdateFavoriteGamePlayerAction,
    addUpdateGameRatingPlayerAction,
    casinoLobby,
    gameRatings,
    gameUrl,
    getUserBalanceAction,
    imageCDNUrl,
    isMobile,
    isPortrait,
    openCasinoGameActionV2,
    providerSelected,
    setShowGoBack,
    useFreePlay,
    user,
    userBalance,
  } = props;

  useEffect(() => {
    if (gameUrl && gameUrl.trim().length > 0) {
      setGameUrl(gameUrl);
      if (!valideGame()) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
      }
    }
  }, [gameUrl]);

  useEffect(() => {
    return () => {
      setShowGoBack(true);
    };
  }, []);

  useEffect(() => {
    setShowGoBack(valideGame());
  }, [game]);

  const valideGame = () => (!game || (game && !game.game_id) ? true : false);

  useEffect(() => {
    const tempCategories: CasinoCategory[] = [];

    casinoLobby.categories
      .map(x => x)
      .forEach((cat: CasinoCategory) => {
        const tempCat: CasinoCategory = JSON.parse(JSON.stringify(cat));
        tempCat.games = tempCat.games.filter(x => x.provider_id == providerSelected.id);

        tempCategories.push(tempCat);
      });

    setLocalCategories(tempCategories);
  }, []);

  return (
    <div>
      <Grid container direction='row' className={`${classes.container} gameCarrouselCont`}>
        {(!game || (game && !game.game_id)) &&
          localCategories &&
          localCategories.length > 0 &&
          localCategories
            .sort((a: CasinoCategory, b: CasinoCategory) => a.category_order - b.category_order)
            .filter((x: CasinoCategory) => x.games && x.games.length > 0)
            .map((cat: CasinoCategory, index: number) => {
              return (
                <div key={`DGCC${index}${cat.category_id}`} className={classes.carrouselGame + ' vip-fadeIn'}>
                  <GameCarouselComponent
                    key={`GCC${index}${cat.category_id}`}
                    isMobile={isMobile}
                    categoryGames={cat}
                    setGame={setGame}
                    search={''}
                    addUpdateFavoriteGamePlayerAction={addUpdateFavoriteGamePlayerAction}
                    categorySelected={defaultCategory}
                    imageCDNUrl={imageCDNUrl}
                  ></GameCarouselComponent>
                </div>
              );
            })}
      </Grid>
      {game && game.game_id && (
        <div style={{ marginTop: '45px' }}>
          <GameDisplayComponent
            addUpdateFavoriteGamePlayerAction={addUpdateFavoriteGamePlayerAction}
            addUpdateGameRatingPlayerAction={addUpdateGameRatingPlayerAction}
            bonusHistoryId={-1}
            game={game}
            gameRatings={gameRatings}
            gameURL={gameURLState}
            getUserBalanceAction={getUserBalanceAction}
            imageCDNUrl={imageCDNUrl}
            isMobile={isMobile}
            setGame={setGame}
            isBonusGame={false}
            isPortrait={isPortrait}
            openCasinoGameActionV2={openCasinoGameActionV2}
            setGameUrl={setGameUrl}
            userBalance={userBalance}
            useFreePlay={useFreePlay}
            user={user}
          />
        </div>
      )}
    </div>
  );
};
