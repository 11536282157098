import { SocketMessagesByDateDto, SocketMessagesDto } from '../../models/Socket/socket-messages';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'SocketSlice';

export interface SocketSliceType {
  socketMessages: SocketMessagesDto[];
  socketMessagesByDate: SocketMessagesByDateDto[];
}

export const initialState: SocketSliceType = {
  socketMessages: [],
  socketMessagesByDate: [],
};

export const SocketReducer = createAndMergeSliceReducer(sliceName, initialState);
