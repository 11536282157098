import globToRe from 'glob-to-regexp';
import login from './login.json';
import { axiosResult } from '../../../utils/axios/axios-result';
import { getAppSettings } from '../../../utils';

export function mockApi(axiosMock: any): any {
  axiosMock.onPost(globToRe(`${getAppSettings().apiUrl}/LandingPage/Login`)).reply(() => {
    return axiosResult(login);
  });
}
