import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Button, DialogTitle, Drawer, Fab, Grid, IconButton, Theme, Typography } from '@material-ui/core';
import { IRoute, RoutesDictionary, RoutesEnum } from '../../models/routes';
import { getHistory } from '../../utils/history.util';
// Icons
// import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import CardGiftcardSharpIcon from '@material-ui/icons/CardGiftcardSharp';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CloseIcon from '@material-ui/icons/Close';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { PROVIDERS } from '../../enum/providers/providers';
import { IsValidRight, IsValidRightByTpiProviderCategory } from '../../utils';
import { VipFootBall } from '../icons/football-icon';
import { TPIProviderCategoryEnum } from '../../enum/tpi/TPIProviderCategoryEnum';
import { GetCurrentHeaderButtonView, GetCurrentHeaderButtonViewInfo, HeaderButtonViewEnum } from '../../enum/components/component-enum';
import './styles/nav-bar.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

declare global {
  interface Window {
    LO: any;
  }
}

// const openLOLiveChat = () => {
//   if (window.LO != null) {
//     if (window.LO.messenger != undefined && window.LO.messenger != null) {
//       window.LO.messenger.open();
//       console.log('opened LO Chat');
//     }
//   }
// };

const HeaderButtonViewSelected = GetCurrentHeaderButtonView();
const CurrentHeaderButtonViewInfo = GetCurrentHeaderButtonViewInfo();

const useStyles = makeStyles((theme: Theme) => ({
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 'bold',
    minHeight: '56px',
    height: '100%',
    '& svg': {
      paddingBottom: '5px',
    },
  },
  label: {
    fontSize: '1rem',
  },
  toolbar: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL}/top-bg.png)`,
    borderTop: '1px solid #333',
  },
  toolbar2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    // backgroundColor: '#1f4d72',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
  },
  toolbarItem: {
    width: '15.6% !important',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#1f2020',
    position: 'fixed',
    zIndex: 1201,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -15,
    height: '50px',
    width: '50px',
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  ButtonMenuDescriptions: {
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: '0px',
  },
  ButtonMenuDescriptionsLiveDealer: {
    fontSize: '0.75rem',
  },
  iconWrapper: {
    textAlign: 'center',
    padding: 5,
  },
  bottomDrawar: {
    '& .MuiDrawer-paper': {
      overflowY: 'visible',
    },
    '& .MuiDrawer-paperAnchorBottom': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  chatIcon: {
    color: theme.palette.primary.main,
  },
  menutooIcon: {
    width: '50% !important',
    marginLeft: '0px !important',
    marginRight: '0px !important',
  },
  translateLeft: {
    transform: 'translateX(-25%)',
  },
  translateRight: {
    transform: 'translateX(75%)',
  },
  translateRight6Icons: {
    transform: 'translateX(15%)',
  },
  iconSize: {
    height: '30px',
  },
  secondButton: {
    backgroundColor: 'white !important',
  },
}));

const getIcon = (routeName: any) => {
  // RoutesEnum.LoginPage;
  if (routeName == RoutesEnum.Sportsbook) {
    return <VipFootBall style={{ height: '35px', fontSize: '2rem', paddingBottom: '0px' }} />;
    // return <SportsFootballIcon style={{ fontSize: '2rem' }} color='primary' />;
  }
  if (routeName == RoutesEnum.LiveSports) {
    return <WhatshotIcon style={{ height: '30px', fontSize: '1.75rem', color: 'orange', paddingBottom: '0px' }} />;
  }

  if (routeName == RoutesEnum.Casino) {
    return (
      <FontAwesomeIcon
        icon={['fad', 'dice']}
        style={{ fontSize: '1.5rem', color: 'skyblue', height: '25px', paddingBottom: '0px !important' }}
      />
    );
  }
  // if (routeName == RoutesEnum.Casino) {
  //   return <img style={{ background: '#FFF', width: '37px', height: '33px' }} src={`${process.env.PUBLIC_URL}/casino-lobby.png`} />;
  // }
  if (routeName == RoutesEnum.Live_Dealer)
    return <img style={{ width: '25px', height: '25px' }} src={`${process.env.PUBLIC_URL}/live-dealer.png`} />;

  if (routeName == RoutesEnum.ESports) {
    return <SportsEsportsIcon style={{ fontSize: '2rem', height: '30px' }} />;
  }

  if (routeName == RoutesEnum.Promotions) {
    return <CardGiftcardSharpIcon style={{ fontSize: '2rem', height: '30px' }} />;
  }
  if (routeName == RoutesEnum.RAF) {
    return <FontAwesomeIcon icon={['fad', 'user-friends']} style={{ fontSize: '1.5rem' }} />;
  }
  if (routeName == RoutesEnum.Rules) {
    return <HelpOutlineSharpIcon style={{ fontSize: '2rem', height: '30px' }} />;
  }
  if (routeName == RoutesEnum.Guides) {
    return <MenuBookIcon style={{ fontSize: '2rem', height: '30px' }} />;
  }
  if (routeName == RoutesEnum.Cashier) {
    return <FontAwesomeIcon icon={['fad', 'sack-dollar']} style={{ fontSize: '1.5rem', height: '25px' }} />;
  }
  if (routeName == RoutesEnum.LiveChat) {
    return <FontAwesomeIcon icon={['fad', 'comment']} style={{ fontSize: '2rem', color: 'white', height: '30px' }} />;
  }
  if (routeName == RoutesEnum.Account) {
    return <FontAwesomeIcon icon={['fad', 'user']} style={{ fontSize: '2rem', height: '30px' }} />;
  }
  // if (routeName == RoutesEnum.Props_Builder) return <FontAwesomeIcon icon={['fad', 'list-ol']} style={{ fontSize: '2rem' }} />;
  if (routeName == RoutesEnum.Props_Builder)
    return <img style={{ width: '30px', height: '30px' }} src={`${process.env.PUBLIC_URL}/props.png`} />;

  if (routeName == RoutesEnum.Chat) {
    return (
      <FontAwesomeIcon icon={['fad', 'comment']} style={{ fontSize: '1.5rem', color: 'skyblue', height: '25px', paddingBottom: '0px' }} />
    );
  }

  if (routeName == RoutesEnum.Racebook || routeName == RoutesEnum.Horses)
    return <FontAwesomeIcon icon={['fad', 'horse-head']} style={{ fontSize: '2rem', color: '#a36314', height: '30px' }} />;
  return <Brightness1Icon style={{ fontSize: '2rem', height: '30px' }} />;
};

export interface AppRootProps {
  isTVOn: boolean;
  tvUrl: string;
  user: any;
  rightOpen: boolean;
  unreadMessages: number;
}
export interface ExternalActionProps {
  tvOnOffAction: (isOn: boolean) => void;
  setTvUrlAction: (ch: string) => void;
  SetRunTourAction: (runTour: boolean) => void;
  setOpenDialogMessages: (data: boolean) => void;
}

export const NavBarBottom = ({
  user,
  rightOpen,
  unreadMessages,
  SetRunTourAction,
  setOpenDialogMessages,
}: ExternalActionProps & AppRootProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDialogOptions, setOpenDialogOptions] = useState(false);

  const classes = useStyles();
  const getName = (name: string) => {
    switch (name) {
      case 'Sportsbook':
        return 'Sports';
      case 'Props Builder':
        return 'Props';
      case RoutesEnum.Live_Dealer:
        return 'Live';
      default:
        return name;
    }
  };

  const getIconButton = (route: IRoute, index?: number, routesLength?: number) => {
    const shouldAddMenuTooIcon = routes && routes.filter(x => x.path != RoutesEnum.Hidden).length == 2;

    const translateLeftButtons =
      (routesLength && routesLength < 6 && index === 1) || (routesLength && routesLength >= 6 && index === 2) ? classes.translateLeft : '';
    const translateRightButtons = routesLength && routesLength < 6 && index === 2 ? classes.translateRight : '';
    const translateRight6Buttons = routesLength && routesLength >= 6 && index === 3 ? classes.translateRight6Icons : '';

    return (
      <IconButton
        className={`btnMenuBotton font-theme ${classes.iconButtonLabel}  tour_tabBar-${route['name']
          .replace(/\s/g, '')
          .toLowerCase()} ${translateLeftButtons} ${translateRightButtons} ${translateRight6Buttons}`}
        classes={{ root: classes.toolbarItem, label: classes.iconButtonLabel }}
        key={route.name}
        style={
          route.path == RoutesEnum.Hidden
            ? shouldAddMenuTooIcon
              ? { display: 'none', width: '0%' }
              : { visibility: 'hidden', width: '0%' }
            : routes && routes.length >= 4 && routes[0].name == RoutesEnum.Hidden && routes[3].name == route.name
            ? { marginLeft: '3rem', marginRight: '-2rem' }
            : {}
        }
        onClick={() => {
          setOpenDrawer(false);

          if (route.path != (RoutesDictionary[RoutesEnum.LiveChat].path ?? '')) {
            getHistory().push(route.path);
          } else {
            setOpenDialogOptions(true);
          }
        }}
      >
        {getIcon(route.name)}
        <span className={`${classes.ButtonMenuDescriptions}`} dangerouslySetInnerHTML={{ __html: getName(route.name) }}></span>
      </IconButton>
    );
  };

  const getButton = (route: IRoute) => {
    return (
      <Grid key={route.name} item xs={4}>
        <div className={`${classes.iconWrapper} tour_tabBar-${route['name'].replace(/\s/g, '').toLowerCase()}`}>
          <IconButton
            style={{ width: '100%' }}
            classes={{ label: classes.iconButtonLabel }}
            onClick={() => {
              setOpenDrawer(false);

              if (route.path != (RoutesDictionary[RoutesEnum.LiveChat].path ?? '')) {
                getHistory().push(route.path);
              } else {
                setOpenDialogOptions(true);
              }
            }}
          >
            {getIcon(route.name)}

            <span className={classes.ButtonMenuDescriptions}>{route.name}</span>
          </IconButton>
        </div>
      </Grid>
    );
  };

  let localRoutes = [RoutesDictionary[RoutesEnum.Sportsbook]];

  // if (IsValidRight(user, [PROVIDERS.PROPS_BUILDER])) localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Props_Builder]];
  if (IsValidRight(user, [PROVIDERS.PROPS_BUILDER])) localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Props_Builder]];

  if (IsValidRight(user, [PROVIDERS.LIVE_WAGERING])) localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.LiveSports]];

  // if (IsValidRight(user, [PROVIDERS.VIG])) localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Live_Dealer]];

  if (IsValidRightByTpiProviderCategory(user, [TPIProviderCategoryEnum.LIVE_DEALER]))
    localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Live_Dealer]];

  if (IsValidRight(user, [PROVIDERS.BETSOFT, PROVIDERS.CONCEPT, PROVIDERS.DGS_CASINO, PROVIDERS.PLATINUM, PROVIDERS.VIG]))
    localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Casino]];

  let routes: IRoute[] = [];
  const chatRoute: IRoute[] = [{ name: RoutesEnum.Chat, path: '/live-chat' }];
  routes = [...routes, ...localRoutes, ...chatRoute];

  let drawerRoutes: any[] = [];
  // if (IsValidRight(user, [PROVIDERS.LIVE_WAGERING])) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.LiveSports]];
  if (IsValidRight(user, [PROVIDERS.HORSES])) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Horses]];

  if (IsValidRight(user, [PROVIDERS.RACEBOOK])) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Racebook]];

  if (!user.agent_username) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.RAF]];
  drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Account]];
  drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Rules]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Guides]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.LiveChat]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Promotions]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Bracket]];
  // className={`fonttest ${classes.iconButtonLabel}`}

  const modifiedRoutes =
    routes.length <= 5
      ? [...routes.filter(route => route.path !== (RoutesDictionary[RoutesEnum.LiveChat].path ?? '')).slice(0, 4), chatRoute[0]]
      : [...routes.filter(route => route.path !== (RoutesDictionary[RoutesEnum.LiveChat].path ?? '')).slice(0, 6), chatRoute[0]];

  return (
    <AppBar color='primary' className={`${classes.appBar} ${rightOpen ? 'animationHDown' : 'animationHUp'}`}>
      <Toolbar classes={{ root: classes.toolbar }}>
        {modifiedRoutes.map((route: IRoute, idx) => getIconButton(route, idx, modifiedRoutes.length))}
        <Fab
          color='secondary'
          aria-label='add'
          className={`${classes.fabButton} tour_mobile_more_options`}
          onClick={() => setOpenDrawer(true)}
        >
          <FontAwesomeIcon icon={['fad', 'bars']} style={{ fontSize: '1.2rem' }} />
        </Fab>
      </Toolbar>
      <Drawer anchor='bottom' open={openDrawer} onClose={() => setOpenDrawer(false)} className={classes.bottomDrawar}>
        <Toolbar classes={{ root: classes.toolbar2 }}>
          {modifiedRoutes.map((route: IRoute, idx) => getIconButton(route, idx, modifiedRoutes.length))}
          <Fab
            color='primary'
            aria-label='add'
            className={`${classes.fabButton} tour_mobile_more_options`}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon style={{ fontSize: '1.5rem' }} />
          </Fab>
        </Toolbar>
        <Grid container spacing={3} style={{ padding: 20 }}>
          {drawerRoutes.map((route: IRoute) => getButton(route))}
          {/* <Grid key={'tv'} item xs={4}>
            <div className={classes.iconWrapper}>
              <IconButton classes={{ label: classes.iconButtonLabel }} onClick={() => tvOnOffAction(!isTVOn)}>
                <VipLiveTv style={{ width: '2rem', height: '2rem' }} />
                TV
              </IconButton>
            </div>
          </Grid> */}
          {/* <Grid key={'tv'} item xs={4}>
            <div className={classes.iconWrapper}>
              <IconButton
                classes={{ label: classes.iconButtonLabel }}
                onClick={() => {
                  setOpenDrawer(false);
                  getHistory().push('live-chat');
                }}
              >
                <FontAwesomeIcon icon={['fad', 'comments']} style={{ fontSize: '2rem', color: '#fff' }} />
                <span style={{ fontSize: '0.75rem' }}>Support</span>
              </IconButton>
            </div>
          </Grid> */}
          {HeaderButtonViewSelected == HeaderButtonViewEnum.BRACKET && (
            <Grid key={`contest`}>
              <Typography style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='buttonWithMotionMobile'
                  onClick={() => {
                    getHistory().push('/bracket');
                  }}
                >
                  <span className='buttonWithMotionSpan'>
                    <img src={`${CurrentHeaderButtonViewInfo.image}`} width='60' style={{ marginTop: '0.5rem' }} />
                  </span>
                </Button>
                <small style={{ fontSize: '.8rem' }}>{CurrentHeaderButtonViewInfo.name}</small>
              </Typography>
            </Grid>
          )}
          {HeaderButtonViewSelected == HeaderButtonViewEnum.PICK && (
            <Grid key={`pick`}>
              <Typography style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='buttonWithMotionMobile'
                  onClick={() => {
                    getHistory().push('/picks');
                  }}
                >
                  <span className='buttonWithMotionSpan'>
                    <img src={`${process.env.PUBLIC_URL}/images/brackets/football.png`} width='40' style={{ marginTop: '0.5rem' }} />
                  </span>
                </Button>
                <small style={{ fontSize: '.8rem' }}>Contest</small>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Drawer>

      {openDialogOptions && (
        <Dialog
          fullWidth
          maxWidth='lg'
          open={true}
          onClose={() => {
            setOpenDialogOptions(false);
          }}
          aria-labelledby='max-width-dialog-title'
        >
          <DialogTitle>
            Help
            <hr style={{ width: '100%' }} />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setOpenDialogOptions(false);
                    getHistory().push(RoutesDictionary[RoutesEnum.LiveChat].path ?? '');
                  }}
                >
                  Live Chat
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    setOpenDialogOptions(false);
                    window.Marker.capture();
                  }}
                >
                  Feedback
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  className={classes.secondButton}
                  onClick={() => {
                    setOpenDialogOptions(false);
                    SetRunTourAction(true);
                  }}
                >
                  Site Tour
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setOpenDialogOptions(false);
                    setOpenDialogMessages(true);
                  }}
                >
                  VIP Inbox {unreadMessages == 0 ? `` : `(${unreadMessages})`}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialogOptions(false);
              }}
              color='primary'
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </AppBar>
  );
};
