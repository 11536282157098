import React from 'react';

export const VipPennantIcon = (props: any) => {
  const { style } = props;
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' style={style} viewBox='0 0 612 792'>
      <path
        stroke='#000000'
        d='M106.333,112
	c133.218,0,266.153,0,399.334,0c0,186.251,0,372.33,0,558.99c-3.492-1.664-6.601-3.099-9.67-4.614
	c-32.626-16.113-65.25-32.231-97.864-48.367c-29.919-14.804-59.836-29.612-89.713-44.502c-1.699-0.849-2.887-0.987-4.638-0.115
	c-27.885,13.882-55.806,27.692-83.738,41.479c-33.814,16.689-67.648,33.34-101.47,50.015c-3.948,1.946-7.871,3.943-12.242,6.135
	C106.333,484.31,106.333,298.325,106.333,112z'
      />
    </svg>
  );
};
