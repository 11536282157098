import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
import { API_PATH } from '../../../constants';
import { ITvChannels } from '../../../models/tv-channels';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const GET_TV_CHANNELS = `GET_TV_CHANNELS${suffix}`;

export const getTvChannelsAction = createAction(GET_TV_CHANNELS);

const GET_TV_CHANNELS_SUCCESS = `GET_TV_CHANNELS_SUCCESS${suffix}`;
const getTvChannelsSuccessAction = createAction<ITvChannels[]>(GET_TV_CHANNELS_SUCCESS);

const GET_TV_CHANNELS_ERROR = `GET_TV_CHANNELS_ERROR${suffix}`;
const getTvChannelsErrorAction = createAction<string>(GET_TV_CHANNELS_ERROR);

function* getTvChannelsWatcher(): SagaIterator {
  yield takeEvery(GET_TV_CHANNELS, getTvChannelsWorker);
}
mergeSaga(getTvChannelsWatcher);

function* getTvChannelsWorker(): SagaIterator {
  try {
    const result: any = yield call(getTvChannelsApi);
    yield put(getTvChannelsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getTvChannelsErrorAction(e));
  }
}

const getTvChannelsApi = (): Request => {
  return vipApi(API_PATH.GAME_SELECTION.TV_CHANNELS, 'get', {});
};

const reduceHandlers = {
  [GET_TV_CHANNELS]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_TV_CHANNELS_SUCCESS]: (slice: GameSelectionSliceType, action: Action<ITvChannels[]>): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    tvChannelsList: action.payload,
  }),
  [GET_TV_CHANNELS_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
