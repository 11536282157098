import { setAxiosBusyAction } from './set-axios-busy.action';

let apiBusyCount = 0;

export function ajaxStarts(): void {
  if (apiBusyCount === 0) {
    setAxiosBusyAction(true);
  }
  apiBusyCount += 1;
}

export function ajaxEnded(): void {
  apiBusyCount -= 1;
  if (apiBusyCount === 0) {
    setAxiosBusyAction(false);
  }
}
