import React, { useEffect, useState } from 'react';
import { GameCardComponent } from './GameCard.component';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Button, createStyles, Grid as MuiGrid, makeStyles, Theme } from '@material-ui/core';
import { CasinoCategory, CasinoGameDto } from '../../../models/CasinoLobbyV2/CasinoLobbyDto';
import { ViphandSwip } from '../../../shared/icons/hand-swip-icon';
import SwiperCore, { Grid, EffectCoverflow, Pagination, Navigation } from 'swiper';
import 'swiper/modules/grid/grid.min.css';
import 'swiper/modules/pagination/pagination.min.css';

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    buttomHideAll: {
      float: 'right',
      margin: '10px',
    },
    ShowMore: {
      width: '25%',
      marginRight: '37%',
      marginLeft: '37%',
      [theme.breakpoints.down('md')]: {
        width: '75%',
        marginRight: '12.5%',
        marginLeft: '12.5%',
      },
    },
    swipeIcon: {
      display: 'none',
    },
    swipeCarrousel: {
      '&:hover, :active, :focus': {
        '& $swipeIcon': {
          display: 'block !important',
        },
      },
    },
    neonText: {
      color: '#fff',
      textShadow: `0 0 7px #fff,
          0 0 10px #fff,
          0 0 21px #fff,
          0 0 42px #0fa,
          0 0 82px #0fa,
          0 0 92px #0fa,
          0 0 102px #0fa,
          0 0 151px #0fa`,
    },
    bigFont: {
      fontSize: '30px',
    },
  }),
);

export interface ExternalProps {
  categoryGames: CasinoCategory;
  isMobile: boolean;
  search: string;
  imageCDNUrl: string;
  setGame: (data: any) => void;
  addUpdateFavoriteGamePlayerAction: (data: any) => void;
  categorySelected: any;
}

export const setViewPort = (): number => {
  let _slidesPerView = 3;
  if (window.innerWidth <= 400) _slidesPerView = 3;
  else if (window.innerWidth > 400 && window.innerWidth <= 480) _slidesPerView = 4;
  else if (window.innerWidth > 480 && window.innerWidth <= 720) _slidesPerView = 5;
  else if (window.innerWidth > 720 && window.innerWidth <= 1024) _slidesPerView = 6;
  else if (window.innerWidth > 1024 && window.innerWidth <= 1280) _slidesPerView = 5;
  else if (window.innerWidth > 1280 && window.innerWidth <= 1600) _slidesPerView = 6;
  else _slidesPerView = 8;
  return _slidesPerView;
};

export function GameCarouselComponent(props: ExternalProps) {
  const classes = useStyles();
  const { categoryGames, setGame, isMobile, imageCDNUrl, search, addUpdateFavoriteGamePlayerAction, categorySelected } = props;
  const [viewAll, setViewAll] = useState(false);
  const [pages, setPages] = useState(1);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [localGames, setLocalGames] = useState<CasinoGameDto[]>([]);
  const [gameHeight, setGameHeight] = useState(150);
  const [gameCarouselHeight, setGameCarouselHeight] = useState<any>(310);

  const handleGameHeight = () => {
    let _gameHeight = 310;
    const _slidesPerView = setViewPort();
    const _gameCarrouselContWidth = document.getElementsByClassName('gameCarrouselCont')[0]?.clientWidth;
    const allMargin = (_slidesPerView - 1) * 10;
    _gameHeight = (((_gameCarrouselContWidth - allMargin) / _slidesPerView) * 4) / 3;
    setSlidesPerView(_slidesPerView);
    setGameHeight(_gameHeight);
    setGameCarouselHeight(window.innerWidth <= 720 ? _gameHeight * 2 + 10 : 'auto');
    // setGameCarouselHeight(window.innerWidth <= 720 && localGames.slice(0, 10).length > slidesPerView ? _gameHeight * 2 + 10 : 'auto');
  };

  window.addEventListener('resize', () => {
    handleGameHeight();
  });

  useEffect(() => {
    setLocalGames(filterGamesBySearch());
  }, [categoryGames]);

  useEffect(() => {
    setViewAll(categorySelected?.category_id != -1);
  }, [categorySelected]);

  useEffect(() => {
    setViewAll(categorySelected?.category_id != -1);
  }, []);

  useEffect(() => {
    handleGameHeight();
  }, [localGames]);

  useEffect(() => {
    setLocalGames(filterGamesBySearch());
  }, [categoryGames.games]);

  useEffect(() => {
    setLocalGames(filterGamesBySearch());
  }, [search]);

  const handlerClick = () => {
    setPages(1);
    setViewAll(false);
  };

  const filterGamesBySearch = () => {
    let tempGames: CasinoGameDto[] = [];
    if (categoryGames && search && search.trim().length > 0) {
      categoryGames.games.forEach((game: CasinoGameDto) => {
        if (game.game_description.toLowerCase().trim().includes(search.toLowerCase().trim())) {
          tempGames.push(game);
        } else if (game.provider_description.toLowerCase().trim().includes(search.toLowerCase().trim())) {
          tempGames.push(game);
        } else if (game.tags && game.tags.find((x: string) => x.toLowerCase().trim() === search.toLowerCase().trim())) {
          tempGames.push(game);
        }
      });
    } else {
      tempGames = categoryGames.games;
    }
    return tempGames;
  };

  return (
    <>
      {categoryGames && (
        <>
          <div className='vip-fadeIn'>
            <p style={{ marginBottom: '5px' }}>
              <span className={classes.bigFont}>{categoryGames.category_description}</span>
              {viewAll && (
                <Button
                  variant='contained'
                  color='primary'
                  style={{ float: 'right', margin: '10px' }}
                  className={classes.buttomHideAll}
                  onClick={handlerClick}
                >
                  Hide All
                </Button>
              )}
            </p>
          </div>
          <div>
            {!viewAll && (
              <Swiper
                navigation={true}
                slidesPerView={slidesPerView}
                grid={{ rows: window.innerWidth <= 720 ? 2 : 1 }}
                spaceBetween={10}
                modules={[Grid]}
                className={`${classes.swipeCarrousel} gameCarrouselCont`}
                style={{ height: gameCarouselHeight, margin: 0 }}
              >
                <span className={`${classes.swipeIcon}`}>
                  <ViphandSwip
                    style={{
                      width: '80px',
                      height: '80px',
                      position: 'absolute',
                      zIndex: '150',
                      marginTop: '30px',
                      marginLeft: 'calc(50% - 40px)',
                      top: 0,
                    }}
                  ></ViphandSwip>
                </span>
                {localGames.slice(0, 10).map((g: CasinoGameDto, index: number) => {
                  return (
                    <SwiperSlide key={`SS${index}`} style={{ height: gameHeight }} className='vip-fadeIn'>
                      <GameCardComponent
                        key={`HideAllGameCC${index}`}
                        game={g}
                        viewAll={viewAll}
                        viewMore={false}
                        setViewAll={setViewAll}
                        setGame={setGame}
                        isMobile={isMobile}
                        imageCDNUrl={imageCDNUrl}
                        addUpdateFavoriteGamePlayerAction={addUpdateFavoriteGamePlayerAction}
                      ></GameCardComponent>
                    </SwiperSlide>
                  );
                })}

                {categoryGames && categoryGames.games && localGames.length > 10 && (
                  <SwiperSlide key={`SSViewMore`} style={{ height: gameHeight }}>
                    <GameCardComponent
                      key={`HideAllGameCCViewMore`}
                      viewMore={true}
                      viewAll={viewAll}
                      setViewAll={setViewAll}
                      setGame={setGame}
                      isMobile={isMobile}
                      imageCDNUrl={imageCDNUrl}
                      addUpdateFavoriteGamePlayerAction={addUpdateFavoriteGamePlayerAction}
                    ></GameCardComponent>
                  </SwiperSlide>
                )}
              </Swiper>
            )}
            {viewAll && (
              <>
                <MuiGrid container direction='row' className='gameCarrouselCont'>
                  {localGames.slice(0, pages * 20).map((g: CasinoGameDto, index: number) => {
                    return (
                      <div style={{ width: `${100 / slidesPerView}%` }} key={`GameGridItemCC${index}`} className='vip-fadeIn'>
                        <GameCardComponent
                          key={`ViewAllGameCC${index}`}
                          game={g}
                          viewAll={viewAll}
                          viewMore={false}
                          setViewAll={setViewAll}
                          setGame={setGame}
                          isMobile={isMobile}
                          imageCDNUrl={imageCDNUrl}
                          addUpdateFavoriteGamePlayerAction={addUpdateFavoriteGamePlayerAction}
                        ></GameCardComponent>
                      </div>
                    );
                  })}
                </MuiGrid>
                <div>
                  {pages * 20 < localGames.length && (
                    <Button variant='contained' color='primary' className={classes.ShowMore} onClick={() => setPages(pages + 1)}>
                      Show More - Page {pages} of {(localGames.length / 20).toFixed(0)}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
