import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../../user.reducer';
import { mergeSaga } from '../../../../utils/redux-saga/merge-saga';
import { ICognitoCloseWindow } from '../../../../models/users';

const suffix = `/${userReducer.sliceName}/app`;

const CLOSE_COGNITO_FLOW_SESSION = `  CLOSE_COGNITO_FLOW_SESSION${suffix}`;
export const closeCognitoFlowSessionAction = createAction<ICognitoCloseWindow>(CLOSE_COGNITO_FLOW_SESSION);

const CLOSE_COGNITO_FLOW_SESSION_ERROR = `  CLOSE_COGNITO_FLOW_SESSION_ERROR${suffix}`;
const closeCognitoFlowSessionErrorAction = createAction<string>(CLOSE_COGNITO_FLOW_SESSION_ERROR);
const CLOSE_COGNITO_FLOW_SESSION_SUCCESS = `  CLOSE_COGNITO_FLOW_SESSION_SUCCESS${suffix}`;
const closeCognitoFlowSessionSuccessAction = createAction<ICognitoCloseWindow>(CLOSE_COGNITO_FLOW_SESSION_SUCCESS);

function* closeCognitoFlowSessionWatcher(): SagaIterator {
  yield takeEvery(CLOSE_COGNITO_FLOW_SESSION, closeCognitoFlowSessionWorker);
}
mergeSaga(closeCognitoFlowSessionWatcher);

function* closeCognitoFlowSessionWorker(action: Action<ICognitoCloseWindow>): SagaIterator {
  try {
    yield put(closeCognitoFlowSessionSuccessAction(action.payload));
  } catch (e: any) {
    yield put(closeCognitoFlowSessionErrorAction(e.response.data));
  }
}

const reduceHandlers = {
  [CLOSE_COGNITO_FLOW_SESSION]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [CLOSE_COGNITO_FLOW_SESSION_SUCCESS]: (slice: UserSliceType, action: Action<ICognitoCloseWindow>): UserSliceType => {
    let tmp_url = slice.shareable_url;
    let tmp_window_opened = slice.window_opened;

    try {
      if (tmp_url && tmp_window_opened) {
        if (tmp_url.toLocaleLowerCase().trim() == action.payload.url.toLocaleLowerCase().trim()) {
          tmp_window_opened.close();
          tmp_url = null;
          tmp_window_opened = null;
        }
      } else {
        const new_window = window.open('', 'cognito_tab');
        if (new_window) {
          tmp_url = null;
          tmp_window_opened = null;
          new_window.close();
        }
      }
    } catch (error) {
      console.error('closeCognitoFlowSessionAction', { error });
    }

    return {
      ...slice,
      shareable_url: tmp_url,
      window_opened: tmp_window_opened,
    };
  },
  [CLOSE_COGNITO_FLOW_SESSION_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    shareable_url: null,
    window_opened: null,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
