let sagaMiddlewareInstance: any;

const sagaToMerge: any[] = [];

function merge(): void {
  if (!sagaMiddlewareInstance) {
    return;
  }

  if (sagaToMerge.length) {
    sagaToMerge.forEach(saga => {
      sagaMiddlewareInstance.run(saga);
    });
    sagaToMerge.length = 0;
  }
}

export function initMergeSaga(sagaMiddleware: any): void {
  sagaMiddlewareInstance = sagaMiddleware;
  merge();
}

export function mergeSaga(saga: any): void {
  if (saga) {
    sagaToMerge.push(saga);
    merge();
  }
}

export const testPort = {
  sagaToMerge,
};
