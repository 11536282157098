import { createAction } from 'redux-actions';
import { UserSliceType, userReducer } from '../user.reducer';

const suffix = `/${userReducer.sliceName}/app`;

const LOGOUT = `LOGOUT${suffix}`;
export const logoutAction = createAction(LOGOUT);

const reduceHandlers = {
  [LOGOUT]: (slice: UserSliceType): UserSliceType => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('login_process');

    return {
      ...slice,
      isLoggedIn: false,
      loggedInUser: undefined,
      isInternalResetPasswordSuccess: false,
    };
  },
};
userReducer.addActionReducerMap(reduceHandlers);
