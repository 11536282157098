import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { SocketMessagesByDateDto } from '../../../models/Socket/socket-messages';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { SocketReducer, SocketSliceType } from '../socket-reducer';

const suffix = `/${SocketReducer.sliceName}/app`;

const GET_SO_MESSAGES_PLAYER_BY_DATE = `GET_SO_MESSAGES_PLAYER_BY_DATE${suffix}`;

export const SetSocketMessagesPlayerByDateAction = createAction<SocketMessagesByDateDto[]>(GET_SO_MESSAGES_PLAYER_BY_DATE);

const GET_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS = `GET_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS${suffix}`;
const SetSocketMessagesPlayerByDateSuccessAction = createAction(GET_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS);

const GET_SO_MESSAGES_PLAYER_BY_DATE_ERROR = `GET_SO_MESSAGES_PLAYER_BY_DATE_ERROR${suffix}`;
const SetSocketMessagesPlayerByDateErrorAction = createAction<string>(GET_SO_MESSAGES_PLAYER_BY_DATE_ERROR);

function* SetSocketMessagesPlayerByDateWatcher(): SagaIterator {
  yield takeEvery(GET_SO_MESSAGES_PLAYER_BY_DATE, SetSocketMessagesPlayerByDateWorker);
}

mergeSaga(SetSocketMessagesPlayerByDateWatcher);

function* SetSocketMessagesPlayerByDateWorker(action: Action<SocketMessagesByDateDto[]>): SagaIterator {
  try {
    yield put(SetSocketMessagesPlayerByDateSuccessAction(action.payload));
  } catch (e: any) {
    yield put(SetSocketMessagesPlayerByDateErrorAction(e));
  }
}

const reduceHandlers = {
  [GET_SO_MESSAGES_PLAYER_BY_DATE]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),

  [GET_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS]: (slice: SocketSliceType, action: Action<SocketMessagesByDateDto[]>): SocketSliceType => {
    const curMessages = slice.socketMessagesByDate.map(x => x);
    let notificationsDisplayed: SocketMessagesByDateDto[] = [];
    let notificationsPendingToDisplay: SocketMessagesByDateDto[] = [];

    action.payload.forEach((x: SocketMessagesByDateDto) => {
      const msg = curMessages.find(msg => msg.user_perm_component_id == x.user_perm_component_id);
      if (msg) {
        if (
          msg.message.trim() == x.message.trim() &&
          msg.socket_message_type_id == x.socket_message_type_id &&
          msg.date == x.message &&
          msg.repeat_frecuency == x.repeat_frecuency &&
          msg.repeat_times == x.repeat_times &&
          msg.status == x.status &&
          x.isNotified == true
        )
          x.isNotified = true;
      }
    });
    /*Checks if the notification displayed is already showed*/
    try {
      if (localStorage.getItem('notifications_by_date')) {
        notificationsDisplayed = JSON.parse(localStorage.getItem('notifications_by_date') ?? '');
        if (notificationsDisplayed && notificationsDisplayed.length > 0) {
          action.payload.forEach((x: SocketMessagesByDateDto) => {
            const msg = notificationsDisplayed.find(msg => msg.id == x.id);
            if (msg) {
              if (
                msg.message.trim() == x.message.trim() &&
                msg.socket_message_type_id == x.socket_message_type_id &&
                msg.date == x.date &&
                msg.repeat_frecuency == x.repeat_frecuency &&
                msg.repeat_times == x.repeat_times &&
                msg.status == x.status
              )
                x.isNotified = true;
              else {
                if (
                  msg.message.trim() != x.message.trim() &&
                  msg.socket_message_type_id != x.socket_message_type_id &&
                  msg.date != x.date &&
                  msg.repeat_frecuency != x.repeat_frecuency &&
                  msg.repeat_times != x.repeat_times &&
                  msg.status != x.status
                ) {
                  notificationsDisplayed = notificationsDisplayed.filter(x => x.id != msg.id);
                }
              }
            }
          });
          localStorage.setItem('notifications_by_date', JSON.stringify(notificationsDisplayed));
        }
      }
    } catch (error) {
      console.log('SetSocketMessagesPlayerByDateAction', { error });
    }

    /*Checks if the notification displayed is already showed*/
    try {
      if (localStorage.getItem('notifications_by_date_pending')) {
        notificationsPendingToDisplay = JSON.parse(localStorage.getItem('notifications_by_date_pending') ?? '');
        if (notificationsPendingToDisplay && notificationsPendingToDisplay.length > 0) {
          action.payload.forEach((x: SocketMessagesByDateDto) => {
            let msg = notificationsPendingToDisplay.find(msg => msg.id == x.id);
            if (msg) {
              if (
                msg.message.trim() != x.message.trim() &&
                msg.socket_message_type_id != x.socket_message_type_id &&
                msg.date != x.date &&
                msg.repeat_frecuency != x.repeat_frecuency &&
                msg.repeat_times != x.repeat_times &&
                msg.status != x.status
              ) {
                msg = { ...msg, status: x.status, message: x.message, socket_message_type_id: x.socket_message_type_id };
              }
            }
          });
          localStorage.setItem('notifications_by_date_pending', JSON.stringify(notificationsDisplayed));
        }
      }
    } catch (error) {
      console.log('SetSocketMessagesPlayerByDateAction', { error });
    }

    return {
      ...slice,
      socketMessagesByDate: action.payload,
    };
  },

  [GET_SO_MESSAGES_PLAYER_BY_DATE_ERROR]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),
};

SocketReducer.addActionReducerMap(reduceHandlers);
