import { createAction } from 'redux-actions';
import { NamedReducerType } from './reducers.types';

let store: any;
let createRootReducer: any;
let reducersToAdd: any;

function init(reduxStore: any, rootReducerCreator: (asyncReducers: any[]) => any): void {
  store = reduxStore;
  createRootReducer = rootReducerCreator;
  merge();
}

function getStore(): any {
  return store;
}

function getState(): any {
  return store ? store.getState() : undefined;
}

function dispatch(typeOrAction: string | any, payload?: any): void {
  if (store) {
    const action = typeof typeOrAction === 'string' ? createAction(typeOrAction)(payload) : typeOrAction;
    store.dispatch(action);
  }
}

function mergeTopReducer(reducer: NamedReducerType): NamedReducerType {
  if (reducer.sliceName !== reducer.slicePath) {
    throw new Error(`'${reducer.slicePath}' is not a Top Reducer`);
  }
  reducersToAdd = reducersToAdd || {};
  reducersToAdd[reducer.sliceName] = reducer;
  merge();
  return reducer;
}

function merge(): void {
  if (!store) {
    return;
  }

  if (store.asyncReducers === undefined) {
    store.asyncReducers = {};
  }

  if (reducersToAdd) {
    Object.keys(reducersToAdd).forEach((sliceName: string) => {
      const reducer = reducersToAdd[sliceName];

      if (sliceName in store.asyncReducers) {
        const mod: any = module;
        if (process.env.NODE_ENV === 'development' && mod.hot) {
          store.asyncReducers[sliceName] = reducer;
        } else {
          // eslint-disable-next-line no-console
          console.error(`TopReducer '${sliceName}' already exists!`);
        }
      } else {
        store.asyncReducers[sliceName] = reducer;
      }
    });
    reducersToAdd = undefined;
    store.replaceReducer(createRootReducer(store.asyncReducers));
  }
}

export const storeHelper = {
  dispatch,
  init,
  getStore,
  getState,
  mergeTopReducer,
};
