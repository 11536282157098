import { connect } from 'react-redux';
import { isLoadingSelector, IsVerificationSuccessSelector, loggedInUserSelector } from '../../../api/user/user.selector';
import { ExternalActionProps, ExternalProps, VerifyEmailPageComponent } from './verify-email-page.component';
import { ValidateEmailVerificationCodeAction } from '../../../api/user/actions/validate-email-verification-code.action';
import { getUserInfoAction } from '../../../api/user/actions/get-user-info.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    isVerificationSuccess: IsVerificationSuccessSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  ValidateEmailVerificationCodeAction,
  getUserInfoAction,
};

export const VerifyEmailPageContainer = connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPageComponent);
