import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { API_PATH } from '../../../constants';
import { IGetAll } from '../../../models/get-all';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { showToast } from '../../../utils/toast/show-errors';
import { vipApi } from '../../../utils/vip-api';
import { MessageSliceType, MessageSelectionReducer } from '../message.reducer';
import { IMessageByUser } from '../../../models/Messages/messages';

const suffix = `/${MessageSelectionReducer.sliceName}/app`;

const GET_MESSAGES_BY_USER = `GET_MESSAGES_BY_USER${suffix}`;

export const GetMessagesByUserAction = createAction(GET_MESSAGES_BY_USER);

const GET_MESSAGES_BY_USER_SUCCESS = `GET_MESSAGES_BY_USER_SUCCESS${suffix}`;
const GetMessagesByUserSuccessAction = createAction<IMessageByUser[]>(GET_MESSAGES_BY_USER_SUCCESS);

const GET_MESSAGES_BY_USER_ERROR = `GET_MESSAGES_BY_USER_ERROR${suffix}`;
const GetMessagesByUserErrorAction = createAction<string>(GET_MESSAGES_BY_USER_ERROR);

function* GetMessagesByUserWatcher(): SagaIterator {
  yield takeEvery(GET_MESSAGES_BY_USER, GetMessagesByUserWorker);
}
mergeSaga(GetMessagesByUserWatcher);

function* GetMessagesByUserWorker(): SagaIterator {
  try {
    const result: IGetAll<IMessageByUser> = yield call(GetMessagesByUserApi);
    yield put(GetMessagesByUserSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(GetMessagesByUserErrorAction(e));
  }
}

const GetMessagesByUserApi = (): Request => {
  return vipApi(API_PATH.MESSAGES.GET_BY_USER, 'get', {});
};

const reduceHandlers = {
  [GET_MESSAGES_BY_USER]: (slice: MessageSliceType): MessageSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_MESSAGES_BY_USER_SUCCESS]: (slice: MessageSliceType, action: Action<IMessageByUser[]>): MessageSliceType => ({
    ...slice,
    isLoading: false,
    messages: action.payload,
  }),
  [GET_MESSAGES_BY_USER_ERROR]: (slice: MessageSliceType): MessageSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

MessageSelectionReducer.addActionReducerMap(reduceHandlers);
