export enum RouteSiteEnum {
  CASHIER = '/cashier',
  CASHIER_METHOD = '/cashier/method',
  CASHIER_INFO = '/cashier/info',
  CASHIER_BONUS = '/cashier/bonus',
  CASHIER_CONFIRM = '/cashier/confirm',
  CASHIER_PAYMENT = '/cashier/payment',
  CASHIER_COMPLETE = '/cashier/complete',
  CASHIER_BANK = '/cashier/bank-select',
  SPORTSBOOK = '/sportsbook',
}
