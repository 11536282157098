import { useLayoutEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { InvitationCodeEnum } from '../enum/signup/invitation-code-enum';
import moment from 'moment';
const cookies = new Cookies();

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const handleGetInviteCode = () => {
  let invite_code: string = InvitationCodeEnum.DEFAULT;
  try {
    const cookie_invite_code = cookies.get('inviter_code');
    if (cookie_invite_code != null && cookie_invite_code != '') {
      invite_code = cookie_invite_code;
    }
  } catch (error) {
    console.error('Error Getting Invite Code', error);
  }
  return invite_code;
};

export const removeCookies = () => {
  const cookie_invite_code = cookies.get('inviter_code');
  if (cookie_invite_code != null) {
    console.log('FN removeCookies V2');
    const momentDate = moment().add(-1, 'month');
    cookies.set('inviter_code', cookie_invite_code, {
      path: '/',
      domain: location.hostname,
      expires: momentDate.toDate(),
      secure: true,
      sameSite: true,
    });
  }
};

export const setCustomData = (data: any) => {
  try {
    console.log('Setting Custom Data');

    if (window && window.BXLiveChat) {
      window.BXLiveChat.setCustomData([
        {
          USER: {
            NAME: (data.first_name ?? 'Unknown') + ' ' + (data.last_name ?? 'Unknown'), // Insert First and last name.
          },
        },
        {
          GRID: [
            {
              NAME: 'Player Number',
              VALUE: data.username ?? 'Unknown',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Phone',
              VALUE: data.phone ?? 'Unknown',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'E-mail',
              VALUE: data.email ?? 'Unknown',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Website',
              VALUE: location.hostname,
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Page',
              VALUE: '[url=' + location.href + ']' + (document.title || location.href) + '[/url]',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Is Verified?',
              VALUE: data.cognito_match == 1 ? 'Yes' : 'No',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Player Category',
              VALUE: data?.player_category?.name ?? 'Unknown',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'SignUp Date',
              VALUE: data?.sign_up_date ?? 'Unknown',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Deposits enabled?',
              VALUE: data.can_deposit == 1 ? 'Yes' : 'No',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Withdrawals enabled?',
              VALUE: data.can_withdraw == 1 ? 'Yes' : 'No',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Can Withdraw?',
              VALUE:
                data.can_perform_withdrawal != null || data.can_perform_withdrawal != undefined
                  ? data.can_perform_withdrawal == true
                    ? 'Yes'
                    : 'No'
                  : 'Unknown',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'No Withdrawal Reason',
              VALUE: data.can_perform_withdrawal_message ?? 'NA',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Email Verified?',
              VALUE: data.email_verified_at != null ? 'Yes' : 'No',
              DISPLAY: 'LINE',
            },
            {
              NAME: 'Phone Verified?',
              VALUE: data.phone_verified_at != null ? 'Yes' : 'No',
              DISPLAY: 'LINE',
            },
          ],
        },
      ]);
    } else {
      setTimeout(() => {
        console.log('Retrying Setting Custom Data');
        setCustomData(data);
      }, 500);
    }
  } catch (error) {
    console.error('Error Setting Custom Data', error);
  }
};
