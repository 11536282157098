import React, { memo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IMessageByUser, IMessageMarkAsRead } from '../../../../models/Messages/messages';
import { Grid, Typography } from '@material-ui/core';
import { timeSince } from '../../../functions/common';

export interface ExternalProps {
  message: IMessageByUser;
  classes: any;
  index: number;
}
export interface ExternalActionProps {
  UpdateMessagesByUserMarkAsReadAction: (data: IMessageMarkAsRead) => void;
}

const MessageDialogComponentAccordionItem = (props: ExternalProps & ExternalActionProps) => {
  const { message, UpdateMessagesByUserMarkAsReadAction, classes, index } = props;

  return (
    <Accordion className={`${index % 2 === 0 ? classes.bgColor : ''}  ${classes.boxMessageSelected}  `}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => {
          if (message.viewed_at == null) {
            UpdateMessagesByUserMarkAsReadAction({ id: message.id });
          }
        }}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography
          className={`${classes.box} ${message.viewed_at == null ? classes.unreadMessage : ''}`}
          key={message.id}
          style={{ width: '100%', textAlign: 'left' }}
        >
          {message.viewed_at == null && <p className={classes.newMessage}> New</p>}

          <small>{timeSince(message.sent_at ?? message.created_at ?? new Date().toString())} </small>
          <br />
          {message.title}
          <p className={classes.htmlContent} dangerouslySetInnerHTML={{ __html: message.content.substring(0, 30).concat('...') }}></p>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={`${classes.boxMessage} ${index % 2 !== 0 ? classes.bgColor : classes.bgColorDefault}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              Subject:
            </Grid>
            <Grid item xs={12} md={9}>
              {message.title}
            </Grid>
            <Grid item xs={12} md={3}>
              Received At:
            </Grid>
            <Grid item xs={12} md={9}>
              {new Date(message.sent_at ?? message.created_at ?? new Date().toString()).toLocaleString()}
              <br />
              <small>{timeSince(message.sent_at ?? message.created_at ?? new Date().toString())}</small>
            </Grid>
            <Grid item xs={12}>
              Message:
            </Grid>
            <Grid item xs={12}>
              <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(MessageDialogComponentAccordionItem);
