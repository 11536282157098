import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { API_PATH } from '../../../constants';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const GET_GAME_RATING = `GET_GAME_RATING${suffix}`;

export const getGameRatingAction = createAction<any>(GET_GAME_RATING);

const GET_GAME_RATING_SUCCESS = `GET_GAME_RATING_SUCCESS${suffix}`;
const getGameRatingSuccessAction = createAction<any>(GET_GAME_RATING_SUCCESS);

const GET_GAME_RATING_ERROR = `GET_GAME_RATING_ERROR${suffix}`;
const getGameRatingErrorAction = createAction<string>(GET_GAME_RATING_ERROR);

function* getGameRatingWatcher(): SagaIterator {
  yield takeEvery(GET_GAME_RATING, getGameRatingWorker);
}
mergeSaga(getGameRatingWatcher);

function* getGameRatingWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getGameRatingApi, action.payload);
    yield put(getGameRatingSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(getGameRatingErrorAction(e));
  }
}

const getGameRatingApi = (data: any): Request => {
  return vipApi(API_PATH.TPI.GET_GAME_RATING, 'post', { data });
};

const reduceHandlers = {
  [GET_GAME_RATING]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_GAME_RATING_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    gameRatings: action.payload?.data ?? [],
  }),
  [GET_GAME_RATING_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
