import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../shared/loading/loading.component';
import { Paper, Typography, makeStyles } from '@material-ui/core';

export interface ExternalProps {
  isLoading: boolean;
  isVerificationSuccess: boolean | null;
  user: any;
}

export interface ExternalActionProps {
  ValidateEmailVerificationCodeAction: (code: string) => void;
  getUserInfoAction: () => void;
}

const useStyles = makeStyles({
  shrinkLogo: {
    maxWidth: 100,
    height: 'auto',
    margin: '0px auto',
    display: 'block',
  },
  moveForgetPasswordToRight: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: 20,
  },
  adjustEmailMargin: {
    marginTop: 30,
    marginBottom: 30,
  },
  centerForgetPassword: {
    textAlign: 'center',
    marginBottom: 20,
  },
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.jpg` + ')',
  },
  styleForgetPasswordParagraph: {
    textAlign: 'center',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: 450,
    backgroundColor: '#101010',
    borderRadius: '25px',
  },
  form: {
    padding: 24,
  },
});

export const VerifyEmailPageComponent = (props: ExternalActionProps & ExternalProps) => {
  const { ValidateEmailVerificationCodeAction, getUserInfoAction, isLoading, isVerificationSuccess, user } = props;
  const { code } = useParams<any>();
  const classes = useStyles();
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (code) {
        ValidateEmailVerificationCodeAction(code);
      }
    }, 500);

    setTimeout(() => {
      setDisplayMessage(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isVerificationSuccess == true && user && user.username) {
      getUserInfoAction();
    }
  }, [isVerificationSuccess]);

  return (
    <>
      <Loading loading={isLoading} />
      <div className={classes.container}>
        <div className={classes.helper}>
          <Paper className={classes.content} elevation={3}>
            <div className={classes.form}>
              <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
              <Typography className={classes.centerForgetPassword} variant='h5'>
                Email Verification
              </Typography>
              <Typography className={classes.styleForgetPasswordParagraph} variant='subtitle2'>
                {isVerificationSuccess == null && !isLoading && displayMessage == true && (
                  <Typography variant='body1' style={{ textAlign: 'center' }}>
                    An unexpected error occurred during the validation process. Please try again later or contact support.
                  </Typography>
                )}
                {isVerificationSuccess == null && displayMessage == false && (
                  <Typography variant='body1' style={{ textAlign: 'center' }}>
                    Validating your email address...
                  </Typography>
                )}

                {isVerificationSuccess == true && !isLoading && (
                  <Typography variant='body1' style={{ textAlign: 'center' }}>
                    Success! Your email has been successfully validated. Thank you for confirming your address.
                  </Typography>
                )}

                {isVerificationSuccess == false && !isLoading && (
                  <Typography variant='body1' style={{ textAlign: 'center' }}>
                    We&amp;re sorry, we couldn&amp;t validate your email. Please check your information and try again.
                  </Typography>
                )}
              </Typography>
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
};
