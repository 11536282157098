import { CountryData } from 'react-intl-tel-input';
import { IBonus } from './Bonus/bonus';

export interface Users {
  id?: number;
  name: string;
  playerNumber: string;
  email: string;
  phoneNumber: string;
  commissionPercentage: string;
  first_name: string;
  last_name: string;
}

export interface ISignup {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  fullPhone: string;
  password: string;
  password_confirmation: string;
  inviter_code: string;
  country_code: string;
  fingerprint: string | null;
  ip: string;
  countryData: CountryData;
  agreed: boolean;
  default_invite_code: boolean;
}

export interface IIdentityDialog {
  open: boolean;
  message: string | null;
  result: boolean;
  playerProfileSettingsPaymentMethodBonuses: IBonus[];
}

export interface IIdentityDialogFrame {
  open: boolean;
  url: string | null;
}

export enum ISignupStatusInternalEnum {
  SUCCESS = 0,
  INVALID_REQUEST = -1,
  EMAIL_EXISTS = -2,
  INVALID_PHONE_NUMBER = -3,
  PHONE_NUMBER_IS_NOT_MOBILE = -4,
  INVALID_EMAIL = -5,
  CANT_CREATE_PLAYER_ON_DGS = -6,
  EXCEPTION = -7,
  UNKNOWN = -8,
  PHONE_EXISTS = -9,
  INVITE_CODE_DOES_NOT_EXIST = -10,
}

export interface ICognitoReTryFlowSession {
  url: string;
  new_window: Window | null;
}

export interface ICognitoCloseWindow {
  url: string;
}

export interface ICognitoWindow {
  new_window: Window | null;
}

export const EmptyIdentityDialogFrame: IIdentityDialogFrame = {
  open: false,
  url: null,
};
