import React, { memo, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, Button, Grid, createStyles, IconButton, TextField } from '@material-ui/core';
import { IMessageByUser, IMessageMarkAsRead } from '../../../../models/Messages/messages';
import { timeSince } from '../../../functions/common';
import MessageDialogComponentAccordionItem from './message-dialog-accordion.component';
import SearchIcon from '@material-ui/icons/Search';
import { IPlayerSession } from '../../../../models/player';

const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    root: {
      marginTop: '40px',
    },
    paper: {
      padding: '20px',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    bgColor: {
      backgroundColor: '#262626 !important',
    },
    bgColorDefault: {
      backgroundColor: '#2d2d2d !important',
    },
    box: {
      padding: '10px',
    },
    htmlContent: {
      fontSize: 'small !important',
      marginBottom: '0px !important',
    },
    messageList: {
      maxHeight: '500px',
      overflow: 'auto',
      marginRight: '10px',
    },
    boxMessage: {
      padding: '30px',
      backgroundColor: '#262626',
      height: '100%',
      marginLeft: '10px',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '10px !important',
        marginLeft: '0px !important',
      },
    },
    newMessage: {
      color: '#c8a964',
      margin: '0px',
    },
    buttonShowAll: {
      marginTop: '10px !important',
      marginBottom: '10px !important',
      maxWidth: '90%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    boxMessageSelected: {
      maxWidth: '100%',
      border: '1px solid #c8a964',
    },
    fullWidth: {
      width: '100%',
    },
    marginBottom: {
      marginBottom: '10px',
    },
    unreadMessage: {
      fontWeight: 'bold',
    },
  }),
);

const sortMessages = (messages: IMessageByUser[], search: string) => {
  if (search.trim().length > 0) {
    messages = messages.filter(
      message => message.title.toLowerCase().includes(search.toLowerCase()) || message.content.toLowerCase().includes(search.toLowerCase()),
    );
  }

  const notViewed = messages.filter(message => message.viewed_at === null);

  const viewed = messages.filter(message => message.viewed_at !== null);

  viewed.sort((a, b) => new Date(b.sent_at ?? b.created_at).getTime() - new Date(a.sent_at ?? a.created_at).getTime());

  return [...notViewed, ...viewed];
};

export interface ExternalProps {
  messages: IMessageByUser[];
  isLoadingMessages: boolean;
  user: IPlayerSession;
}
export interface ExternalActionProps {
  GetMessagesByUserAction: () => void;
  UpdateMessagesByUserMarkAsReadAction: (data: IMessageMarkAsRead) => void;
  onClose: () => void;
}

const MessageDialogComponent = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { messages, isLoadingMessages, user, GetMessagesByUserAction, UpdateMessagesByUserMarkAsReadAction, onClose } = props;
  const [selectedMessage, setSelectedMessage] = useState<IMessageByUser | null>(null);
  const [localMessages, setLocalMessages] = useState<IMessageByUser[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [limitMessagesToDisplay] = useState(user?.message_records_to_show ?? 10);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLocalMessages(sortMessages(messages, searchText));
  }, [messages]);

  useEffect(() => {
    if (showAll == true) {
      setLocalMessages(sortMessages(messages, searchText));
    } else {
      setLocalMessages(sortMessages(messages, searchText).slice(0, limitMessagesToDisplay));
    }
  }, [showAll]);

  useEffect(() => {
    setLocalMessages(sortMessages(messages, searchText));
  }, [searchText]);

  const LocalButton = (
    <>
      {messages.length > limitMessagesToDisplay && searchText.trim().length == 0 && (
        <div className={`${classes.textAlignCenter} ${classes.fullWidth}`}>
          <Button
            className={classes.buttonShowAll}
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'Show Less' : 'Show All'}
          </Button>
        </div>
      )}
    </>
  );

  return (
    <Dialog open={true} onClose={onClose} maxWidth='md' fullWidth aria-labelledby='max-width-dialog-title' className={classes.root}>
      <DialogContent style={{ minHeight: '600px' }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <h2>Inbox</h2>
          </Grid>
          <Grid item xs={6} className={`${classes.textAlignRight}`}>
            <IconButton
              color='inherit'
              onClick={() => {
                setShowSearchBar(!showSearchBar);
              }}
            >
              <SearchIcon />
            </IconButton>
            <Button variant='contained' color='primary' onClick={() => GetMessagesByUserAction()}>
              Refresh
            </Button>
          </Grid>
          {showSearchBar && (
            <Grid item xs={12} className={classes.marginBottom}>
              <TextField fullWidth label='Search' value={searchText} onChange={e => setSearchText(e.target.value)} />
            </Grid>
          )}
          {!isLoadingMessages && messages.length === 0 && (
            <Grid item xs={12} className={classes.textAlignCenter}>
              <h3>No messages</h3>
            </Grid>
          )}

          {!isMobile && (
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12} className={classes.messageList}>
                  {localMessages.map((message: IMessageByUser, index: number) => (
                    <div
                      className={`${index % 2 === 0 ? classes.bgColor : ''} ${classes.box} ${
                        message.id === (selectedMessage?.id ?? -1) ? classes.boxMessageSelected : ''
                      }  ${message.viewed_at == null ? classes.unreadMessage : ''}`}
                      key={message.id}
                      onClick={() => {
                        setSelectedMessage(message);
                        if (message.viewed_at == null) {
                          UpdateMessagesByUserMarkAsReadAction({ id: message.id });
                        }
                      }}
                      style={{ width: '100%', textAlign: 'left' }}
                    >
                      {message.viewed_at == null && <p className={classes.newMessage}> New</p>}

                      <small>{timeSince(message.sent_at ?? message.created_at ?? new Date().toString())} </small>
                      <br />
                      {message.title}
                      <p
                        className={classes.htmlContent}
                        dangerouslySetInnerHTML={{ __html: message.content.substring(0, 30).concat('...') }}
                      ></p>
                    </div>
                  ))}

                  {LocalButton}
                </Grid>
              </Grid>
            </Grid>
          )}

          {!isMobile && (
            <Grid item xs={8}>
              <div className={`${classes.boxMessage} ${classes.bgColor}`}>
                {selectedMessage ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      Subject:
                    </Grid>
                    <Grid item xs={12} md={9}>
                      {selectedMessage.title}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Received At:
                    </Grid>
                    <Grid item xs={12} md={9}>
                      {new Date(selectedMessage.sent_at ?? selectedMessage.created_at ?? new Date().toString()).toLocaleString()}
                      <br />
                      <small>{timeSince(selectedMessage.sent_at ?? selectedMessage.created_at ?? new Date().toString())}</small>
                    </Grid>
                    <Grid item xs={12}>
                      Message:
                    </Grid>
                    <Grid item xs={12}>
                      <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }}></div>
                    </Grid>
                  </Grid>
                ) : (
                  <div className={classes.textAlignCenter}>
                    <h3>Select a message to view</h3>
                    <p>Click on a message to view its content</p>
                  </div>
                )}
              </div>
            </Grid>
          )}
          {isMobile && (
            <>
              {localMessages.map((message: IMessageByUser, index: number) => (
                <MessageDialogComponentAccordionItem
                  key={message.id}
                  message={message}
                  classes={classes}
                  UpdateMessagesByUserMarkAsReadAction={UpdateMessagesByUserMarkAsReadAction}
                  index={index}
                />
              ))}
              {LocalButton}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.textAlignRight}>
        <Button
          onClick={() => {
            onClose();
          }}
          color='primary'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(MessageDialogComponent);
