const PATH_SEPARATOR = '/';
const SUFFIX_SEPARATOR = '__';

export interface SliceHelperType {
  sliceName: string;
  slicePath: string;
  sliceSelector: (state: any) => any;
  suffixKey: (key: string) => string;
}

export function createSliceHelper(parentSliceHelper: null | SliceHelperType, sliceName: string, initialSlice: any): SliceHelperType {
  if (!sliceName || sliceName.indexOf(PATH_SEPARATOR) !== -1 || sliceName.indexOf(SUFFIX_SEPARATOR) !== -1) {
    throw new Error(`invalid sliceName: ${sliceName}`);
  }

  const slicePath: string = parentSliceHelper ? `${parentSliceHelper.slicePath}${PATH_SEPARATOR}${sliceName}` : sliceName;

  const suffix = slicePath.split(PATH_SEPARATOR).reverse().join(SUFFIX_SEPARATOR);

  const sliceSelector = (state: any): any => {
    const parentSlice = parentSliceHelper ? parentSliceHelper.sliceSelector(state) : state;

    const slice = parentSlice ? parentSlice[sliceName] : undefined;
    if (slice === undefined) {
      return initialSlice;
    }

    return slice;
  };

  const suffixKey = (key: string): string => {
    return `${key}${SUFFIX_SEPARATOR}${suffix}`;
  };

  return {
    sliceName,
    slicePath,
    sliceSelector,
    suffixKey,
  };
}

export const testPort = {
  PATH_SEPARATOR,
  SUFFIX_SEPARATOR,
};
