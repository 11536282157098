import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { ContDialog } from '../../../area/cashier/cashier-home-page/components/ContDiaglog';
import { ContTable } from '../../../area/cashier/cashier-home-page/components/ContTable';
import { WageringDialoge } from '../../../area/cashier/cashier-home-page/components/WageringDialoge';
import { WageringHistoryTable } from '../../../area/cashier/cashier-home-page/components/WageringHistoryTable';
import { CasinoBonusesDto } from '../../../models/CasinoLobbyV2/CasinoBonusesDto';
import { Loading } from '../../loading/loading.component';
import { RolloverTransactionComponent } from './bonus-components/rollover-transaction.component';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto',
      maxHeight: '700px',
      overflow: 'auto',
      borderRadius: '5px',
      marginTop: '1%',
      marginBottom: '1%',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        minWidth: '500px',
      },
    },
    header: {
      backgroundColor: '#1f4d72',
      padding: '.75rem',
      display: 'flex',
      flexDirection: 'column',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    sectionContainer: {
      margin: 'auto',
      width: '100%',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    body: {
      backgroundColor: '#2d2d2d',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    buttonAllPromotions: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    textAlign: {
      textAlign: 'center',
    },
  }),
);

export interface ExternalActionProps {
  getPromotionAction: () => void;
  setShowBonus: (x: boolean) => void;
  getPlayerRolloverAction: (x: any) => void;
  ptpiGetCasinoBonusesAction: () => void;
  ptpiGetCasinoBonusAction: (x: any) => void;
}

export interface ExternalProps {
  promotions: any[];
  rollover: any;
  playerNumber: string;
  casinoBonuses: CasinoBonusesDto[];
  isLoading: boolean;
  user: any;
}

export const DialogBonusCard = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getPlayerRolloverAction, rollover, playerNumber, setShowBonus, isLoading, user } = props;
  const [open, setOpen] = useState(false);
  const [contOpen, setContOpen] = useState(false);
  const [showBonusGif, setShowBonusGif] = useState<boolean>(false);

  const handleOpen = () => {
    if (!rollover || (rollover && rollover.wager_data && rollover.wager_data.length == 0)) {
      toastr.warning('No data found', 'No wagers.');
      return;
    }
    setOpen(true);
  };

  const handleContOpen = () => {
    if (!rollover || (rollover && rollover.contribution_table && rollover.contribution_table.length == 0)) {
      toastr.warning('No data found', 'No contributions.');
      return;
    }

    setContOpen(true);
  };

  useEffect(() => {
    if (playerNumber) getPlayerRolloverAction(playerNumber);
  }, [playerNumber]);

  useEffect(() => {
    const existKey = localStorage.getItem('BonusGif');
    if (existKey != null) {
      setShowBonusGif(localStorage.getItem('BonusGif') == 'true' ? true : false);
    } else {
      setShowBonusGif(true);
      localStorage.setItem('BonusGif', 'true');
    }
  }, []);

  return (
    <>
      <Loading loading={isLoading} />
      <div className={`${classes.container} vip-fadeIn`}>
        <span onClick={() => setShowBonus(false)}>
          <FontAwesomeIcon
            icon={['fad', 'times-circle']}
            style={{
              color: '#c8a964',
              position: 'absolute',
              right: '0',
              marginRight: '20px',
              height: '20px',
              width: '20px',
              marginTop: '20px',
            }}
          />
        </span>

        <div className={classes.sectionContainer}>
          <RolloverTransactionComponent
            rollover={rollover}
            playerNumber={playerNumber}
            handleOpen={handleOpen}
            handleContOpen={handleContOpen}
            user={user}
            setShowBonus={setShowBonus}
            showBonusGif={showBonusGif}
            setShowBonusGif={setShowBonusGif}
          ></RolloverTransactionComponent>
        </div>

        {/* {casinoBonuses && (
          <div className={classes.sectionContainer} style={{ marginTop: '20px', marginBottom: '0px' }}>
            <div className={classes.header}>
              <div className={classes.headerTop}>
                <Typography variant='h6'>Free Spins &amp; Chips </Typography>
              </div>
            </div>
            <div className={classes.body}>
              {<BonusListComponent setShowBonus={setShowBonus} casinoBonuses={casinoBonuses}></BonusListComponent>}
            </div> 
          </div>
        )} */}
      </div>
      <WageringDialoge
        open={open}
        onClose={() => setOpen(false)}
        data={<WageringHistoryTable rows={rollover ? rollover.wager_data : []} />}
      />
      <ContDialog
        open={contOpen}
        onClose={() => setContOpen(false)}
        data={<ContTable rows={rollover ? rollover.contribution_table : []} />}
      />
    </>
  );
};
