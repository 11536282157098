import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { API_PATH } from '../../../constants';
import { IGetGameRequestDto } from '../../../models/CasinoLobbyV2/CasinoLobbyDto';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const GET_CASINO_LOBBY = `GET_CASINO_LOBBYV2${suffix}`;
const CLEAR_CASINO_LOBBY = `CLEAR_CASINO_LOBBY${suffix}`;

export const getCasinoLobbyAction = createAction<IGetGameRequestDto>(GET_CASINO_LOBBY);
export const clearCasinoLobbyAction = createAction(CLEAR_CASINO_LOBBY);

const GET_CASINO_LOBBY_SUCCESS = `GET_CASINO_LOBBY_SUCCESSV2${suffix}`;
const getCasinoLobbySuccessAction = createAction<any[]>(GET_CASINO_LOBBY_SUCCESS);

const GET_CASINO_LOBBY_ERROR = `GET_CASINO_LOBBY_ERRORV2${suffix}`;
const getCasinoLobbyErrorAction = createAction<string>(GET_CASINO_LOBBY_ERROR);

function* getCasinoLobbyWatcher(): SagaIterator {
  yield takeEvery(GET_CASINO_LOBBY, getCasinoLobbyWorker);
}

mergeSaga(getCasinoLobbyWatcher);

function* getCasinoLobbyWorker(action: Action<IGetGameRequestDto>): SagaIterator {
  try {
    const result: any = yield call(getCasinoLobbyApi, action.payload);
    yield put(getCasinoLobbySuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response?.data);
    yield put(getCasinoLobbyErrorAction(e));
  }
}

const getCasinoLobbyApi = (data: IGetGameRequestDto): Request => {
  return vipApi(API_PATH.TPI.CASINO_LOBBY, 'post', data, 'json');
};

const reduceHandlers = {
  [GET_CASINO_LOBBY]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
    casinoLobby: null,
  }),
  [GET_CASINO_LOBBY_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoLobby: action.payload?.data ?? null,
  }),
  [GET_CASINO_LOBBY_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoLobby: null,
  }),
  [CLEAR_CASINO_LOBBY]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    casinoLobby: null,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
