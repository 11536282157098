import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { SocketMessagesDto } from '../../../models/Socket/socket-messages';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { SocketReducer, SocketSliceType } from '../socket-reducer';

const suffix = `/${SocketReducer.sliceName}/app`;

const GET_SO_MESSAGES_PLAYER = `GET_SO_MESSAGES_PLAYER${suffix}`;

export const SetSocketMessagesPlayerAction = createAction<SocketMessagesDto[]>(GET_SO_MESSAGES_PLAYER);

const GET_SO_MESSAGES_PLAYER_SUCCESS = `GET_SO_MESSAGES_PLAYER_SUCCESS${suffix}`;
const GetSocketMessagesPlayerSuccessAction = createAction(GET_SO_MESSAGES_PLAYER_SUCCESS);

const GET_SO_MESSAGES_PLAYER_ERROR = `GET_SO_MESSAGES_PLAYER_ERROR${suffix}`;
const GetSocketMessagesPlayerErrorAction = createAction<string>(GET_SO_MESSAGES_PLAYER_ERROR);

function* GetSocketMessagesPlayerWatcher(): SagaIterator {
  yield takeEvery(GET_SO_MESSAGES_PLAYER, GetSocketMessagesPlayerWorker);
}

mergeSaga(GetSocketMessagesPlayerWatcher);

function* GetSocketMessagesPlayerWorker(action: Action<SocketMessagesDto[]>): SagaIterator {
  try {
    yield put(GetSocketMessagesPlayerSuccessAction(action.payload));
  } catch (e: any) {
    yield put(GetSocketMessagesPlayerErrorAction(e));
  }
}

const reduceHandlers = {
  [GET_SO_MESSAGES_PLAYER]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),

  [GET_SO_MESSAGES_PLAYER_SUCCESS]: (slice: SocketSliceType, action: Action<SocketMessagesDto[]>): SocketSliceType => {
    let notificationsDisplayed: SocketMessagesDto[] = [];

    /*Checks if the notification displayed is already showed*/
    try {
      if (localStorage.getItem('notifications')) {
        notificationsDisplayed = JSON.parse(localStorage.getItem('notifications') ?? '');

        if (notificationsDisplayed && notificationsDisplayed.length > 0) {
          action.payload.forEach((x: SocketMessagesDto) => {
            const msg = notificationsDisplayed.find(msg => msg.user_perm_component_id == x.user_perm_component_id);
            if (msg) {
              if (
                msg.message.trim() != x.message.trim() ||
                msg.socket_message_type_id != x.socket_message_type_id ||
                msg.wait_time != x.wait_time ||
                msg.chat_message != x.chat_message ||
                msg.status != x.status
              ) {
                notificationsDisplayed = notificationsDisplayed.filter(x => x.user_perm_component_id != msg.user_perm_component_id);
              }
            }
          });
          localStorage.setItem('notifications', JSON.stringify(notificationsDisplayed));
        }
      }
    } catch (error) {
      console.log('SetSocketMessagesPlayerAction', { error });
    }

    return {
      ...slice,
      socketMessages: action.payload ?? [],
    };
  },

  [GET_SO_MESSAGES_PLAYER_ERROR]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),
};

SocketReducer.addActionReducerMap(reduceHandlers);
