export interface IRoute {
  name: RoutesEnum;
  path: string;
}

export enum RoutesEnum {
  ForgetPassword = 'Forget Password',
  LoginPage = 'Login',
  Dashboard = 'Dashboard',
  Sportsbook = 'Sportsbook',
  LiveSports = 'Live',
  ESports = 'eSports',
  Casino = 'Casino',
  // Remove CasinoLobbyV2
  CasinoLobbyV2 = 'CasinoV2',
  Horses = 'Horses',
  Promotions = 'Promotions',
  RAF = 'Refer-A-Friend',
  Rules = 'Rules',
  Guides = 'Guides',
  Cashier = 'Cashier',
  LiveChat = 'Support',
  Account = 'My Account',
  Props_Builder = 'Props Builder',
  Racebook = 'Racebook',
  Live_Dealer = 'Live Dealer',
  Bracket = 'March Madness Bracket',
  Picks = 'Bowl Picks',
  Chat = 'Help',
  Hidden = '',
}

export const RoutesDictionary: { [key in RoutesEnum]: IRoute } = {
  [RoutesEnum.Account]: {
    name: RoutesEnum.Account,
    path: '/profile',
  },
  [RoutesEnum.ForgetPassword]: {
    name: RoutesEnum.ForgetPassword,
    path: '/forget-password',
  },
  [RoutesEnum.LoginPage]: {
    name: RoutesEnum.LoginPage,
    path: '/login',
  },
  [RoutesEnum.Dashboard]: { name: RoutesEnum.Dashboard, path: '/' },
  [RoutesEnum.Sportsbook]: {
    name: RoutesEnum.Sportsbook,
    path: '/sportsbook',
  },
  [RoutesEnum.LiveSports]: {
    name: RoutesEnum.LiveSports,
    path: '/live-sports',
  },
  [RoutesEnum.ESports]: {
    name: RoutesEnum.ESports,
    path: '/esports',
  },
  [RoutesEnum.Casino]: {
    name: RoutesEnum.Casino,
    path: '/casino',
  },
  // Remove CasinoLobbyV2
  [RoutesEnum.CasinoLobbyV2]: {
    name: RoutesEnum.CasinoLobbyV2,
    path: '/casinolobbyv2',
  },
  [RoutesEnum.Horses]: {
    name: RoutesEnum.Horses,
    path: '/horses',
  },
  [RoutesEnum.Promotions]: {
    name: RoutesEnum.Promotions,
    path: '/promotions',
  },
  [RoutesEnum.RAF]: {
    name: RoutesEnum.RAF,
    path: '/refer-a-friend',
  },
  [RoutesEnum.Rules]: {
    name: RoutesEnum.Rules,
    path: '/rules',
  },
  [RoutesEnum.Guides]: {
    name: RoutesEnum.Guides,
    path: '/guides',
  },
  [RoutesEnum.Cashier]: {
    name: RoutesEnum.Cashier,
    path: '/cashier',
  },
  [RoutesEnum.LiveChat]: {
    name: RoutesEnum.LiveChat,
    path: '/live-chat',
  },
  [RoutesEnum.Props_Builder]: {
    name: RoutesEnum.Props_Builder,
    path: '/props-builder',
  },
  [RoutesEnum.Racebook]: {
    name: RoutesEnum.Racebook,
    path: '/racebook',
  },
  [RoutesEnum.Live_Dealer]: {
    name: RoutesEnum.Live_Dealer,
    path: '/live-dealer',
  },
  [RoutesEnum.Bracket]: {
    name: RoutesEnum.Bracket,
    path: '/bracket',
  },
  [RoutesEnum.Picks]: {
    name: RoutesEnum.Picks,
    path: '/picks',
  },
  [RoutesEnum.Chat]: {
    name: RoutesEnum.Chat,
    path: '',
  },
  [RoutesEnum.Hidden]: {
    name: RoutesEnum.Hidden,
    path: '',
  },
};
