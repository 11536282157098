import { SocketMessagesByDateDto, SocketMessagesDto } from '../../models/Socket/socket-messages';
import { getCurrentPage } from '../app-root/app-root.component';
import { GetLocalFingerPrint, GetLocalUser } from '../functions/common';

export class MessageService {
  private static instance: MessageService;
  public socketMessages: SocketMessagesDto[] = [];
  public socketMessagesByDate: SocketMessagesByDateDto[] = [];
  public timeOut: NodeJS.Timeout | null = null;
  public currentPage: string | null = null;
  public static getInstance(): MessageService {
    if (!MessageService.instance) {
      MessageService.instance = new MessageService();
    }
    return MessageService.instance;
  }

  SetMessageToast(currentPage: string) {
    if (currentPage.includes('signup')) currentPage = '/signup';
    let notificationsDisplayed: SocketMessagesDto[] = [];
    this.currentPage = currentPage;

    let username: string | null = GetLocalUser();
    if (!username) username = GetLocalFingerPrint();

    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }

    try {
      if (localStorage.getItem('notifications')) notificationsDisplayed = JSON.parse(localStorage.getItem('notifications') ?? '');
    } catch (error) {
      console.log('Error Getting Notifications', { error });
    }

    const timeOutCurrent = this.socketMessages.find(x => x.component.route == currentPage && x.status == 1);

    const display = notificationsDisplayed.find(x => x.component.route == currentPage && x.username && x.username == username)
      ? false
      : true;

    if (timeOutCurrent && display) {
      this.timeOut = setTimeout(() => {
        this.socketMessages.forEach(x => {
          if (x.component.route == currentPage) {
            x.isNotified = true;
          }
        });

        window.parent.postMessage(
          {
            data: {
              data: {
                id: '',
                action: timeOutCurrent.socket_message_type_id,
                message: timeOutCurrent.message,
                room: '',
                chat_message: timeOutCurrent.chat_message,
                showOpenDialogButton: true,
              },
            },
            action: 'message-to-client',
          },
          '*',
        );
        this.SetMessageAsRead({ ...timeOutCurrent, isNotified: true });
      }, timeOutCurrent.wait_time * 1000);
    }
  }

  SetMessage(socketMessages: SocketMessagesDto[]) {
    this.socketMessages = socketMessages;
    if (this.currentPage == null) {
      this.currentPage = getCurrentPage();
    }
    this.SetMessageToast(this.currentPage);
  }

  SetMessageByDate(socketMessagesByDate: SocketMessagesByDateDto[]) {
    this.socketMessagesByDate = socketMessagesByDate;
    if (this.currentPage == null) {
      this.currentPage = getCurrentPage();
    }
  }

  CheckTimeToDisplayMessage(currentTime: Date) {
    const pstDate = new Date(currentTime);
    this.socketMessagesByDate.forEach((soMessage: SocketMessagesByDateDto) => {
      const tempDate = new Date(soMessage.date);
      const diffMs = tempDate.getTime() - pstDate.getTime(); // milliseconds between now & Christmas
      const diffSeconds = ((diffMs % 86400000) % 3600000) / 1000; // seconds

      if (diffSeconds >= -60 && diffSeconds <= 0) {
        let flagMessage = false;

        if (soMessage.user_perm_component_id == null && !soMessage.isNotified) flagMessage = true;
        else {
          if (soMessage.component) {
            const timeOutCurrent = this.socketMessagesByDate.find(
              y =>
                y.component?.route == soMessage.component?.route && this.currentPage == soMessage.component?.route && soMessage.status == 1,
            );

            if (timeOutCurrent && !timeOutCurrent.isNotified) flagMessage = true;
          }
        }
        if (flagMessage) {
          window.parent.postMessage(
            {
              data: {
                data: {
                  id: '',
                  action: soMessage.socket_message_type_id,
                  message: soMessage.message,
                  room: '',
                  chat_message: '',
                  showOpenDialogButton: true,
                },
              },
              action: 'message-to-client',
            },
            '*',
          );
          this.setMessageByDateAsRead({ ...soMessage, isNotified: true });
        }
      }
    });
    this.CheckTimeToReShowMessage(pstDate);
  }

  CheckTimeToReShowMessage(pstDate: Date) {
    let resp_pending: SocketMessagesByDateDto[] = [];
    try {
      if (localStorage.getItem('notifications_by_date_pending')) {
        resp_pending = <SocketMessagesByDateDto[]>JSON.parse(localStorage.getItem('notifications_by_date_pending') ?? '');
        if (resp_pending && resp_pending.length && resp_pending.length > 0) {
          resp_pending.forEach(soMessage => {
            if (soMessage.next_date && soMessage.repeat_times_do_it && soMessage.status == 1) {
              if (soMessage.repeat_times > soMessage.repeat_times_do_it) {
                const tempDate = new Date(soMessage.next_date);
                const diffMs = tempDate.getTime() - pstDate.getTime(); // milliseconds between now & Christmas
                const diffSeconds = ((diffMs % 86400000) % 3600000) / 1000; // seconds

                if (diffSeconds >= -60 && diffSeconds <= 0) {
                  soMessage.repeat_times_do_it += 1;
                  soMessage.next_date = soMessage.next_date
                    ? new Date(new Date(soMessage.next_date).getTime() + soMessage.repeat_frecuency * 60000).toString()
                    : new Date(new Date(soMessage.date).getTime() + soMessage.repeat_frecuency * 60000).toString();

                  const flagMessage =
                    soMessage.user_perm_component_id == null || (soMessage.component && soMessage.component.route == this.currentPage)
                      ? true
                      : false;

                  if (flagMessage)
                    window.parent.postMessage(
                      {
                        data: {
                          data: {
                            id: '',
                            action: soMessage.socket_message_type_id,
                            message: soMessage.message,
                            room: '',
                            chat_message: '',
                            showOpenDialogButton: true,
                          },
                        },
                        action: 'message-to-client',
                      },
                      '*',
                    );
                }
                resp_pending = resp_pending.filter(x => x.id != soMessage.id);
                resp_pending.push(soMessage);
              } else {
                resp_pending = resp_pending.filter(x => x.id != soMessage.id);
              }
            }
          });
        }
        localStorage.setItem('notifications_by_date_pending', JSON.stringify(resp_pending));
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  SetMessageAsRead(socketMessages: SocketMessagesDto) {
    window.parent.postMessage(
      {
        data: socketMessages,
        action: 'mark-as-read-message',
      },
      '*',
    );
  }

  setMessageByDateAsRead(socketMessages: SocketMessagesByDateDto) {
    window.parent.postMessage(
      {
        data: socketMessages,
        action: 'mark-as-read-message-by-date',
      },
      '*',
    );
  }
}

export const MessageServiceClient = MessageService.getInstance();
