import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { toastr } from 'react-redux-toastr';
import { API_PATH } from '../../../constants';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const ADD_UPDATE_FAVORITE_GAME_PLAYER = `ADD_UPDATE_FAVORITE_GAME_PLAYER${suffix}`;

export const addUpdateFavoriteGamePlayerAction = createAction<any>(ADD_UPDATE_FAVORITE_GAME_PLAYER);

const ADD_UPDATE_FAVORITE_GAME_PLAYER_SUCCESS = `ADD_UPDATE_FAVORITE_GAME_PLAYER_SUCCESS${suffix}`;
const addUpdateFavoriteGamePlayerSuccessAction = createAction<any[]>(ADD_UPDATE_FAVORITE_GAME_PLAYER_SUCCESS);

const ADD_UPDATE_FAVORITE_GAME_PLAYER_ERROR = `ADD_UPDATE_FAVORITE_GAME_PLAYER_ERROR${suffix}`;
const addUpdateFavoriteGamePlayerErrorAction = createAction<string>(ADD_UPDATE_FAVORITE_GAME_PLAYER_ERROR);

function* addUpdateFavoriteGamePlayerWatcher(): SagaIterator {
  yield takeEvery(ADD_UPDATE_FAVORITE_GAME_PLAYER, addUpdateFavoriteGamePlayerWorker);
}
mergeSaga(addUpdateFavoriteGamePlayerWatcher);

function* addUpdateFavoriteGamePlayerWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(addUpdateFavoriteGamePlayerApi, action.payload);

    if (action.payload.is_favorite) {
      toastr.success('Success!', 'Game added to your favorites games.');
    } else {
      toastr.success('Success!', 'Game removed from your favorites games.');
    }

    yield put(addUpdateFavoriteGamePlayerSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(addUpdateFavoriteGamePlayerErrorAction(e));
  }
}

const addUpdateFavoriteGamePlayerApi = (data: any): Request => {
  return vipApi(API_PATH.TPI.ADD_UPDATE_FAVORITE_GAME, 'post', {
    game_id: data.game_id,
    is_favorite: data.is_favorite,
  });
};

const reduceHandlers = {
  [ADD_UPDATE_FAVORITE_GAME_PLAYER]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [ADD_UPDATE_FAVORITE_GAME_PLAYER_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    gameFavoriteUpdated: action.payload?.data,
  }),
  [ADD_UPDATE_FAVORITE_GAME_PLAYER_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
