import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { toastr } from 'react-redux-toastr';
import { getGameRatingAction } from './get-game-rating.action';
import { API_PATH } from '../../../constants';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const ADD_UPDATE_GAME_RATING_PLAYER = `ADD_UPDATE_GAME_RATING_PLAYER${suffix}`;

export const addUpdateGameRatingPlayerAction = createAction<any>(ADD_UPDATE_GAME_RATING_PLAYER);

const ADD_UPDATE_GAME_RATING_PLAYER_SUCCESS = `ADD_UPDATE_GAME_RATING_PLAYER_SUCCESS${suffix}`;
const addUpdateGameRatingPlayerSuccessAction = createAction<any[]>(ADD_UPDATE_GAME_RATING_PLAYER_SUCCESS);

const ADD_UPDATE_GAME_RATING_PLAYER_ERROR = `ADD_UPDATE_GAME_RATING_PLAYER_ERROR${suffix}`;
const addUpdateGameRatingPlayerErrorAction = createAction<string>(ADD_UPDATE_GAME_RATING_PLAYER_ERROR);

function* addUpdateGameRatingPlayerWatcher(): SagaIterator {
  yield takeEvery(ADD_UPDATE_GAME_RATING_PLAYER, addUpdateGameRatingPlayerWorker);
}
mergeSaga(addUpdateGameRatingPlayerWatcher);

function* addUpdateGameRatingPlayerWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(addUpdateGameRatingPlayerApi, action.payload);
    toastr.success('Success!', 'We appreciate your review.');
    yield put(getGameRatingAction({}));
    yield put(addUpdateGameRatingPlayerSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(addUpdateGameRatingPlayerErrorAction(e));
  }
}

const addUpdateGameRatingPlayerApi = (data: any): Request => {
  return vipApi(API_PATH.TPI.ADD_UPDATE_GAME_RATING, 'post', {
    game_id: data.game_id,
    rating: data.rating,
    comment: data.comment,
    id: data.id,
  });
};

const reduceHandlers = {
  [ADD_UPDATE_GAME_RATING_PLAYER]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [ADD_UPDATE_GAME_RATING_PLAYER_SUCCESS]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [ADD_UPDATE_GAME_RATING_PLAYER_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
