import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { API_PATH } from '../../../constants';
import { IGet } from '../../../models/get-all';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { MessageSliceType, MessageSelectionReducer } from '../message.reducer';
import { IMessageByUser, IMessageMarkAsRead, MessageStatusEnum } from '../../../models/Messages/messages';

const suffix = `/${MessageSelectionReducer.sliceName}/app`;

const UPDATE_MESSAGES_BY_USER_MARK_AS_READ = `UPDATE_MESSAGES_BY_USER_MARK_AS_READ${suffix}`;

export const UpdateMessagesByUserMarkAsReadAction = createAction<IMessageMarkAsRead>(UPDATE_MESSAGES_BY_USER_MARK_AS_READ);

const UPDATE_MESSAGES_BY_USER_MARK_AS_READ_SUCCESS = `UPDATE_MESSAGES_BY_USER_MARK_AS_READ_SUCCESS${suffix}`;
const UpdateMessagesByUserMarkAsReadSuccessAction = createAction<IMessageByUser>(UPDATE_MESSAGES_BY_USER_MARK_AS_READ_SUCCESS);

const UPDATE_MESSAGES_BY_USER_MARK_AS_READ_ERROR = `UPDATE_MESSAGES_BY_USER_MARK_AS_READ_ERROR${suffix}`;
const UpdateMessagesByUserMarkAsReadErrorAction = createAction<string>(UPDATE_MESSAGES_BY_USER_MARK_AS_READ_ERROR);

function* UpdateMessagesByUserMarkAsReadWatcher(): SagaIterator {
  yield takeEvery(UPDATE_MESSAGES_BY_USER_MARK_AS_READ, UpdateMessagesByUserMarkAsReadWorker);
}
mergeSaga(UpdateMessagesByUserMarkAsReadWatcher);

function* UpdateMessagesByUserMarkAsReadWorker(request: Action<IMessageMarkAsRead>): SagaIterator {
  try {
    const result: IGet<IMessageByUser> = yield call(UpdateMessagesByUserMarkAsReadApi, request.payload);
    yield put(UpdateMessagesByUserMarkAsReadSuccessAction(result.data));
  } catch (e: any) {
    yield put(UpdateMessagesByUserMarkAsReadErrorAction(e));
  }
}

const UpdateMessagesByUserMarkAsReadApi = (request: IMessageMarkAsRead): Request => {
  return vipApi(API_PATH.MESSAGES.MARK_AS_READ(request.id), 'post', {});
};

const reduceHandlers = {
  [UPDATE_MESSAGES_BY_USER_MARK_AS_READ]: (slice: MessageSliceType): MessageSliceType => ({
    ...slice,
  }),
  [UPDATE_MESSAGES_BY_USER_MARK_AS_READ_SUCCESS]: (slice: MessageSliceType, action: Action<IMessageByUser>): MessageSliceType => {
    const message = action.payload;
    let localMessages = slice.messages.map(x => x);

    if ([MessageStatusEnum.READ, MessageStatusEnum.SENT, MessageStatusEnum.EDIT].indexOf(message.message_user_status_id) === -1) {
      localMessages = localMessages.filter(x => x.id !== message.id);
    } else {
      localMessages = localMessages.map(x => (x.id === message.id ? message : x));
    }

    return {
      ...slice,
      messages: localMessages,
    };
  },
  [UPDATE_MESSAGES_BY_USER_MARK_AS_READ_ERROR]: (slice: MessageSliceType): MessageSliceType => ({
    ...slice,
  }),
};

MessageSelectionReducer.addActionReducerMap(reduceHandlers);
