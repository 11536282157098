export enum SocketRooms {
  VIPPUBLICAGENT = 'VIPPUBLICAGENT',
}

export enum SocketMessageEnum {
  MESSAGE = 1,
  SHOWDIALOG = 2,
}

export enum SocketUserTypeEnum {
  PLAYER = 1,
  ADMIN = 2,
  AGENT = 3,
}
