import { Rollover } from '../../models/Rollover/rollover';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'rollOverSlice';

export interface RolloverSliceType {
  isLoading: boolean;
  rollover: Rollover | null;
}

export const initialState: RolloverSliceType = {
  isLoading: false,
  rollover: null,
};

export const rolloverReducer = createAndMergeSliceReducer(sliceName, initialState);
