/**
 * @export
 * Parameter defined by DGS
 * In Compile wager
 * TNT use tmnum
 * PROP sue vnum
 */
export enum PLAY_LINE {
  VISITORSPREAD = 0,
  HOMESPREAD = 1,
  VISITORTOTAL = 2,
  HOMETOTAL = 3,
  VISITORMONEYLINE = 4,
  HOMEMONEYLINE = 5,
  DRAW = 6,
  TNT = -1,
}
