import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
// import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
import { rolloverReducer, RolloverSliceType } from '../rollover.reducer';
import { Rollover } from '../../../models/Rollover/rollover';

const suffix = `/${rolloverReducer.sliceName}/app`;

const GET_PLAYER_ROLLOVER = `GET_PLAYER_ROLLOVER${suffix}`;

export const getPlayerRolloverAction = createAction<any>(GET_PLAYER_ROLLOVER);

const GET_PLAYER_ROLLOVER_SUCCESS = `GET_PLAYER_ROLLOVER_SUCCESS${suffix}`;
const getPlayerRolloverSuccessAction = createAction<any[]>(GET_PLAYER_ROLLOVER_SUCCESS);

const GET_PLAYER_ROLLOVER_ERROR = `GET_PLAYER_ROLLOVER_ERROR${suffix}`;
const getPlayerRolloverErrorAction = createAction<string>(GET_PLAYER_ROLLOVER_ERROR);

function* getPlayerRolloverWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_ROLLOVER, getPlayerRolloverWorker);
}
mergeSaga(getPlayerRolloverWatcher);

function* getPlayerRolloverWorker(): SagaIterator {
  try {
    const result: any = yield call(getPlayerRolloverApi);
    try {
      if (result && result.data) {
        if (Array.isArray(result.data) && result.data.length == 0) {
          result.data = null;
        } else {
          if (result.data.bonus) {
            if (!result.data.contribution_table) result.data.contribution_table = [];
            // result.data.contribution_table = result.data.contribution_table.concat(functionLoadTableGamesContributionTable());
            result.data.contribution_table = result.data.contribution_table.sort((a: any, b: any) => a.id - b.id);
          }
        }
      }
    } catch (error) {
      console.log('Error bonus', { error });
    }

    /*ENDING OF THE TEMPORARY SECTION*/
    yield put(getPlayerRolloverSuccessAction(result.data));
  } catch (e: any) {
    // yield call(showToast, e.response.data.statusMessage, 'error');
    yield put(getPlayerRolloverErrorAction(e));
  }
}

const getPlayerRolloverApi = (): Request => {
  return vipApi(API_PATH.CASHIER.GET_ROLLOVERS, 'get', {});
};

const reduceHandlers = {
  [GET_PLAYER_ROLLOVER]: (slice: RolloverSliceType): RolloverSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_ROLLOVER_SUCCESS]: (slice: RolloverSliceType, action: Action<Rollover | null>): RolloverSliceType => {
    return {
      ...slice,
      isLoading: false,
      rollover: action.payload,
    };
  },
  [GET_PLAYER_ROLLOVER_ERROR]: (slice: RolloverSliceType): RolloverSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

rolloverReducer.addActionReducerMap(reduceHandlers);

/*
    BEGINNING OF THE TEMPORARY SECTION
    THIS IS TEMPORARY IT WILL BE REMOVED SOON
    TICKET# #2727
*/
export const functionLoadTableGamesContributionTable = () => {
  return [
    {
      category: {
        created_at: '2022-01-18T00:03:18.000000Z',
        id: 2,
        name: 'Casino',
        updated_at: '2022-01-18T00:03:18.000000Z',
      },
      contribution_category_id: 2,
      created_at: '2022-01-18T00:03:18.000000Z',
      id: 12.1,
      name: 'Roulette',
      updated_at: '2022-01-18T00:03:18.000000Z',
      value: {
        contribution_id: 12.1,
        contribution_profile_id: 19,
        created_at: '2022-01-18T00:03:18.000000Z',
        updated_at: '2022-01-18T00:03:18.000000Z',
        deleted: 0,
        id: 0,
        percentage: 5,
        status: 1,
      },
    },
    {
      category: {
        created_at: '2022-01-18T00:03:18.000000Z',
        id: 2,
        name: 'Casino',
        updated_at: '2022-01-18T00:03:18.000000Z',
      },
      contribution_category_id: 2,
      created_at: '2022-01-18T00:03:18.000000Z',
      id: 12.2,
      name: 'Baccarat',
      updated_at: '2022-01-18T00:03:18.000000Z',
      value: {
        contribution_id: 12.2,
        contribution_profile_id: 19,
        created_at: '2022-01-18T00:03:18.000000Z',
        updated_at: '2022-01-18T00:03:18.000000Z',
        deleted: 0,
        id: 0,
        percentage: 5,
        status: 1,
      },
    },
    {
      category: {
        created_at: '2022-01-18T00:03:18.000000Z',
        id: 2,
        name: 'Casino',
        updated_at: '2022-01-18T00:03:18.000000Z',
      },
      contribution_category_id: 2,
      created_at: '2022-01-18T00:03:18.000000Z',
      id: 12.3,
      name: 'Craps',
      updated_at: '2022-01-18T00:03:18.000000Z',
      value: {
        contribution_id: 12.3,
        contribution_profile_id: 19,
        created_at: '2022-01-18T00:03:18.000000Z',
        updated_at: '2022-01-18T00:03:18.000000Z',
        deleted: 0,
        id: 0,
        percentage: 5,
        status: 1,
      },
    },
  ];
  /*ENDING OF THE TEMPORARY SECTION*/
};
