import React from 'react';

export const VipCasinoMachineIcon = (props: any) => {
  const { style } = props;
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' style={style}>
      <rect x='450.207' y='176.552' style={{ fill: '#AFB9D2' }} width='17.655' height='176.552' />
      <circle style={{ fill: '#FF6464' }} cx='459.034' cy='172.138' r='17.655' />
      <path
        style={{ fill: '#D2555A' }}
        d='M451.972,172.138c0-7.893,5.214-14.496,12.359-16.757c-1.683-0.532-3.438-0.898-5.297-0.898
   c-9.751,0-17.655,7.904-17.655,17.655c0,9.75,7.904,17.655,17.655,17.655c1.858,0,3.614-0.366,5.297-0.898
   C457.186,186.634,451.972,180.03,451.972,172.138z'
      />
      <path
        style={{ fill: '#959CB3' }}
        d='M467.862,370.759h-70.621v-52.966h70.621c4.875,0,8.828,3.953,8.828,8.828v35.31
   C476.69,366.806,472.737,370.759,467.862,370.759z'
      />
      <polygon style={{ fill: '#707487' }} points='61.793,414.897 44.138,494.345 432.552,494.345 414.897,414.897 ' />
      <polygon style={{ fill: '#5B5D6E' }} points='61.793,414.897 55.907,441.379 420.781,441.379 414.897,414.897 ' />
      <path
        style={{ fill: '#959CB3' }}
        d='M423.724,423.724H52.966c-4.875,0-8.828-3.953-8.828-8.828v-88.276h388.414v88.276
   C432.552,419.772,428.599,423.724,423.724,423.724z'
      />
      <g>
        <path
          style={{ fill: '#AFB9D2' }}
          d='M44.138,379.586h388.414c4.875,0,8.828-3.953,8.828-8.828v-52.966c0-4.875-3.953-8.828-8.828-8.828
     H44.138c-4.875,0-8.828,3.953-8.828,8.828v52.966C35.31,375.634,39.263,379.586,44.138,379.586z'
        />
        <path
          style={{ fill: '#AFB9D2' }}
          d='M414.897,141.241H61.793V52.966c0-9.751,7.904-17.655,17.655-17.655h317.793
     c9.751,0,17.655,7.904,17.655,17.655V141.241z'
        />
      </g>
      <path
        style={{ fill: '#C7CFE2' }}
        d='M423.724,308.966H52.966V132.414c0-4.875,3.953-8.828,8.828-8.828h353.103
   c4.875,0,8.828,3.953,8.828,8.828V308.966z'
      />
      <path
        style={{ fill: '#AFB9D2' }}
        d='M397.241,264.828H79.448c-4.875,0-8.828-3.953-8.828-8.828V150.069c0-4.875,3.953-8.828,8.828-8.828
   h317.793c4.875,0,8.828,3.953,8.828,8.828V256C406.069,260.875,402.116,264.828,397.241,264.828z'
      />
      <path
        style={{ fill: '#EFF2FA' }}
        d='M169.091,247.172H95.736c-4.12,0-7.46-3.34-7.46-7.46v-73.355c0-4.12,3.34-7.46,7.46-7.46h73.355
   c4.12,0,7.46,3.34,7.46,7.46v73.355C176.552,243.832,173.212,247.172,169.091,247.172z'
      />
      <g>
        <path
          style={{ fill: '#FF6464' }}
          d='M116.988,193.553h18.964c-16,7.507-14.222,37.927-14.222,37.927h23.704
     c0-31.704,18.964-47.409,18.964-47.409l-4.74-9.482h-52.173c-2.606,0-4.718,2.112-4.718,4.718v23.727h9.46
     c2.631,0,4.764-2.133,4.764-4.764v-4.718H116.988z'
        />
        <path style={{ fill: '#FF6464' }} d='M88.276,194.207v17.655c4.875,0,8.828-3.953,8.828-8.828S93.151,194.207,88.276,194.207z' />
        <path style={{ fill: '#FF6464' }} d='M176.552,211.862v-17.655c-4.875,0-8.828,3.953-8.828,8.828S171.677,211.862,176.552,211.862z' />
      </g>
      <path
        style={{ fill: '#EFF2FA' }}
        d='M275.022,247.172h-73.355c-4.12,0-7.46-3.34-7.46-7.46v-73.355c0-4.12,3.34-7.46,7.46-7.46h73.355
   c4.12,0,7.46,3.34,7.46,7.46v73.355C282.483,243.832,279.143,247.172,275.022,247.172z'
      />
      <g>
        <path
          style={{ fill: '#FF6464' }}
          d='M222.919,193.553h18.964c-16,7.507-14.222,37.927-14.222,37.927h23.704
     c0-31.704,18.964-47.409,18.964-47.409l-4.74-9.482h-52.172c-2.606,0-4.718,2.112-4.718,4.718v23.727h9.46
     c2.631,0,4.764-2.133,4.764-4.764v-4.718H222.919z'
        />
        <path style={{ fill: '#FF6464' }} d='M194.207,194.207v17.655c4.875,0,8.828-3.953,8.828-8.828S199.082,194.207,194.207,194.207z' />
        <path style={{ fill: '#FF6464' }} d='M282.483,211.862v-17.655c-4.875,0-8.828,3.953-8.828,8.828S277.607,211.862,282.483,211.862z' />
      </g>
      <path
        style={{ fill: '#EFF2FA' }}
        d='M380.953,247.172h-73.355c-4.12,0-7.46-3.34-7.46-7.46v-73.355c0-4.12,3.34-7.46,7.46-7.46h73.355
   c4.12,0,7.46,3.34,7.46,7.46v73.355C388.414,243.832,385.074,247.172,380.953,247.172z'
      />
      <g>
        <path
          style={{ fill: '#FF6464' }}
          d='M328.85,193.553h18.964c-16,7.507-14.222,37.927-14.222,37.927h23.704
     c0-31.704,18.964-47.409,18.964-47.409l-4.74-9.482h-52.172c-2.606,0-4.718,2.112-4.718,4.718v23.727h9.46
     c2.631,0,4.764-2.133,4.764-4.764v-4.718H328.85z'
        />
        <path style={{ fill: '#FF6464' }} d='M300.138,194.207v17.655c4.875,0,8.828-3.953,8.828-8.828S305.013,194.207,300.138,194.207z' />
        <path style={{ fill: '#FF6464' }} d='M388.414,211.862v-17.655c-4.875,0-8.828,3.953-8.828,8.828S383.538,211.862,388.414,211.862z' />
      </g>
      <path
        style={{ fill: '#FFE669' }}
        d='M388.414,52.966H88.276c-4.875,0-8.828,3.953-8.828,8.828v35.31c0,4.875,3.953,8.828,8.828,8.828
   h300.138c4.875,0,8.828-3.953,8.828-8.828v-35.31C397.241,56.918,393.289,52.966,388.414,52.966z'
      />
      <path
        style={{ fill: '#959CB3' }}
        d='M378.275,282.483c-3.672,0-7.256,2.097-8.417,5.58c-2.335,7.016-8.954,12.075-16.755,12.075
   c-7.8,0-14.42-5.059-16.755-12.075c-1.16-3.484-4.745-5.58-8.417-5.58h-18.966c-4.875,0-8.828,3.953-8.828,8.828v17.655h88.276
   V291.31c0-4.875-3.953-8.828-8.828-8.828H378.275z'
      />
      <g>
        <path
          style={{ fill: '#FFE669' }}
          d='M123.586,308.966h-35.31v-8.828c0-4.875,3.953-8.828,8.828-8.828h17.655
     c4.875,0,8.828,3.953,8.828,8.828V308.966z'
        />
        <path
          style={{ fill: '#FFE669' }}
          d='M176.552,308.966h-35.31v-8.828c0-4.875,3.953-8.828,8.828-8.828h17.655
     c4.875,0,8.828,3.953,8.828,8.828V308.966z'
        />
      </g>
      <g>
        <path
          style={{ fill: '#FF6464' }}
          d='M229.517,308.966h-35.31v-8.828c0-4.875,3.953-8.828,8.828-8.828h17.655
     c4.875,0,8.828,3.953,8.828,8.828V308.966z'
        />
        <path
          style={{ fill: '#FF6464' }}
          d='M282.483,308.966h-35.31v-8.828c0-4.875,3.953-8.828,8.828-8.828h17.655
     c4.875,0,8.828,3.953,8.828,8.828V308.966z'
        />
        <circle style={{ fill: '#FF6464' }} cx='238.345' cy='17.655' r='17.655' />
      </g>
      <path
        style={{ fill: '#D2555A' }}
        d='M233.931,17.655c0-7.405,4.569-13.725,11.034-16.345C242.918,0.479,240.69,0,238.345,0
   c-9.751,0-17.655,7.904-17.655,17.655c0,9.75,7.904,17.655,17.655,17.655c2.345,0,4.574-0.479,6.621-1.31
   C238.5,31.38,233.931,25.06,233.931,17.655z'
      />
      <path
        style={{ fill: '#C7CFE2' }}
        d='M264.828,35.31h-52.966v-8.828c0-4.875,3.953-8.828,8.828-8.828H256c4.875,0,8.828,3.953,8.828,8.828
   V35.31z'
      />
      <circle style={{ fill: '#FF6464' }} cx='317.793' cy='17.655' r='17.655' />
      <path
        style={{ fill: '#D2555A' }}
        d='M313.379,17.655c0-7.405,4.569-13.725,11.034-16.345C322.366,0.479,320.138,0,317.793,0
   c-9.751,0-17.655,7.904-17.655,17.655c0,9.75,7.904,17.655,17.655,17.655c2.345,0,4.574-0.479,6.621-1.31
   C317.949,31.38,313.379,25.06,313.379,17.655z'
      />
      <path
        style={{ fill: '#C7CFE2' }}
        d='M344.276,35.31H291.31v-8.828c0-4.875,3.953-8.828,8.828-8.828h35.31
   c4.875,0,8.828,3.953,8.828,8.828V35.31z'
      />
      <circle style={{ fill: '#FF6464' }} cx='158.897' cy='17.655' r='17.655' />
      <path
        style={{ fill: '#D2555A' }}
        d='M154.483,17.655c0-7.405,4.569-13.725,11.034-16.345C163.47,0.479,161.241,0,158.897,0
   c-9.751,0-17.655,7.904-17.655,17.655c0,9.75,7.904,17.655,17.655,17.655c2.345,0,4.574-0.479,6.621-1.31
   C159.052,31.38,154.483,25.06,154.483,17.655z'
      />
      <path
        style={{ fill: '#C7CFE2' }}
        d='M185.379,35.31h-52.966v-8.828c0-4.875,3.953-8.828,8.828-8.828h35.31
   c4.875,0,8.828,3.953,8.828,8.828V35.31z'
      />
      <path
        style={{ fill: '#FF6464' }}
        d='M114.759,88.276L114.759,88.276c-4.875,0-8.828-3.953-8.828-8.828l0,0
   c0-4.875,3.953-8.828,8.828-8.828l0,0c4.875,0,8.828,3.953,8.828,8.828l0,0C123.586,84.323,119.634,88.276,114.759,88.276z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M167.724,88.276h-26.483c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
   h26.483c4.875,0,8.828,3.953,8.828,8.828l0,0C176.552,84.323,172.599,88.276,167.724,88.276z'
      />
      <path
        style={{ fill: '#FF6464' }}
        d='M308.966,88.276L308.966,88.276c-4.875,0-8.828-3.953-8.828-8.828l0,0
   c0-4.875,3.953-8.828,8.828-8.828l0,0c4.875,0,8.828,3.953,8.828,8.828l0,0C317.793,84.323,313.841,88.276,308.966,88.276z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M361.931,88.276h-26.483c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
   h26.483c4.875,0,8.828,3.953,8.828,8.828l0,0C370.759,84.323,366.806,88.276,361.931,88.276z'
      />
      <path
        style={{ fill: '#FF6464' }}
        d='M203.034,88.276L203.034,88.276c-4.875,0-8.828-3.953-8.828-8.828l0,0
   c0-4.875,3.953-8.828,8.828-8.828l0,0c4.875,0,8.828,3.953,8.828,8.828l0,0C211.862,84.323,207.91,88.276,203.034,88.276z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M273.655,88.276h-44.138c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
   h44.138c4.875,0,8.828,3.953,8.828,8.828l0,0C282.483,84.323,278.53,88.276,273.655,88.276z'
      />
      <path
        style={{ fill: '#5B5D6E' }}
        d='M308.966,361.931h70.621c4.875,0,8.828-3.953,8.828-8.828v-17.655c0-4.875-3.953-8.828-8.828-8.828
   h-70.621c-4.875,0-8.828,3.953-8.828,8.828v17.655C300.138,357.978,304.09,361.931,308.966,361.931z'
      />
      <polygon
        style={{ fill: '#FFE669' }}
        points='281.931,344.276 249.918,325.789 249.918,335.448 220.69,335.448 220.69,353.103 
   249.918,353.103 249.918,362.762 '
      />
      <g>
        <circle style={{ fill: '#EFF2FA' }} cx='194.207' cy='344.276' r='8.828' />
        <circle style={{ fill: '#EFF2FA' }} cx='123.586' cy='344.276' r='8.828' />
        <circle style={{ fill: '#EFF2FA' }} cx='158.897' cy='344.276' r='8.828' />
        <circle style={{ fill: '#EFF2FA' }} cx='88.276' cy='344.276' r='8.828' />
      </g>
      <path
        style={{ fill: '#959CB3' }}
        d='M432.552,512H44.138c-4.875,0-8.828-3.953-8.828-8.828v-8.828c0-4.875,3.953-8.828,8.828-8.828
   h388.414c4.875,0,8.828,3.953,8.828,8.828v8.828C441.379,508.047,437.427,512,432.552,512z'
      />
      <path
        style={{ fill: '#AFB9D2' }}
        d='M361.931,512H114.759c-4.875,0-8.828-3.953-8.828-8.828v-17.655c0-4.875,3.953-8.828,8.828-8.828
   h247.172c4.875,0,8.828,3.953,8.828,8.828v17.655C370.759,508.047,366.806,512,361.931,512z'
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
