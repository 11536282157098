import React from 'react';

export const VipHeartPlusIcon = (props: any) => {
  const { style } = props;
  return (
    <svg style={style} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'>
      <g>
        <g>
          <g>
            <path
              d='M256,394.667C256,318.208,318.208,256,394.667,256c17.92,0,35.499,3.456,52.224,10.24
               c4.544,1.856,9.728,0.384,12.651-3.584l25.6-35.093C502.699,202.987,512,174.08,512,144C512,64.597,447.403,0,368,0
               c-43.904,0-84.757,19.797-112,53.632C228.757,19.797,187.904,0,144,0C64.597,0,0,64.597,0,144
               c0,30.08,9.301,58.987,26.923,83.669l194.389,266.496c0.235,0.299,0.448,0.555,0.683,0.832C230.059,505.664,242.688,512,256,512
               c12.096,0,23.744-5.397,31.957-14.784c3.435-3.968,3.499-9.835,0.149-13.845C267.392,458.517,256,427.029,256,394.667z'
            />
            <path
              d='M394.667,277.333c-64.683,0-117.333,52.651-117.333,117.333S329.984,512,394.667,512S512,459.349,512,394.667
               S459.349,277.333,394.667,277.333z M437.333,405.333h-32v32c0,5.888-4.779,10.667-10.667,10.667S384,443.221,384,437.333v-32h-32
               c-5.888,0-10.667-4.779-10.667-10.667S346.112,384,352,384h32v-32c0-5.888,4.779-10.667,10.667-10.667
               s10.667,4.779,10.667,10.667v32h32c5.888,0,10.667,4.779,10.667,10.667S443.221,405.333,437.333,405.333z'
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
