import { createAction } from 'redux-actions';
import { AxiosSliceType, axiosReducer } from './axios.reducer';

const suffix = `/${axiosReducer.sliceName}/app`;

const SET_AXIOS_BUSY = `SET_AXIOS_BUSY${suffix}`;
export const setAxiosBusyAction = createAction(SET_AXIOS_BUSY);

const reduceHandlers = {
  [SET_AXIOS_BUSY]: (slice: AxiosSliceType, action: any): AxiosSliceType => ({
    ...slice,
    isBusy: action.payload,
  }),
};
axiosReducer.addActionReducerMap(reduceHandlers);
