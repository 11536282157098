import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, createStyles, Button, Typography, TextField, Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS, isWinPhone } from 'react-device-detect';
import { CasinoGameDto, GameRatingDto } from '../../../models/CasinoLobbyV2/CasinoLobbyDto';
import { VipAutoSpinIcon } from '../../../shared/icons/auto-spin-icon';
import { VipCasinoMachineIcon } from '../../../shared/icons/casino-machine-icon';
import { VipCrownIcon } from '../../../shared/icons/crown-icon';
import { VipExchangeIcon } from '../../../shared/icons/exchange-icon';
import { VipFreeSpinIcon } from '../../../shared/icons/free-spin-icon';
import { VipHeartMinusIcon } from '../../../shared/icons/heart-minus-icon';
import { VipHeartPlusIcon } from '../../../shared/icons/heart-plus-icon';
import Fade from '@material-ui/core/Fade';
import { formatUSDNumber } from '../../../shared/functions/common';
// import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    container: {
      width: '75%',
      border: '2px #c8a964 solid',
      margin: 'auto',
      borderRadius: '5px',
      backgroundColor: '#2d2d2d',
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        height: '600PX',
      },
      [theme.breakpoints.up('md')]: {
        height: '700px',
      },
      [theme.breakpoints.up('sm')]: {
        height: '800px',
      },
    },
    containerWithoutBonus: {
      width: '75%',
      border: '2px #c8a964 solid',
      margin: 'auto',
      borderRadius: '5px',
      backgroundColor: '#2d2d2d',
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        height: '500PX',
      },
      [theme.breakpoints.up('sm')]: {
        height: '700px',
      },
    },
    containerMobile: {
      width: '75%',
      border: '2px #c8a964 solid',
      margin: 'auto',
      borderRadius: '5px',
      backgroundColor: '#2d2d2d',
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        // height: '500PX',
      },
    },
    containerMobileLandscape: {
      width: '75%',
      border: '2px #c8a964 solid',
      margin: 'auto',
      borderRadius: '5px',
      backgroundColor: '#2d2d2d',
      display: 'block',
      textAlign: 'center',
      height: '300px',
    },
    containerMobileLandscapeWithFreePlay: {
      width: '75%',
      border: '2px #c8a964 solid',
      margin: 'auto',
      borderRadius: '5px',
      backgroundColor: '#2d2d2d',
      display: 'block',
      textAlign: 'center',
      height: '348px',
    },
    containerMobileWithoutBonus: {
      width: '75%',
      border: '2px #c8a964 solid',
      margin: 'auto',
      borderRadius: '5px',
      backgroundColor: '#2d2d2d',
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        // height: '700PX',
      },
    },
    game: {
      width: '98%',
      height: '89%',
      borderRadius: '5px',
      zIndex: 1,
      position: 'relative',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: '70%',
      },
    },
    gameWithoutBonus: {
      width: '98%',
      height: '97%',
      borderRadius: '5px',
      zIndex: 1,
      position: 'relative',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        height: '75%',
      },
    },
    gameImage: {
      width: '100%',
      // height: '72%',
      borderRadius: '5px',
      display: 'inline',
      zIndex: 1,
      position: 'relative',
    },
    gameImageLandscape: {
      width: '100%',
      // height: '72%',
      display: 'inline',
      zIndex: 1,
      position: 'relative',
      borderRight: '2px #c8a964 solid',
    },
    gameImageWithoutBonus: {
      width: '100%',
      // height: '80%',
      borderRadius: '5px',
      display: 'inline',
      zIndex: 1,
      position: 'relative',
    },
    gameImageWithoutBonusLandscape: {
      width: '100%',
      // height: '80%',
      borderRadius: '5px',
      display: 'inline',
      zIndex: 1,
      position: 'relative',
      borderRight: '2px #c8a964 solid',
    },
    containerControls: {
      float: 'right',
      position: 'absolute',
      height: '120px',
      width: '40px',
      textAlign: 'center',
      borderBottomRightRadius: '5px',
      borderTopRightRadius: '5px',
      border: '2px #c8a964 solid',
      backgroundColor: 'black',
      overflow: 'hidden',
      marginTop: '50px',
      right: '2px',
      marginRight: 'calc(12% - 31px)',
      [theme.breakpoints.down('md')]: {
        marginRight: 'calc(12% - 35px)',
      },
    },
    icons: {
      display: 'block !important',
      marginTop: '10px',
      marginBottom: '15px',
      color: '#c8a964',
      cursor: 'pointer',
    },
    bottonBack: {
      margin: 'auto',
      width: '75%',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginTop: '0.5rem',
      },
    },
    backBtn: {
      marginBottom: '10px',
      border: '2px solid rgba(200, 169, 100, 0.36)',
      marginTop: '10px',
    },
    bottonMobileOpenGame: {
      // marginTop: '10px',
      zIndex: 2,
      width: '70%',
      display: 'inline-block',
      border: '2px solid #c8a964',
      backgroundColor: 'rgba(77, 77, 77, 0.61) !important',
      color: '#c8a964 !important',
      fontSize: '1rem',
    },
    bottonMobileOpenGamePortait: {
      marginBottom: '10px',
      zIndex: 2,
      width: '40%',
      display: 'inline-block',
      border: '2px solid #c8a964',
      backgroundColor: 'rgba(77, 77, 77, 0.61) !important',
      color: '#c8a964 !important',
      fontSize: '1rem',
    },
    bottonMobileFavorite: {
      color: '#c8a964',
      width: '60px',
      minWidth: '60px',
      display: 'inline-block',
      zIndex: 2,
      marginLeft: ' 5px',
    },
    ratingContainer: {
      minHeight: '25px',
      display: 'block',
      margin: 'auto',
      marginTop: '10px',
      textAlign: 'center',
    },
    tagsContainer: {
      display: 'block',
      margin: 'auto',
    },
    ratingDesktopText: {
      textAlign: 'left',
    },
    ratingSend: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px',
      '& label': {
        fontSize: '12px',
      },
    },
    ratingFloat: {
      float: 'left',
    },
    ratingFloatContainer: {
      marginBottom: '0',
      display: 'flex',
      alignItems: 'center',
    },
    removeColorA: {
      textDecoration: 'none',
      color: 'inherit !important',
    },
    tagLbl: {
      display: 'inline-block',
      color: theme.palette.primary.main,
      marginLeft: '5px',
    },
    spanIcon: {
      marginLeft: '4px',
      [theme.breakpoints.up('md')]: {
        float: 'left',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
    },
    featureContainer: {
      display: 'block',
      margin: 'auto',
      marginBottom: '15px',
    },
    amounts: {
      textShadow: ' 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #c8a964, 0 0 40px #c8a964, 0 0 50px #c8a964, 0 0 60px #c8a964, 0 0 70px #c8a964',
      fontSize: '30px',
      color: 'white',
    },
    amountsMobile: {
      textShadow:
        ' 0 0 10px #c8a964, 0 0 20px #c8a964, 0 0 30px #c8a964, 0 0 40px #c8a964, 0 0 50px #c8a964, 0 0 60px #c8a964, 0 0 70px #c8a964',

      fontSize: '17px',
      color: 'white',
      [theme.breakpoints.down(400)]: {
        fontSize: '14px',
      },
    },
    negativeAmounts: {
      textShadow: ' 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ee0000, 0 0 40px #ee0000, 0 0 50px #ee0000, 0 0 60px #ee0000, 0 0 70px #ee0000',
      color: 'white',
    },
    textBottom: {
      padding: '1px',
      display: 'table-cell',
      verticalAlign: 'middle',
      background: 'black',
      border: '2px solid #c8a964',
      borderRadius: '5px',
      color: '#c8a964',
    },
    textBottomUseFreePlay: {
      display: 'table-cell',
      verticalAlign: 'middle',
      background: 'black',
      padding: '1px',
      border: '2px solid #c8a964',
      borderRadius: '5px',
      fontSize: '13px !important',
      color: '#c8a964',
    },
    tableDisplay: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    gameBonusTitle: {
      backgroundColor: '#2d2d2d',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      marginBottom: '10px',
      marginTop: '10px',
      [theme.breakpoints.down('md')]: {
        margin: 'auto !important',
      },
    },
    containerBorder: {
      borderTop: '2px solid #c8a964',
    },
    completeDiv: {
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    },
    gameLbl: {
      [theme.breakpoints.down(400)]: {
        display: 'none',
      },
    },
    glowMobile: {
      [theme.breakpoints.down(400)]: {
        fontSize: '24px',
      },
    },
    lobbyLbl: {
      marginLeft: '2px',
      [theme.breakpoints.down(600)]: {
        display: 'none',
      },
    },
    gameDesc: {
      [theme.breakpoints.up(768)]: {
        fontSize: '20px',
      },
    },
  }),
);

export interface ExternalProps {
  user: any;
  game: CasinoGameDto;
  useFreePlay: boolean;
  isBonusGame: boolean;
  gameURL: string;
  isMobile: boolean;
  gameRatings: GameRatingDto[];
  bonusHistoryId: number;
  userBalance: any;
  imageCDNUrl: string;
  isPortrait: boolean;
  openCasinoGameActionV2: (data: any) => void;
  setGame: (data: any) => void;
  setGameUrl: (data: any) => void;
  addUpdateGameRatingPlayerAction: (data: any) => void;
  addUpdateFavoriteGamePlayerAction: (data: any) => void;
  getUserBalanceAction: () => void;
}

export const GameDisplayComponent = (props: ExternalProps) => {
  const classes = useStyles();
  const {
    openCasinoGameActionV2,
    addUpdateGameRatingPlayerAction,
    addUpdateFavoriteGamePlayerAction,
    setGame,
    setGameUrl,
    getUserBalanceAction,
    game,
    gameRatings,
    gameURL,
    isBonusGame,
    isMobile,
    imageCDNUrl,
    useFreePlay,
    bonusHistoryId,
    user,
    // userBalance,
    isPortrait,
  } = props;
  const [gameRating, setGameRating] = useState<GameRatingDto>({
    comment: '',
    id: -1,
    rating: 5,
    tpi_game_id: -1,
  });

  const [showIframe, setShowIframe] = useState<boolean>(false);
  const [videoStatus, setVideoStatus] = useState<number>(0);
  const [gameHeight, setGameHeight] = useState(0);

  window.addEventListener('resize', () => {
    handleGameHeight();
  });

  useEffect(() => {
    handleGameHeight();
    getUserBalanceAction();
  }, []);

  const handleGameHeight = () => {
    let _gameHeight = 310;
    const _gameImageWidth = document.getElementsByClassName('gameImageCont')[0]?.clientWidth;
    _gameHeight = (_gameImageWidth * 4) / 3;
    setGameHeight(_gameHeight - 100);
  };

  const openGame = async () => {
    let device_id = 1;
    if (isMobile) {
      if (isAndroid) {
        device_id = 3;
      }
      if (isIOS) {
        device_id = 2;
      }
      if (isWinPhone) {
        device_id = 4;
      }
    }
    openCasinoGameActionV2({
      device_id: device_id,
      provider_id: game.provider_id,
      game_id: game.game_id,
      is_freeplay: useFreePlay,
      casinoVersion: 2,
      bonus_history_id: isBonusGame ? bonusHistoryId : null,
    });
  };

  useEffect(() => {
    if (game && game.game_id) {
      openGame();
      setGameUrl('');
    }
  }, [game]);

  useEffect(() => {
    if (gameRatings && gameRatings.length && gameRatings.length > 0) {
      const gameSelected = gameRatings.find(x => x.tpi_game_id == game.game_id);
      if (gameSelected) {
        gameSelected.rating = parseFloat(gameSelected.rating.toString());
        gameSelected.comment = '';
        setGameRating(gameSelected);
      } else
        setGameRating({
          comment: '',
          id: -1,
          rating: 3,
          tpi_game_id: game.game_id,
        });
      setDisableRating(false);
    } else setDisableRating(false);
  }, [gameRatings]);

  const handlerClickFullScreen = () => {
    const element: any = document.querySelector('#ifrmGame');
    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        / Safari /;
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        / IE11 /;
        element.msRequestFullscreen();
      }
    }
  };
  const handlerGoBack = () => {
    setGameUrl('');
    setGame({});
    getUserBalanceAction();
  };
  const handleSave = () => {
    addUpdateGameRatingPlayerAction({ ...gameRating, game_id: game.game_id });
    setGameRating({ ...gameRating, comment: '' });
  };

  const [disableRating, setDisableRating] = useState(false);

  const handlerSaveFavorite = () => {
    if (game && game.game_id)
      addUpdateFavoriteGamePlayerAction({
        game_id: game.game_id,
        is_favorite: game.favorite_games_by_player_is_favorite != null ? !game.favorite_games_by_player_is_favorite : true,
      });
  };

  const styleIcon = {
    hearthIconFavorite: {
      width: '38%',
      height: '15%',
      filter: 'invert(27%) sepia(26%) saturate(3112%) hue-rotate(328deg) brightness(81%) contrast(92%)',
    },
    hearthIconNoFavorite: {
      width: '65%',
      height: '15%',
      filter: 'invert(42%) sepia(13%) saturate(2592%) hue-rotate(69deg) brightness(107%) contrast(83%)',
    },
    hearthIconFavoriteMobile: {
      width: '35%',
      height: '35%',
      filter: 'invert(27%) sepia(26%) saturate(3112%) hue-rotate(328deg) brightness(81%) contrast(92%)',
    },
    hearthIconNoFavoriteMobile: {
      width: '50%',
      height: '35%',
      filter: 'invert(42%) sepia(13%) saturate(2592%) hue-rotate(69deg) brightness(107%) contrast(83%)',
    },
    hearthIconWithout: {
      width: '65%',
      height: '35%',
      filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%)',
    },
    hearthIconWithoutMobile: {
      width: '50%',
      height: '35%',
      filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%)',
    },
  };

  const icons = {
    width: '25px',
    height: '25px',
    float: 'left',
    marginRight: isMobile ? '0px' : '10px',
  };
  const onloadIframe = () => {
    setShowIframe(true);
  };

  const changeVideoStatus = (status: number) => {
    setVideoStatus(status);
  };
  return (
    <div className='vip-fadeIn' style={{ marginTop: '-45px' }}>
      <div className={classes.bottonBack}>
        <div style={{ marginRight: 'auto' }}>
          <p className={classes.gameDesc}>
            {game.game_description} - {game.category_description}
          </p>
        </div>
        <Button
          onClick={handlerGoBack}
          style={{
            maxWidth: '165px',
            marginLeft: 'auto',
          }}
          className={classes.backBtn}
        >
          <FontAwesomeIcon style={{ marginRight: '10px' }} icon={['fad', 'arrow-circle-left']}></FontAwesomeIcon> Back
        </Button>
      </div>
      <Grid container style={{ margin: 'auto', width: '75%' }}>
        {!isMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={isMobile ? 12 : 4}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              className={`${useFreePlay ? classes.textBottomUseFreePlay : classes.textBottom} ${classes.fontFamilyBebas}`}
              style={{ borderBottom: 'none', borderRadius: '5px 5px 0 0', textAlign: 'center' }}
            >
              {useFreePlay
                ? 'Bonus mode active, All winnings will convert into your cash balance'
                : 'You are playing with your cash balance.'}
            </div>
          </div>
        </Grid>
        {!isMobile && (
          <Grid item xs={4} style={{ padding: '5px', textAlign: 'right' }}>
            {user && user.max_bet_casino != null && user.max_bet_casino > 0 && (
              <span className={classes.negativeAmounts}>Max Wager Amount {formatUSDNumber(parseInt(user.max_bet_casino.toString()))}</span>
            )}
          </Grid>
        )}
      </Grid>

      {!isMobile && (
        <div className={`${useFreePlay ? classes.container : classes.containerWithoutBonus} gameImageCont`}>
          {
            <>
              <span className={classes.containerControls}>
                <div className={classes.icons}>
                  <FontAwesomeIcon onClick={handlerGoBack} icon={['fad', 'times-circle']}></FontAwesomeIcon>
                </div>
                <div className={classes.icons} onClick={handlerClickFullScreen}>
                  <FontAwesomeIcon icon={['fad', 'expand']}></FontAwesomeIcon>
                </div>
                {!isBonusGame && (
                  <div className={classes.icons} onClick={handlerSaveFavorite}>
                    {game.favorite_games_by_player_is_favorite == null ||
                    (game.favorite_games_by_player_is_favorite != null && game.favorite_games_by_player_is_favorite == false) ? (
                      <VipHeartPlusIcon style={styleIcon.hearthIconWithout}></VipHeartPlusIcon>
                    ) : (
                      <>
                        <VipHeartMinusIcon style={styleIcon.hearthIconNoFavorite}></VipHeartMinusIcon>
                      </>
                    )}
                  </div>
                )}
              </span>
              <div className={classes.gameBonusTitle}>
                {useFreePlay && (
                  <>
                    <VipCrownIcon style={{ width: '35px', height: '35px' }} />
                    <span className='glow'>{'BONUS MODE'}</span>
                    <VipCrownIcon style={{ width: '35px', height: '35px' }} />
                  </>
                )}
              </div>
              <Grid container className={useFreePlay ? classes.game : classes.gameWithoutBonus}>
                <Fade in={!showIframe || videoStatus == 0} timeout={{ exit: 1000 }}>
                  <div className={classes.completeDiv} style={{ position: 'absolute' }}>
                    <video
                      style={{ width: '100%', height: '100%' }}
                      autoPlay
                      muted
                      onEnded={() => {
                        changeVideoStatus(1);
                        onloadIframe();
                      }}
                      onError={() => {
                        changeVideoStatus(1);
                      }}
                    >
                      <source src={imageCDNUrl + '/videos/casino/initgame4.webm'} type='video/webm' />
                      {/* New CDN */}
                    </video>
                  </div>
                </Fade>
                {gameURL && (
                  // <Fade in={showIframe && videoStatus == 1} timeout={{ enter: 2000 }}>
                  <iframe
                    className={classes.completeDiv}
                    id='ifrmGame'
                    src={gameURL}
                    allowFullScreen={true}
                    onLoad={() => {
                      changeVideoStatus(1);
                      onloadIframe();
                    }}
                  ></iframe>
                  // </Fade>
                )}
              </Grid>

              {/* <div>
                <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={7} md={7} style={{ textAlign: 'right' }}>
                        <FontAwesomeIcon
                          style={{ color: '#c8a964', height: '50px', width: '100px' }}
                          icon={['fad', 'gift']}
                        ></FontAwesomeIcon>
                      </Grid>
                      <Grid item xs={5} md={5} style={{ textAlign: 'left', display: 'table' }}>
                        <span className={classes.tableDisplay}>
                          <span className={`${classes.amounts} ${classes.fontFamilyBebas}`}>
                            ${GetAmountWithDecimals(userBalance.FreePlayAmount, 2)}
                          </span>
                          <br />
                          <span className={classes.fontFamilyBebas}>Bonus Funds</span>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={5} md={5} style={{ textAlign: 'right', display: 'table' }}>
                        <span className={classes.tableDisplay}>
                          <span className={`${classes.amounts} ${classes.fontFamilyBebas}`}>
                            ${GetAmountWithDecimals(userBalance.RealAvailBalance, 2)}
                          </span>
                          <br />
                          <span className={classes.fontFamilyBebas}>Cash Balance</span>
                        </span>
                      </Grid>
                      <Grid item xs={7} md={7} style={{ textAlign: 'left' }}>
                        <FontAwesomeIcon
                          style={{ color: '#c8a964', height: '50px', width: '100px' }}
                          icon={['fad', 'money-bill']}
                        ></FontAwesomeIcon>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div> */}
            </>
          }
        </div>
      )}
      {isMobile && isPortrait && (
        <>
          <div className={`${classes.containerMobile} gameImageCont`} id='ifrmGame'>
            <div className={classes.gameBonusTitle}>
              {useFreePlay && (
                <>
                  <VipCrownIcon style={{ width: '20px', height: '20px' }} />
                  <span className={`glowMobile ${classes.glowMobile}`}>{useFreePlay ? 'BONUS MODE' : ''}</span>
                  <VipCrownIcon style={{ width: '20px', height: '20px' }} />{' '}
                </>
              )}
            </div>
            {/* New CDN here */}
            <img
              src={`${imageCDNUrl + game.game_image}`}
              className={useFreePlay ? classes.gameImage : classes.gameImageWithoutBonus}
              style={{ height: gameHeight }}
            ></img>
            {/* <img
              src={`${'https://api.pdub.lv' + game.game_image}`}
              className={useFreePlay ? classes.gameImage : classes.gameImageWithoutBonus}
            ></img> */}
            <div>
              <Grid container style={{ marginTop: '10px', marginBottom: '10px' }}>
                {/* <Grid item xs={3} style={{ padding: '1%' }}>
                  <span>
                    <small className={`${classes.amountsMobile} ${classes.fontFamilyBebas}`}>
                      ${GetAmountWithDecimals(userBalance.FreePlayAmount, 2)}
                    </small>
                    <br />
                    <small className={classes.fontFamilyBebas}>Bonus Funds</small>
                  </span>
                </Grid> */}
                <Grid item xs={12} style={{ display: 'table' }}>
                  {/* <div className={`${useFreePlay ? classes.textBottomUseFreePlay : classes.textBottom} ${classes.fontFamilyBebas}`}>
                    {useFreePlay
                      ? 'Bonus mode active, All winnings will convert into your cash balance'
                      : 'You are playing with your cash balance.'}
                  </div> */}
                  <Button className={classes.bottonMobileOpenGamePortait} onClick={() => window.open(gameURL)} disabled={!gameURL}>
                    Open <span className={classes.gameLbl}>Game</span>
                  </Button>
                </Grid>
                {/* <Grid item xs={3} style={{ padding: '1%' }}>
                  <span>
                    <small className={`${classes.amountsMobile} ${classes.fontFamilyBebas}`}>
                      ${GetAmountWithDecimals(userBalance.RealAvailBalance, 2)}
                    </small>
                    <br />
                    <small className={classes.fontFamilyBebas}>Cash Balance</small>
                  </span>
                </Grid> */}
                <Grid item xs={12} style={{ marginTop: '0.5 rem' }}>
                  {user && user.max_bet_casino != null && user.max_bet_casino > 0 && (
                    <span className={classes.negativeAmounts}>
                      Max Wager Amount {formatUSDNumber(parseInt(user.max_bet_casino.toString()))}
                    </span>
                  )}
                </Grid>
                {/* <Grid item xs={12}>
                  <Button className={classes.bottonMobileOpenGame} onClick={() => window.open(gameURL)} disabled={!gameURL}>
                    Open Game
                  </Button>
                </Grid> */}
                {/* <Grid item xs={12}>
                  {!isBonusGame && (
                    <Button onClick={handlerSaveFavorite} className={classes.bottonMobileFavorite}>
                      {game.favorite_games_by_player_is_favorite == null ||
                      (game.favorite_games_by_player_is_favorite != null && game.favorite_games_by_player_is_favorite == false) ? (
                        <VipHeartPlusIcon style={styleIcon.hearthIconWithoutMobile}></VipHeartPlusIcon>
                      ) : (
                        <>
                          <VipHeartMinusIcon style={styleIcon.hearthIconNoFavoriteMobile}></VipHeartMinusIcon>
                        </>
                      )}
                    </Button>
                  )}
                </Grid> */}
              </Grid>
            </div>
          </div>
        </>
      )}

      {isMobile && !isPortrait && (
        <>
          <div
            className={`${useFreePlay ? classes.containerMobileLandscapeWithFreePlay : classes.containerMobileLandscape} gameImageCont`}
            id='ifrmGame'
          >
            <div className={classes.gameBonusTitle}>
              {useFreePlay && (
                <>
                  <VipCrownIcon style={{ width: '20px', height: '20px' }} />
                  <span className={`glowMobile ${classes.glowMobile}`}>{useFreePlay ? 'BONUS MODE' : ''}</span>
                  <VipCrownIcon style={{ width: '20px', height: '20px' }} />{' '}
                </>
              )}
            </div>
            <Grid container className={`${useFreePlay ? classes.containerBorder : ''}`}>
              <Grid item xs={8}>
                <img
                  src={`${imageCDNUrl + game.game_image}`}
                  className={useFreePlay ? classes.gameImageLandscape : classes.gameImageWithoutBonusLandscape}
                  style={{ height: useFreePlay ? '300px' : '295px' }}
                ></img>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1} style={{ height: '100%' }} alignItems='center'>
                  {/* <Grid item xs={12}>
                    Bonus Funds
                  </Grid>
                  <Grid item xs={12}>
                    <small className={`${classes.amountsMobile} ${classes.fontFamilyBebas}`}>
                      {formatUSDNumber(parseInt(userBalance.FreePlayAmount.toString()))}
                    </small>
                  </Grid>
                  <Grid item xs={12}>
                    Cash Balance
                  </Grid> 
                 <Grid item xs={12}>
                    <small className={`${classes.amountsMobile} ${classes.fontFamilyBebas}`}>
                      {formatUSDNumber(parseInt(userBalance.RealAvailBalance.toString()))}
                    </small>
                  </Grid> */}
                  {user && user.max_bet_casino != null && user.max_bet_casino > 0 && (
                    <>
                      <div style={{ width: '100%' }}>
                        <Grid item xs={12}>
                          Max Wager Amount
                        </Grid>
                        <Grid item xs={12}>
                          <small className={`${classes.amountsMobile} ${classes.fontFamilyBebas}`}>
                            {formatUSDNumber(parseInt(user.max_bet_casino.toString()))}
                          </small>
                        </Grid>
                      </div>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Button className={classes.bottonMobileOpenGame} onClick={() => window.open(gameURL)} disabled={!gameURL}>
                      <small>
                        Open <span className={classes.gameLbl}>Game</span>
                      </small>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      <div style={{ padding: '0 5%' }}>
        {!isBonusGame && (
          <div className={classes.ratingContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={classes.ratingFloatContainer}>
                <Typography component='legend' className={classes.ratingFloat}>
                  {gameRating.id != -1 ? 'Rating' : 'No Rating'}
                </Typography>
                <Rating
                  name='simple-controlled'
                  value={gameRating.rating}
                  disabled={disableRating}
                  className={classes.ratingFloat}
                  onChange={(event: any, newValue: any) => {
                    if (gameRating.rating != newValue) {
                      setGameRating({ ...gameRating, rating: newValue });
                      if (newValue > 2) {
                        addUpdateGameRatingPlayerAction({ ...gameRating, game_id: game.game_id, rating: newValue });
                        setDisableRating(true);
                      }
                    }
                  }}
                />
              </div>

              {isMobile && (
                <div>
                  {!isBonusGame && (
                    <Button onClick={handlerSaveFavorite} className={classes.bottonMobileFavorite}>
                      {game.favorite_games_by_player_is_favorite == null ||
                      (game.favorite_games_by_player_is_favorite != null && game.favorite_games_by_player_is_favorite == false) ? (
                        <VipHeartPlusIcon style={styleIcon.hearthIconWithoutMobile}></VipHeartPlusIcon>
                      ) : (
                        <>
                          <VipHeartMinusIcon style={styleIcon.hearthIconNoFavoriteMobile}></VipHeartMinusIcon>
                        </>
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div>
              {gameRating.rating && gameRating.rating <= 2 && (
                <>
                  <TextField
                    id='outlined-basic'
                    label='Add a comment about the game if you wish'
                    variant='outlined'
                    className={classes.ratingSend}
                    onKeyDown={(event: any) => setGameRating({ ...gameRating, comment: event.target.value })}
                  />

                  <Button variant='contained' color='primary' onClick={handleSave}>
                    Send
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
        <div className={classes.tagsContainer}>
          <Typography component='legend'>
            Tags:
            {game &&
              game.tags &&
              Array.isArray(game.tags) &&
              game.tags.length > 0 &&
              game.tags.map((text: string, index: number) => {
                return (
                  <span className={classes.tagLbl} key={`tags${index}`}>
                    #{text}
                  </span>
                );
              })}
          </Typography>
        </div>

        <div className={classes.featureContainer}>
          <Typography component='legend'>Features:</Typography>
          <div style={{ display: 'flex' }}>
            {game.has_gamble == true && (
              <div style={{ marginTop: '2px', marginBottom: '2px', marginRight: '10px' }}>
                <VipExchangeIcon style={icons} /> <span className={classes.spanIcon}> Gamble </span>
              </div>
            )}

            {game.has_jackpot == true && (
              <div style={{ marginTop: '2px', marginBottom: '2px', marginRight: '10px' }}>
                <VipCasinoMachineIcon style={icons} /> <span className={classes.spanIcon}> Jackpot</span>
              </div>
            )}

            {game.has_free_rounds == true && (
              <div style={{ marginTop: '2px', marginBottom: '2px', marginRight: '10px' }}>
                <VipFreeSpinIcon style={icons} /> <span className={classes.spanIcon}> Freespin</span>
              </div>
            )}
            {game.has_auto_spin == true && (
              <div style={{ marginTop: '2px', marginBottom: '2px', marginRight: '10px' }}>
                <VipAutoSpinIcon style={icons} />
                <span className={classes.spanIcon}> Autospin</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
