import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
// import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const SET_TV_URL = `SET_TV_URL${suffix}`;

export const setTvUrlAction = createAction<string>(SET_TV_URL);

const SET_TV_URL_SUCCESS = `SET_TV_URL_SUCCESS${suffix}`;
const setTvUrlSuccessAction = createAction<string>(SET_TV_URL_SUCCESS);

const SET_TV_URL_ERROR = `SET_TV_URL_ERROR${suffix}`;
const setTvUrlErrorAction = createAction<string>(SET_TV_URL_ERROR);

function* setTvUrlWatcher(): SagaIterator {
  yield takeEvery(SET_TV_URL, setTvUrlWorker);
}
mergeSaga(setTvUrlWatcher);

function* setTvUrlWorker(action: any): SagaIterator {
  try {
    yield put(setTvUrlSuccessAction(action.payload));
  } catch (e: any) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(setTvUrlErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [SET_TV_URL]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
    isSetUp: false,
  }),
  [SET_TV_URL_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    tvUrl: action.payload,
    isSetUp: true,
  }),
  [SET_TV_URL_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    isSetUp: true,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
