import Axios from 'axios';
import appSettingsData from '../app.settings.json';

type MapType = { [key: string]: any };

/**
 * Holds the application settings
 */
let appSettings: MapType;
appSettings = appSettingsData;

export function getAppSettingsName(): any {
  const settingsFile: any = {};
  settingsFile.async = false;
  switch (window.location.hostname) {
    case 'vipclub.lv': // producción
      settingsFile.url = 'app.settings.json';
      settingsFile.async = true;
      break;
    case 'wager-sandbox.vipclub.lv': // sandbox
      settingsFile.url = 'app.settings.sandbox.json';
      break;
    case 'localhost': // local
      settingsFile.url = 'app.settings.local.json';
      break;
    default:
      // just in case lol
      settingsFile.url = 'app.settings.json';
      break;
  }
  settingsFile.url + '?v=' + new Date().getTime();
  return settingsFile;
}

export function getAppSettings(): MapType {
  return appSettings;
}

/**
 * if default value is 'undefined' raises error if key is missing
 * @param key
 * @param defaultValue
 */
export function getAppSetting(key: string, defaultValue?: any): any {
  if (!appSettings[key]) {
    if (defaultValue === undefined) {
      throw new Error(`Required value for key: ${key} is missing in appSettings.`);
    }
    return defaultValue;
  }
  return appSettings[key];
}

export function setAppSettings(settings: MapType): MapType {
  appSettings = settings;
  return appSettings;
}

export function forceUpdateAppSettings() {
  Axios.get(getAppSettingsName().url)
    .then(response => {
      const appSettings = response.data;
      if (appSettings) setAppSettings(Object.freeze(appSettings));
    })
    .catch(error => {
      console.error(`Failed to load APP SETTINGS:`, error);
    });
}
