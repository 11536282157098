import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Card, CardActions, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ITransactionContest } from '../../../models/Cashier/Transactions/TransactionContest';
import { formatUSDNumber } from '../../functions/common';
declare global {
  interface Window {
    BXLiveChat: any;
  }
}

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: 'rgb(18, 18, 18) !important',
  },
  cardContent: {
    minHeight: '200px !important',
  },
  cardActions: {
    display: 'flow-root !important',
  },
  float: {
    float: 'right',
  },
  dialogContent: {
    padding: '20px !important',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    borderBottom: '2px solid #c8a964',
    padding: '10px',
  },
  textAlignC: {
    textAlign: 'center',
  },
  msg: {
    fontSize: '0.75rem',
    textAlign: 'justify',
  },
}));

export interface ExternalProps {
  data: ITransactionContest[];
  open: boolean;
  isLoading: boolean;
}

export interface ExternalActionProps {
  setOpen: (data: boolean) => void;
  handlerDialog: () => void;
  GetTransactionContestAction: () => void;
}

export const DialogTransactionContestComponent = (props: ExternalProps & ExternalActionProps) => {
  const { data, open, isLoading, handlerDialog, GetTransactionContestAction } = props;
  const classes = useStyles();

  useEffect(() => {
    GetTransactionContestAction();
  }, []);

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handlerDialog} aria-labelledby='max-width-dialog-title'>
      <DialogContent className={classes.dialogContent}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>Transaction Contests</Typography>
            {data &&
              data.map((contest: ITransactionContest, index: number) => {
                return (
                  <Grid container key={`tc${index}`} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' className={classes.textAlignC}>
                        {contest.contest.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>Payment Method:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>{contest.contest.payment_method.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>Transaction Type:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>{contest.contest.transaction_type.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>Min Amount:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>{formatUSDNumber(contest.contest.transaction_amount)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>Total Tickets Assigned:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>{contest.transactions.length}</Typography>
                    </Grid>

                    {contest.transactions.length > 0 && (
                      <Grid item xs={12} className={classes.msg}>
                        {contest.message}
                      </Grid>
                    )}
                    <Divider style={{ marginBottom: '1rem' }} />
                  </Grid>
                );
              })}

            {isLoading && data.length == 0 && <p style={{ textAlign: 'center' }}>Loading...</p>}
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button variant='contained' color='secondary' className={classes.float} onClick={handlerDialog} style={{ marginRight: '10px' }}>
              Close
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
