import { createAction } from 'redux-actions';
import { IAppSettingsDto } from '../../../models/common';
import { appSettingsSelectionReducer, appSettingsSelectionSliceeType } from './app-settings-reducer';

const suffix = `/${appSettingsSelectionReducer.sliceName}/app`;

const SET_APP_SETTINGS = `SET_APP_SETTINGS${suffix}`;

export const SetAppSettingAction = createAction<IAppSettingsDto>(SET_APP_SETTINGS);

const reduceHandlers = {
  [SET_APP_SETTINGS]: (slice: appSettingsSelectionSliceeType, action: any): appSettingsSelectionSliceeType => {
    return { ...slice, app_settings: action.payload };
  },
};

appSettingsSelectionReducer.addActionReducerMap(reduceHandlers);
