import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';
import { createRootReducer } from './reducers';
import { initMergeSaga } from './utils/redux-saga/merge-saga';
import { storeHelper } from './utils/redux/store-helper';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState: any, history: any): any {
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(createRootReducer(), initialState, composeWithDevTools(...enhancers));

  // Add a dictionary to keep track of the registered async reducers
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store.asyncReducers = {};

  // Make reducers hot reloadable
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(reducerModule => {
        const newRootReducer = reducerModule.createRootReducer(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          store.asyncReducers,
        );
        store.replaceReducer(newRootReducer);
      });
    });
  }

  initMergeSaga(sagaMiddleware);
  storeHelper.init(store, createRootReducer);

  return store;
}
