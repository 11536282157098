/**
 * @export
 *  Providers
 */
export enum SocketMessageEnum {
  MESSAGE = 1,
  SHOWDIALOG = 2,
  SHOWDIALOGANDOPENCHAT = 3,
  SNACKBAR = 3,
}

export enum SocketRooms {
  VIPPUBLIC = 'VIPPUBLIC',
}

export enum EventActionEnum {
  OPEN_IDENTITY_DIALOG = 'open_identity_dialog',
  GO_TO_PROFILE = 'go_to_profile',
  PLACE_BET_FROM_CASHIER_DIALOG = 'place_bet_from_cashier_dialog',
  CLOSE_AD_DIALOG = 'close_ad_dialog',
  BALANCE_CHANGE = 'balance-change',
  MESSAGE_TO_CLIENT = 'message-to-client',
  SET_MESSAGES = 'set-Messages',
  GET_MESSAGES_RECEIVED = 'get-messages-received',
  SET_MESSAGES_BY_DATE = 'set-Messages-by-date',
  MARK_AS_READ_MESSAGE = 'mark-as-read-message',
  MARK_AS_READ_MESSAGE_BY_DATE = 'mark-as-read-message-by-date',
  SIGNUP_STATUS = 'signup-status',
  CLOSE_COGNITO_IDENTITY = 'close-cognito-identity',
  MESSAGE_TO_CLIENT_API = 'message-to-client-api',
  CALL_TOUR = 'call-tour',
  UPDATE_MAX_WAGER = 'update-max-wager',
  SET_CUSTOM_DATA = 'setCustomData',
  MESSAGE_NOTIFICATION = 'message-notification',
  GET_INFORMATION = 'get-information',
  LOG_OUT = 'log-out',
}
