import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { AppAction } from '../../../types';
import { ISocketIdentityStatusResponse } from '../../../models/Socket/socket-messages';

const suffix = `/${userReducer.sliceName}/app`;

const SET_RIGHTS = `SET_RIGHTS${suffix}`;
export const SetRights = createAction<ISocketIdentityStatusResponse>(SET_RIGHTS);

const SET_RIGHTS_ERROR = `SET_RIGHTS_ERROR${suffix}`;
const setRightsErrorAction = createAction<string>(SET_RIGHTS_ERROR);
const SET_RIGHTS_SUCCESS = `SET_RIGHTS_SUCCESS${suffix}`;
const setRightsSuccessAction = createAction<ISocketIdentityStatusResponse>(SET_RIGHTS_SUCCESS);

function* setRightsWatcher(): SagaIterator {
  yield takeEvery(SET_RIGHTS, loginWorker);
}
mergeSaga(setRightsWatcher);

function* loginWorker(action: Action<ISocketIdentityStatusResponse>): SagaIterator {
  try {
    yield put(setRightsSuccessAction(action.payload));
  } catch (e: any) {
    yield put(setRightsErrorAction(e));
  }
}

const reduceHandlers = {
  [SET_RIGHTS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [SET_RIGHTS_SUCCESS]: (slice: UserSliceType, action: Action<ISocketIdentityStatusResponse>): UserSliceType => {
    if (slice.isLoggedIn) {
      const loggedInUser = { ...slice.loggedInUser };
      if (loggedInUser && loggedInUser.rights && action.payload.is_success && action.payload.rights) {
        loggedInUser.rights = action.payload.rights;
        return {
          ...slice,
          loggedInUser: loggedInUser,
        };
      }
    }
    return {
      ...slice,
    };
  },
  [SET_RIGHTS_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
