import { connect } from 'react-redux';
import { NavBar, NavBarProps } from './tab-bar.component';
import { loggedInUserSelector } from './../../api/user/user.selector';
function mapStateToProps(state: any): NavBarProps {
  return {
    pathname: state.router.location.pathname,
    user: loggedInUserSelector(state),
  };
}

export const NavBarContainer = connect(mapStateToProps, {})(NavBar);
