import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { TransactionContestReducer, TransactionContestSliceType } from '../transaction-contest.reducer';

const suffix = `/${TransactionContestReducer.sliceName}/app`;

const SET_OPEN_DIALOG_TRANSACTION_CONTEST = `SET_OPEN_DIALOG_TRANSACTION_CONTEST${suffix}`;

export const SetOpenDialogTransactionContestAction = createAction<boolean>(SET_OPEN_DIALOG_TRANSACTION_CONTEST);

const SET_OPEN_DIALOG_TRANSACTION_CONTEST_SUCCESS = `SET_OPEN_DIALOG_TRANSACTION_CONTEST_SUCCESS${suffix}`;
const SetOpenDialogTransactionContestSuccessAction = createAction<boolean>(SET_OPEN_DIALOG_TRANSACTION_CONTEST_SUCCESS);

const SET_OPEN_DIALOG_TRANSACTION_CONTEST_ERROR = `SET_OPEN_DIALOG_TRANSACTION_CONTEST_ERROR${suffix}`;
const SetOpenDialogTransactionContestErrorAction = createAction<string>(SET_OPEN_DIALOG_TRANSACTION_CONTEST_ERROR);

function* SetOpenDialogTransactionContestWatcher(): SagaIterator {
  yield takeEvery(SET_OPEN_DIALOG_TRANSACTION_CONTEST, SetOpenDialogTransactionContestWorker);
}
mergeSaga(SetOpenDialogTransactionContestWatcher);

function* SetOpenDialogTransactionContestWorker(data: Action<boolean>): SagaIterator {
  try {
    yield put(SetOpenDialogTransactionContestSuccessAction(data.payload));
  } catch (e: any) {
    yield put(SetOpenDialogTransactionContestErrorAction(e));
  }
}

const reduceHandlers = {
  [SET_OPEN_DIALOG_TRANSACTION_CONTEST]: (slice: TransactionContestSliceType): TransactionContestSliceType => ({
    ...slice,
  }),
  [SET_OPEN_DIALOG_TRANSACTION_CONTEST_SUCCESS]: (
    slice: TransactionContestSliceType,
    action: Action<boolean>,
  ): TransactionContestSliceType => ({
    ...slice,
    openDialog: action.payload,
  }),
  [SET_OPEN_DIALOG_TRANSACTION_CONTEST_ERROR]: (slice: TransactionContestSliceType): TransactionContestSliceType => ({
    ...slice,
  }),
};

TransactionContestReducer.addActionReducerMap(reduceHandlers);
