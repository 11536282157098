import { createSelector } from 'reselect';
import { rolloverReducer, RolloverSliceType } from './rollover.reducer';

export const RolloverSliceSelector = (state: any): any => state[rolloverReducer.sliceName];

export const isLoadingRollOverSliceSelector = createSelector(
  RolloverSliceSelector,
  (rolloverSlice: RolloverSliceType) => rolloverSlice.isLoading,
);

export const rolloverSelector = createSelector(RolloverSliceSelector, (rolloverSlice: RolloverSliceType) => rolloverSlice.rollover);
