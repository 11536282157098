import { clone } from '../clone';

export function axiosResult(result?: any, error?: any, httpCode?: number): [number, any] {
  let code;
  let data;
  if (error) {
    code = httpCode || 400;
    data = error;
  } else {
    code = httpCode || 200;
    data = clone(result);
  }
  return [code, data];
}

export function axiosPostResult(result?: any, error?: any, httpCode?: number): [number, any] {
  let code;
  let data;
  if (error) {
    code = httpCode || 400;
    data = error;
  } else {
    code = httpCode || 200;
    data = clone(result);
    data = JSON.parse(data);
    data = { ...data, id: Math.floor(Math.random() * 1000) };
  }

  return [code, data];
}
