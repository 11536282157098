/**
 * @export
 * Order by steps, car selections.
 */
export enum BET_STEP {
  INIT = 0,
  //   COMPILE = 1,
  COMPILED = 2,
  ACEPT_CHANGES = 3,
  CONFIRM = 4,
  POSTED = 5,
}
