import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';

const suffix = `/${userReducer.sliceName}/app`;

const CHANGE_USER_BALANCE = `CHANGE_USER_BALANCE${suffix}`;

export const changeUserBalanceAction = createAction(CHANGE_USER_BALANCE);

const CHANGE_USER_BALANCE_SUCCESS = `CHANGE_USER_BALANCE_SUCCESS${suffix}`;
const changeUserBalanceSuccessAction = createAction<any[]>(CHANGE_USER_BALANCE_SUCCESS);

const CHANGE_USER_BALANCE_ERROR = `CHANGE_USER_BALANCE_ERROR${suffix}`;
const changeUserBalanceErrorAction = createAction<string>(CHANGE_USER_BALANCE_ERROR);

function* changeUserBalanceWatcher(): SagaIterator {
  yield takeLatest(CHANGE_USER_BALANCE, changeUserBalanceWorker);
}
mergeSaga(changeUserBalanceWatcher);

function* changeUserBalanceWorker(action: any): SagaIterator {
  try {
    yield put(changeUserBalanceSuccessAction(action.payload));
  } catch (e: any) {
    yield put(changeUserBalanceErrorAction(e));
  }
}
const reduceHandlers = {
  [CHANGE_USER_BALANCE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [CHANGE_USER_BALANCE_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    userBalance: { ...(slice.userBalance ?? {}), ...action.payload },
  }),
  [CHANGE_USER_BALANCE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
