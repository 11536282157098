import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, getSessionUser, userReducer } from '../../user.reducer';
import { mergeSaga } from '../../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../../utils/vip-api';
import { API_PATH } from '../../../../constants';

const suffix = `/${userReducer.sliceName}/app`;

const MARK_TOUR_AS_DISPLAYED = `MARK_TOUR_AS_DISPLAYED${suffix}`;
export const MarkTourAsDisplayedAction = createAction(MARK_TOUR_AS_DISPLAYED);

const MARK_TOUR_AS_DISPLAYED_SUCCESS = `MARK_TOUR_AS_DISPLAYED_SUCCESS${suffix}`;
const MarkTourAsDisplayedSuccessAction = createAction(MARK_TOUR_AS_DISPLAYED_SUCCESS);

const MARK_TOUR_AS_DISPLAYED_ERROR = `MARK_TOUR_AS_DISPLAYED_ERROR${suffix}`;
const MarkTourAsDisplayedErrorAction = createAction<any>(MARK_TOUR_AS_DISPLAYED_ERROR);

function* MarkTourAsDisplayedWatcher(): SagaIterator {
  yield takeEvery(MARK_TOUR_AS_DISPLAYED, MarkTourAsDisplayedWorker);
}
mergeSaga(MarkTourAsDisplayedWatcher);

function* MarkTourAsDisplayedWorker(): SagaIterator {
  try {
    yield call(MarkTourAsDisplayedAPI);
    yield put(MarkTourAsDisplayedSuccessAction());

    const session = getSessionUser();

    if (session && session.username) {
      localStorage.setItem(`tour_display_${session.username}`, '1');
    }
  } catch (e: any) {
    yield put(MarkTourAsDisplayedErrorAction(e));
  }
}

const MarkTourAsDisplayedAPI = (): Request => {
  return vipApi(API_PATH.USER.MARK_TOUR_AS_DISPLAYED, 'post', {});
};

const reduceHandlers = {
  [MARK_TOUR_AS_DISPLAYED]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [MARK_TOUR_AS_DISPLAYED_SUCCESS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [MARK_TOUR_AS_DISPLAYED_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
