import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../../user.reducer';
import { mergeSaga } from '../../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../../utils/vip-api';
import { API_PATH } from '../../../../constants';

const suffix = `/${userReducer.sliceName}/app`;

const GET_TOUR_STEPS = `GET_TOUR_STEPS${suffix}`;
export const getTourStepsAction = createAction(GET_TOUR_STEPS);

const GET_TOUR_STEPS_SUCCESS = `GET_TOUR_STEPS_SUCCESS${suffix}`;
const getTourStepsSuccessAction = createAction<any[]>(GET_TOUR_STEPS_SUCCESS);

const GET_TOUR_STEPS_ERROR = `GET_TOUR_STEPS_ERROR${suffix}`;
const getTourStepsErrorAction = createAction<any>(GET_TOUR_STEPS_ERROR);

function* getTourStepsWatcher(): SagaIterator {
  yield takeEvery(GET_TOUR_STEPS, getTourStepsWorker);
}
mergeSaga(getTourStepsWatcher);

function* getTourStepsWorker(): SagaIterator {
  try {
    const result: any = yield call(getTourStepsApi);
    yield put(getTourStepsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getTourStepsErrorAction(e));
  }
}

const getTourStepsApi = (): Request => {
  return vipApi(API_PATH.USER.GET_ALL_TOURS, 'get', {});
};

const reduceHandlers = {
  [GET_TOUR_STEPS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_TOUR_STEPS_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    tourSteps: action.payload,
  }),
  [GET_TOUR_STEPS_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
