import { createAndMergeSliceReducer } from '../redux/create-and-merge-slice-reducer';

const sliceName = 'axiosSlice';

export interface AxiosSliceType {
  isBusy: boolean;
}

export const initialState: AxiosSliceType = {
  isBusy: false,
};

export const axiosReducer = createAndMergeSliceReducer(sliceName, initialState);
