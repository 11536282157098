import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { AppAction } from '../../../types';
import { SocketMessageChangeMaxBetAmount } from '../../../models/Socket/socket-request';

const suffix = `/${userReducer.sliceName}/app`;

const SET_MAX_BET_AMOUNTS = `SET_MAX_BET_AMOUNTS${suffix}`;
export const SetMaxBetAmountAction = createAction<SocketMessageChangeMaxBetAmount>(SET_MAX_BET_AMOUNTS);

const SET_MAX_BET_AMOUNTS_ERROR = `SET_MAX_BET_AMOUNTS_ERROR${suffix}`;
const SetMaxBetAmountActionErrorAction = createAction<string>(SET_MAX_BET_AMOUNTS_ERROR);
const SET_MAX_BET_AMOUNTS_SUCCESS = `SET_MAX_BET_AMOUNTS_SUCCESS${suffix}`;
const SetMaxBetAmountActionSuccessAction = createAction<SocketMessageChangeMaxBetAmount>(SET_MAX_BET_AMOUNTS_SUCCESS);

function* SetMaxBetAmountActionWatcher(): SagaIterator {
  yield takeEvery(SET_MAX_BET_AMOUNTS, loginWorker);
}
mergeSaga(SetMaxBetAmountActionWatcher);

function* loginWorker(action: Action<SocketMessageChangeMaxBetAmount>): SagaIterator {
  try {
    yield put(SetMaxBetAmountActionSuccessAction(action.payload));
  } catch (e: any) {
    yield put(SetMaxBetAmountActionErrorAction(e));
  }
}

const reduceHandlers = {
  [SET_MAX_BET_AMOUNTS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [SET_MAX_BET_AMOUNTS_SUCCESS]: (slice: UserSliceType, action: Action<SocketMessageChangeMaxBetAmount>): UserSliceType => {
    if (slice.isLoggedIn) {
      const loggedInUser = { ...slice.loggedInUser };
      if (loggedInUser) {
        loggedInUser.max_bet_casino = action.payload.max_bet_casino;
        loggedInUser.max_bet_sports = action.payload.max_bet_sports;
        return {
          ...slice,
          loggedInUser: loggedInUser,
        };
      }
    }
    return {
      ...slice,
    };
  },
  [SET_MAX_BET_AMOUNTS_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
