import { createSelector } from 'reselect';
import { promotionSelectionReducer, PromotionSelectionSliceType } from './promotion.reducer';

export const promotionSelectionSliceSelector = (state: any): any => state[promotionSelectionReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  promotionSelectionSliceSelector,
  (promotionSelectionSlice: PromotionSelectionSliceType) => promotionSelectionSlice.isLoading,
);

export const promotionsSelector = createSelector(
  promotionSelectionSliceSelector,
  (promotionSelectionSlice: PromotionSelectionSliceType) => promotionSelectionSlice.promotions,
);
