import { PROVIDERS } from '../enum/providers/providers';
import { Right } from '../models/player';

export const IsValidRight = (pData: any, pRigth: PROVIDERS[]): boolean => {
  return pData && pData.rights && pData.rights.find((x: any) => pRigth.indexOf(x.id) != -1);
};

export const IsValidRightByTpiProviderCategory = (pData: any, pTpiCategoryTypeId: number[]): boolean => {
  if (!pData && !pData.rights) return false;

  return pData.rights.find((x: Right) => x.tpi_category_id && pTpiCategoryTypeId.indexOf(x.tpi_category_id) != -1);
};
