import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { SocketMessagesByDateDto } from '../../../models/Socket/socket-messages';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { SocketReducer, SocketSliceType } from '../socket-reducer';

const suffix = `/${SocketReducer.sliceName}/app`;

const MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE = `MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE${suffix}`;

export const MarkNotifiedAsReadPlayerByDateAction = createAction<SocketMessagesByDateDto>(MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE);

const MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS = `MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS${suffix}`;
const MarkNotifiedAsReadPlayerByDateSuccessAction = createAction(MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS);

const MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_ERROR = `MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_ERROR${suffix}`;
const MarkNotifiedAsReadPlayerByDateErrorAction = createAction<string>(MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_ERROR);

function* MarkNotifiedAsReadPlayerByDateWatcher(): SagaIterator {
  yield takeEvery(MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE, MarkNotifiedAsReadPlayerByDateWorker);
}

mergeSaga(MarkNotifiedAsReadPlayerByDateWatcher);

function* MarkNotifiedAsReadPlayerByDateWorker(action: Action<SocketMessagesByDateDto>): SagaIterator {
  try {
    yield put(MarkNotifiedAsReadPlayerByDateSuccessAction(action.payload));
  } catch (e: any) {
    yield put(MarkNotifiedAsReadPlayerByDateErrorAction(e));
  }
}

const reduceHandlers = {
  [MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),

  [MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_SUCCESS]: (
    slice: SocketSliceType,
    action: Action<SocketMessagesByDateDto>,
  ): SocketSliceType => {
    const msgs = slice.socketMessagesByDate;

    msgs.forEach(element => {
      if (element.id == action.payload.id) element.isNotified = true;
    });
    let resp: SocketMessagesByDateDto[] = [];
    let resp_pending: SocketMessagesByDateDto[] = [];
    try {
      if (localStorage.getItem('notifications_by_date')) {
        resp = JSON.parse(localStorage.getItem('notifications_by_date') ?? '');
        if (resp && resp.length && resp.length > 0) {
          const checkIfNotificationsExists = resp.find(x => x.id == action.payload.id);
          if (!checkIfNotificationsExists) resp.push(action.payload);
          else {
            resp = resp.filter(x => x.id != action.payload.id);
            resp.push(action.payload);
          }
        } else {
          resp.push(action.payload);
        }
      } else {
        resp.push(action.payload);
      }
      localStorage.setItem('notifications_by_date', JSON.stringify(resp));
    } catch (error) {
      console.log('MarkNotifiedAsReadPlayerByDatePlayerAction', { error });
    }

    try {
      if (action.payload.repeat_times > 1) {
        const tmpAction = { ...action.payload };
        tmpAction.next_date = new Date(new Date(tmpAction.date).getTime() + tmpAction.repeat_frecuency * 60000).toString();
        tmpAction.repeat_times_do_it = 1;
        if (localStorage.getItem('notifications_by_date_pending')) {
          resp_pending = JSON.parse(localStorage.getItem('notifications_by_date_pending') ?? '');
          if (resp_pending && resp_pending.length && resp_pending.length > 0) {
            const checkIfNotificationsExists = resp_pending.find(x => x.id == action.payload.id);
            if (!checkIfNotificationsExists) resp_pending.push(tmpAction);
            else {
              resp_pending = resp_pending.filter(x => x.id != action.payload.id);
              resp_pending.push(tmpAction);
            }
          } else {
            resp_pending.push(tmpAction);
          }
        } else {
          resp_pending.push(tmpAction);
        }
      }
    } catch (error) {
      console.log('MarkNotifiedAsReadPlayerByDatePlayerAction Pending Notifications', { error });
    }

    localStorage.setItem('notifications_by_date_pending', JSON.stringify(resp_pending));

    return {
      ...slice,
      socketMessagesByDate: msgs,
    };
  },

  [MARK_NOTIFIED_SO_MESSAGES_PLAYER_BY_DATE_ERROR]: (slice: SocketSliceType): SocketSliceType => ({
    ...slice,
  }),
};

SocketReducer.addActionReducerMap(reduceHandlers);
