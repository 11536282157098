import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { AppAction } from '../../../types';

const suffix = `/${userReducer.sliceName}/app`;

const SET_CHAT_INITIALIZED = `SET_CHAT_INITIALIZED${suffix}`;
export const SetChatInitializedAction = createAction<boolean>(SET_CHAT_INITIALIZED);

const SET_CHAT_INITIALIZED_ERROR = `SET_CHAT_INITIALIZED_ERROR${suffix}`;
const SetChatInitializedErrorAction = createAction<string>(SET_CHAT_INITIALIZED_ERROR);
const SET_CHAT_INITIALIZED_SUCCESS = `SET_CHAT_INITIALIZED_SUCCESS${suffix}`;
const SetChatInitializedSuccessAction = createAction<boolean>(SET_CHAT_INITIALIZED_SUCCESS);

function* SetChatInitializedWatcher(): SagaIterator {
  yield takeEvery(SET_CHAT_INITIALIZED, loginWorker);
}
mergeSaga(SetChatInitializedWatcher);

function* loginWorker(action: Action<boolean>): SagaIterator {
  try {
    yield put(SetChatInitializedSuccessAction(action.payload));
  } catch (e: any) {
    yield put(SetChatInitializedErrorAction(e));
  }
}

const reduceHandlers = {
  [SET_CHAT_INITIALIZED]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [SET_CHAT_INITIALIZED_SUCCESS]: (slice: UserSliceType, action: Action<boolean>): UserSliceType => {
    return {
      ...slice,
      chatInitialized: action.payload,
    };
  },
  [SET_CHAT_INITIALIZED_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
