import React from 'react';

export const VipFootBall = (props: any) => {
  const { style } = props;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={style} width='32px' height='37px' viewBox='0 0 48 48'>
      <defs>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7c'
          cx='21.44'
          cy='22.74'
          r='25.04'
          gradientTransform='rotate(-43.28 32.91 18.191) scale(1 .6)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#a9665f'></stop>
          <stop offset='.26' stopColor='#995751'></stop>
          <stop offset='.63' stopColor='#7a3c37'></stop>
          <stop offset='.96' stopColor='#51201d'></stop>
          <stop offset='1' stopColor='#4c1c1a'></stop>
        </radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7d'
          cx='20.02'
          cy='19.28'
          r='13.03'
          gradientTransform='matrix(.65 .76 -1.21 1.03 30.27 -15.76)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#ebbbb5'></stop>
          <stop offset='.09' stopColor='#ebbbb5' stopOpacity='.93'></stop>
          <stop offset='.27' stopColor='#ebbcb6' stopOpacity='.75'></stop>
          <stop offset='.53' stopColor='#ebbcb6' stopOpacity='.45'></stop>
          <stop offset='.84' stopColor='#ebbdb7' stopOpacity='.05'></stop>
          <stop offset='.87' stopColor='#ebbdb7' stopOpacity='0'></stop>
        </radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7e'
          cx='28.28'
          cy='33.85'
          r='31.86'
          gradientTransform='matrix(-.63027 .77638 -.48135 -.39077 62.41 25.14)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.84' stopColor='#4c1c1a'></stop>
          <stop offset='.87' stopColor='#4c1c1a' stopOpacity='.76'></stop>
          <stop offset='.92' stopColor='#4b1b19' stopOpacity='.35'></stop>
          <stop offset='.97' stopColor='#4b1b19' stopOpacity='.09'></stop>
          <stop offset='1' stopColor='#4b1b19' stopOpacity='0'></stop>
        </radialGradient>
        <radialGradient id='MOzrFTcNavuSoIX1cE8m7f' cx='27.4' cy='4.34' r='22.46' gradientUnits='userSpaceOnUse'>
          <stop offset='.05' stopColor='#ccb8b8'></stop>
          <stop offset='.17' stopColor='#ddcbcb'></stop>
          <stop offset='.33' stopColor='#ecdddd'></stop>
          <stop offset='.44' stopColor='#f2e4e4'></stop>
          <stop offset='.5' stopColor='#ebdcdc'></stop>
          <stop offset='.61' stopColor='#d9c7c6'></stop>
          <stop offset='.73' stopColor='#bca4a3'></stop>
          <stop offset='.88' stopColor='#947472'></stop>
          <stop offset='1' stopColor='#6c4542'></stop>
        </radialGradient>
        <radialGradient id='MOzrFTcNavuSoIX1cE8m7a' cx='30.41' cy='13.22' r='12.74' gradientUnits='userSpaceOnUse'>
          <stop offset='0' stopColor='#f7f9f9' stopOpacity='.8'></stop>
          <stop offset='.1' stopColor='#f7f9f9' stopOpacity='.71'></stop>
          <stop offset='.54' stopColor='#f7f9f9' stopOpacity='.33'></stop>
          <stop offset='.85' stopColor='#f7f9f9' stopOpacity='.09'></stop>
          <stop offset='1' stopColor='#f7f9f9' stopOpacity='0'></stop>
        </radialGradient>
        <radialGradient id='MOzrFTcNavuSoIX1cE8m7g' cx='5.04' cy='24.1' r='21.77' gradientUnits='userSpaceOnUse'>
          <stop offset='0' stopColor='#ccb8b8'></stop>
          <stop offset='.17' stopColor='#e1d8d8'></stop>
          <stop offset='.34' stopColor='#f1f0f0'></stop>
          <stop offset='.44' stopColor='#f7f9f9'></stop>
          <stop offset='.5' stopColor='#f1f1f1'></stop>
          <stop offset='.59' stopColor='#e0dcdb'></stop>
          <stop offset='.71' stopColor='#c6b9b8'></stop>
          <stop offset='.84' stopColor='#a08887'></stop>
          <stop offset='.99' stopColor='#714b48'></stop>
          <stop offset='1' stopColor='#6c4542'></stop>
        </radialGradient>
        <radialGradient id='MOzrFTcNavuSoIX1cE8m7h' cx='12.13' cy='27.25' r='12.19'></radialGradient>
        <linearGradient id='MOzrFTcNavuSoIX1cE8m7i' x1='6.11' x2='37.57' y1='36.72' y2='8.19' gradientUnits='userSpaceOnUse'>
          <stop offset='0' stopColor='#6e3c37'></stop>
          <stop offset='.03' stopColor='#78443f'></stop>
          <stop offset='.14' stopColor='#915652'></stop>
          <stop offset='.25' stopColor='#a2645f'></stop>
          <stop offset='.36' stopColor='#ad6b67'></stop>
          <stop offset='.5' stopColor='#b06e6a'></stop>
          <stop offset='.62' stopColor='#ac6b67'></stop>
          <stop offset='.75' stopColor='#9e615c'></stop>
          <stop offset='.88' stopColor='#89504c'></stop>
          <stop offset='1' stopColor='#6e3c37'></stop>
        </linearGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7j'
          cx='34.61'
          cy='29.43'
          r='33.1'
          fy='37.198'
          gradientTransform='rotate(-39.71 53.918 22.46) scale(1 .53)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.9' stopColor='#fef5e3'></stop>
          <stop offset='.91' stopColor='#fcf2df'></stop>
          <stop offset='.93' stopColor='#f7e9d2'></stop>
          <stop offset='.94' stopColor='#f0ddc0'></stop>
          <stop offset='.95' stopColor='#e6cfac'></stop>
          <stop offset='.95' stopColor='#e3caa6'></stop>
          <stop offset='.95' stopColor='#e6cfae'></stop>
          <stop offset='.96' stopColor='#eedcc1'></stop>
          <stop offset='.96' stopColor='#f3e5cf'></stop>
          <stop offset='.96' stopColor='#f6ead7'></stop>
          <stop offset='.96' stopColor='#f7ecd9'></stop>
          <stop offset='.96' stopColor='#f7ecd8'></stop>
          <stop offset='.97' stopColor='#f5e9d0'></stop>
          <stop offset='.98' stopColor='#f5e8ce'></stop>
          <stop offset='1' stopColor='#f2e2c2'></stop>
        </radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7k'
          cx='10.89'
          cy='30.63'
          r='10.06'
          gradientTransform='matrix(.762 .64759 -.47274 .55625 17.08 6.54)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.81' stopColor='#e3caa6'></stop>
          <stop offset='.82' stopColor='#ebd6b8'></stop>
          <stop offset='.83' stopColor='#f3e4cb'></stop>
          <stop offset='.85' stopColor='#f9eed9'></stop>
          <stop offset='.86' stopColor='#fdf3e1'></stop>
          <stop offset='.9' stopColor='#fef5e3'></stop>
          <stop offset='.93' stopColor='#fdf3e0'></stop>
          <stop offset='.95' stopColor='#f8ecd6'></stop>
          <stop offset='.97' stopColor='#f1e0c5'></stop>
          <stop offset='.99' stopColor='#e6cfae'></stop>
          <stop offset='1' stopColor='#e3caa6'></stop>
        </radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7b'
          cx='10.05'
          cy='31.66'
          r='14.85'
          gradientTransform='matrix(.75 .66 -.58 .66 20.82 4.02)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.89' stopColor='#e3caa6'></stop>
          <stop offset='.9' stopColor='#ebd6b8'></stop>
          <stop offset='.9' stopColor='#f3e4cb'></stop>
          <stop offset='.91' stopColor='#f9eed9'></stop>
          <stop offset='.93' stopColor='#fdf3e1'></stop>
          <stop offset='.95' stopColor='#fef5e3'></stop>
          <stop offset='.97' stopColor='#fdf3e0'></stop>
          <stop offset='.98' stopColor='#f8ecd6'></stop>
          <stop offset='.99' stopColor='#f1e0c5'></stop>
          <stop offset='1' stopColor='#e6cfae'></stop>
          <stop offset='1' stopColor='#e3caa6'></stop>
        </radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7l'
          cx='12.87'
          cy='28.8'
          r='15.33'
          gradientTransform='rotate(39.63 8.132 27.094) scale(1 .88)'
        ></radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7m'
          cx='11.77'
          cy='29.83'
          r='14.66'
          gradientTransform='rotate(46.18 23.053 34.633) scale(1 1.32)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.92' stopColor='#e3caa6'></stop>
          <stop offset='.93' stopColor='#ecd9bb'></stop>
          <stop offset='.94' stopColor='#f6e8d1'></stop>
          <stop offset='.96' stopColor='#fcf2de'></stop>
          <stop offset='.97' stopColor='#fef5e3'></stop>
          <stop offset='.98' stopColor='#fdf3e0'></stop>
          <stop offset='.98' stopColor='#f8ecd6'></stop>
          <stop offset='.99' stopColor='#f1e0c5'></stop>
          <stop offset='1' stopColor='#e6cfae'></stop>
          <stop offset='1' stopColor='#e3caa6'></stop>
        </radialGradient>
        <radialGradient
          id='MOzrFTcNavuSoIX1cE8m7n'
          cx='15.73'
          cy='26.77'
          r='14.18'
          gradientTransform='rotate(46.18 25.862 31.08) scale(1 1.32)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.92' stopColor='#e3caa6'></stop>
          <stop offset='.93' stopColor='#e9d4b4'></stop>
          <stop offset='.94' stopColor='#f5e6ce'></stop>
          <stop offset='.96' stopColor='#fcf1de'></stop>
          <stop offset='.97' stopColor='#fef5e3'></stop>
          <stop offset='.98' stopColor='#fdf3e0'></stop>
          <stop offset='.98' stopColor='#f8ecd6'></stop>
          <stop offset='.99' stopColor='#f1e0c5'></stop>
          <stop offset='1' stopColor='#e6cfae'></stop>
          <stop offset='1' stopColor='#e3caa6'></stop>
        </radialGradient>
      </defs>
      <g data-name='American Football'>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7c)'
          d='M41.32,24.74A21.33,21.33,0,0,1,38.5,30c-.13.18-.26.36-.4.53a22.59,22.59,0,0,1-3.81,3.83,34,34,0,0,1-11.56,5.83A32.67,32.67,0,0,1,15,41.46c-3.25.14-5.87-.35-7.21-1.42a1.88,1.88,0,0,1-.39-.35v0a1.82,1.82,0,0,1-.33-.47A1.13,1.13,0,0,1,7,39a1,1,0,0,1-.33-.21.49.49,0,0,1-.12-.13c-.84-.93-1.07-3.37-.61-6.48a33.16,33.16,0,0,1,2-7.29,29.78,29.78,0,0,1,7.52-11.23A26.48,26.48,0,0,1,26.12,7.87a25.72,25.72,0,0,1,8.36-.94,12.66,12.66,0,0,1,4.16.84,5.41,5.41,0,0,1,.59.29l.19.12.11.08a1.79,1.79,0,0,1,.59.61,0,0,0,0,1,0,0,1.45,1.45,0,0,1,.78.55A.18.18,0,0,1,41,9.5c.85,1.08,1.61,3.46,1.75,6.47A22.31,22.31,0,0,1,41.32,24.74Z'
        ></path>
        <g opacity='.1'>
          <path
            fill='#3a1616'
            d='M39.75,28.06c-.17.29-.34.58-.53.86A.51.51,0,0,1,39,28.5a.5.5,0,0,1,.5-.5A.51.51,0,0,1,39.75,28.06Z'
          ></path>
          <path fill='#3a1616' d='M38.5,30c-.13.18-.26.36-.4.53H38a.5.5,0,1,1,.5-.54Z'></path>
          <circle cx='36.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='35' cy='33' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M34,34.5v0h0l-.58.44h0a.5.5,0,0,1-.4-.49.5.5,0,0,1,1,0Z'></path>
          <path fill='#3a1616' d='M32.4,35.69c-.27.19-.55.37-.84.55A.48.48,0,0,1,31.5,36a.5.5,0,0,1,.5-.5A.51.51,0,0,1,32.4,35.69Z'></path>
          <circle cx='40.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='39' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='37.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='36' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='34.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='33' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='31.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='30' cy='36' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M29,37.5a.45.45,0,0,1,0,.2c-.2.1-.39.2-.58.28h0A.48.48,0,0,1,28,37.5a.5.5,0,0,1,1,0Z'></path>
          <circle cx='41.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='40' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='38.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='37' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='35.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='34' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='32.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='31' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='29.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='28' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='26.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M24.83,39.47h0A.5.5,0,1,1,25.5,39a.48.48,0,0,1-.06.24Z'></path>
          <path fill='#3a1616' d='M42.63,19c0,.32-.07.65-.12,1h0a.5.5,0,0,1,0-1Z'></path>
          <circle cx='41' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='39.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='38' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='36.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='35' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='33.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='32' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='30.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='29' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='27.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='26' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='24.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='23' cy='39' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M22,40.36c-.32.09-.65.17-1,.24a.31.31,0,0,1,0-.1.5.5,0,0,1,1-.14Z'></path>
          <circle cx='42' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='40.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='39' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='37.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='36' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='34.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='33' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='31.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='30' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='28.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='27' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='25.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='24' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='22.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='21' cy='39' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M20,40.5a.57.57,0,0,1-.14.36l-.55.1h0a.48.48,0,0,1-.3-.46.5.5,0,0,1,1,0Z'></path>
          <circle cx='41.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='40' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='38.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='37' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='35.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='34' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='32.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='31' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='29.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='28' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='26.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='25' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='23.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='22' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='20.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='19' cy='39' r='.5' fill='#3a1616'></circle>
          <circle cx='17.5' cy='40.5' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M42.52,14h0a.5.5,0,0,1-.15-1C42.41,13.33,42.47,13.66,42.52,14Z'></path>
          <circle cx='41' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='39.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='38' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='36.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='35' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='33.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='32' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='30.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='29' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='27.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='26' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='24.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='23' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='21.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='20' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='18.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='17' cy='39' r='.5' fill='#3a1616'></circle>
          <circle cx='15.5' cy='40.5' r='.5' fill='#3a1616'></circle>
          <path
            fill='#3a1616'
            d='M42.22,12.45a.54.54,0,0,1-.22.05.5.5,0,0,1-.5-.5.48.48,0,0,1,.44-.49C42,11.8,42.13,12.12,42.22,12.45Z'
          ></path>
          <circle cx='40.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='39' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='37.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='36' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='34.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='33' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='31.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='30' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='28.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='27' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='25.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='24' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='22.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='21' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='19.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='18' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='16.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='15' cy='39' r='.5' fill='#3a1616'></circle>
          <circle cx='13.5' cy='40.5' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M41.74,10.94a.48.48,0,0,1-.24.06.5.5,0,0,1-.19-1A6.31,6.31,0,0,1,41.74,10.94Z'></path>
          <circle cx='40' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='38.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='37' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='35.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='34' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='32.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='31' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='29.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='28' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='26.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='25' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='23.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='22' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='20.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='19' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='17.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='16' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='14.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='13' cy='39' r='.5' fill='#3a1616'></circle>
          <circle cx='11.5' cy='40.5' r='.5' fill='#3a1616'></circle>
          <circle cx='39.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='38' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='36.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='35' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='33.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='32' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='30.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='29' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='27.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='26' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='24.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='23' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='21.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='20' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='18.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='17' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='15.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='14' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='12.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='11' cy='39' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M10,40.5a.5.5,0,0,1-.29.46h0a5.32,5.32,0,0,1-.64-.23h0A.59.59,0,0,1,9,40.5a.5.5,0,0,1,1,0Z'></path>
          <circle cx='39' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='37.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='36' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='34.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='33' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='31.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='30' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='28.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='27' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='25.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='24' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='22.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='21' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='19.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='18' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='16.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='15' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='13.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='12' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='10.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='9' cy='39' r='.5' fill='#3a1616'></circle>
          <path
            fill='#3a1616'
            d='M38.85,7.87A.52.52,0,0,1,38.5,8a.5.5,0,0,1-.5-.47,5.32,5.32,0,0,1,.64.24A1.36,1.36,0,0,1,38.85,7.87Z'
          ></path>
          <circle cx='37' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='35.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='34' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='32.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='31' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='29.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='28' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='26.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='25' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='23.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='22' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='20.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='19' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='17.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='16' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='14.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='13' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='11.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='10' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='8.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <path
            fill='#3a1616'
            d='M7.5,39a.51.51,0,0,1-.27.45,1.65,1.65,0,0,1-.15-.25A1.13,1.13,0,0,1,7,39a1,1,0,0,1-.33-.21l0-.06A.5.5,0,0,1,7.5,39Z'
          ></path>
          <path fill='#3a1616' d='M37,7.5a.5.5,0,1,1-.82-.39c.26,0,.51.08.75.14A.43.43,0,0,1,37,7.5Z'></path>
          <circle cx='35' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='33.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='32' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='30.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='29' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='27.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='26' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='24.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='23' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='21.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='20' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='18.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='17' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='15.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='14' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='12.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='11' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='9.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <circle cx='8' cy='36' r='.5' fill='#3a1616'></circle>
          <circle cx='6.5' cy='37.5' r='.5' fill='#3a1616'></circle>
          <circle cx='34.5' cy='7.5' r='.5' fill='#3a1616'></circle>
          <circle cx='33' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='31.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='30' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='28.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='27' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='25.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='24' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='22.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='15' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='13.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='12' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='10.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='9' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='7.5' cy='34.5' r='.5' fill='#3a1616'></circle>
          <path
            fill='#3a1616'
            d='M6.5,36a.5.5,0,0,1-.5.5.51.51,0,0,1-.25-.06h0c0-.26,0-.53-.06-.83A.52.52,0,0,1,6,35.5.5.5,0,0,1,6.5,36Z'
          ></path>
          <circle cx='32.5' cy='7.5' r='.5' fill='#3a1616'></circle>
          <circle cx='31' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='29.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='28' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='23.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='22' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='20.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='19' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='17.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='16' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='14.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='13' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='11.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='10' cy='30' r='.5' fill='#3a1616'></circle>
          <circle cx='8.5' cy='31.5' r='.5' fill='#3a1616'></circle>
          <circle cx='7' cy='33' r='.5' fill='#3a1616'></circle>
          <circle cx='30.5' cy='7.5' r='.5' fill='#3a1616'></circle>
          <circle cx='29' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='27.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='26' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='24.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='23' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='21.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='20' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='18.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='17' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='15.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='14' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='12.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='11' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='9.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='8' cy='30' r='.5' fill='#3a1616'></circle>
          <path
            fill='#3a1616'
            d='M7,31.5a.5.5,0,0,1-.5.5A.5.5,0,0,1,6,31.6v0c0-.1,0-.21,0-.32A.49.49,0,0,1,6.5,31,.5.5,0,0,1,7,31.5Z'
          ></path>
          <path fill='#3a1616' d='M29,7.5a.5.5,0,0,1-1,0,.19.19,0,0,1,0-.08c.31-.07.62-.12.93-.17A.51.51,0,0,1,29,7.5Z'></path>
          <circle cx='27' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='25.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='24' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='22.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='21' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='19.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='18' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='16.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='15' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='13.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='12' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='10.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <circle cx='9' cy='27' r='.5' fill='#3a1616'></circle>
          <circle cx='7.5' cy='28.5' r='.5' fill='#3a1616'></circle>
          <circle cx='25' cy='9' r='.5' fill='#3a1616'></circle>
          <circle cx='23.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='22' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='20.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='19' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='17.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='16' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='14.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='13' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='11.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <circle cx='10' cy='24' r='.5' fill='#3a1616'></circle>
          <circle cx='8.5' cy='25.5' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M7.5,27a.5.5,0,0,1-.5.5H7l.3-.92A.51.51,0,0,1,7.5,27Z'></path>
          <path fill='#3a1616' d='M23.5,9a.5.5,0,0,1-.5.5.48.48,0,0,1-.48-.37c.3-.13.61-.26.92-.37A.48.48,0,0,1,23.5,9Z'></path>
          <circle cx='21.5' cy='10.5' r='.5' fill='#3a1616'></circle>
          <circle cx='20' cy='12' r='.5' fill='#3a1616'></circle>
          <circle cx='18.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='17' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='15.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <circle cx='14' cy='18' r='.5' fill='#3a1616'></circle>
          <circle cx='12.5' cy='19.5' r='.5' fill='#3a1616'></circle>
          <circle cx='11' cy='21' r='.5' fill='#3a1616'></circle>
          <circle cx='9.5' cy='22.5' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M20,10.5a.5.5,0,0,1-.5.5.51.51,0,0,1-.34-.13l.82-.49h0A.49.49,0,0,1,20,10.5Z'></path>
          <path fill='#3a1616' d='M18.5,12a.5.5,0,0,1-1,0v0l.65-.46A.5.5,0,0,1,18.5,12Z'></path>
          <circle cx='16.5' cy='13.5' r='.5' fill='#3a1616'></circle>
          <circle cx='15' cy='15' r='.5' fill='#3a1616'></circle>
          <circle cx='13.5' cy='16.5' r='.5' fill='#3a1616'></circle>
          <path fill='#3a1616' d='M12.5,18a.5.5,0,0,1-1,.11h0l.43-.59H12A.5.5,0,0,1,12.5,18Z'></path>
          <path fill='#3a1616' d='M11,19.5a.5.5,0,0,1-.5.5.41.41,0,0,1-.19,0c.17-.28.35-.56.53-.84A.53.53,0,0,1,11,19.5Z'></path>
        </g>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7d)'
          d='M41.32,24.74A21.33,21.33,0,0,1,38.5,30c-.13.18-.26.36-.4.53a22.59,22.59,0,0,1-3.81,3.83,34,34,0,0,1-11.56,5.83A32.67,32.67,0,0,1,15,41.46c-3.25.14-5.87-.35-7.21-1.42a1.88,1.88,0,0,1-.39-.35v0a1.82,1.82,0,0,1-.33-.47A1.13,1.13,0,0,1,7,39a1,1,0,0,1-.33-.21.49.49,0,0,1-.12-.13c-.84-.93-1.07-3.37-.61-6.48a33.16,33.16,0,0,1,2-7.29,29.78,29.78,0,0,1,7.52-11.23A26.48,26.48,0,0,1,26.12,7.87a25.72,25.72,0,0,1,8.36-.94,12.66,12.66,0,0,1,4.16.84,5.41,5.41,0,0,1,.59.29l.19.12.11.08a1.79,1.79,0,0,1,.59.61,0,0,0,0,1,0,0,1.45,1.45,0,0,1,.78.55A.18.18,0,0,1,41,9.5c.85,1.08,1.61,3.46,1.75,6.47A22.31,22.31,0,0,1,41.32,24.74Z'
          opacity='.3'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7e)'
          d='M39.53,8.26c-3-.12-8.06,1.63-15.65,8.19C11.17,27.45,7.26,36.83,7,39a1,1,0,0,1-.33-.21.49.49,0,0,1-.12-.13c-.84-.93-1.07-3.37-.61-6.48a33.16,33.16,0,0,1,2-7.29,29.78,29.78,0,0,1,7.52-11.23A26.48,26.48,0,0,1,26.12,7.87a25.72,25.72,0,0,1,8.36-.94,12.66,12.66,0,0,1,4.16.84,5.41,5.41,0,0,1,.59.29l.19.12Z'
          opacity='.6'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7f)'
          d='M41.32,24.74A57.17,57.17,0,0,0,26.12,7.87a26,26,0,0,1,7.48-1,59.26,59.26,0,0,1,9.12,9.61A22.15,22.15,0,0,1,41.32,24.74Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7a)'
          d='M41.32,24.74A57.17,57.17,0,0,0,26.12,7.87a26,26,0,0,1,7.48-1,59.26,59.26,0,0,1,9.12,9.61A22.15,22.15,0,0,1,41.32,24.74Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7g)'
          d='M22.73,40.15A32.67,32.67,0,0,1,15,41.46a59,59,0,0,0-9.11-9.3,33.16,33.16,0,0,1,2-7.29A64.27,64.27,0,0,1,22.73,40.15Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7h)'
          d='M22.73,40.15A32.67,32.67,0,0,1,15,41.46a59,59,0,0,0-9.11-9.3,33.16,33.16,0,0,1,2-7.29A64.27,64.27,0,0,1,22.73,40.15Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7i)'
          d='M41,9.5,40,9.36a6.5,6.5,0,0,0-1-.07c-3.28,0-7.39,2.4-14.19,8.3C12.86,28,9,36.6,7.88,39.83L7.81,40a1.88,1.88,0,0,1-.39-.35v0a1.82,1.82,0,0,1-.33-.47A1.13,1.13,0,0,1,7,39a1,1,0,0,1-.33-.21.49.49,0,0,1-.12-.13c.52-2.42,4.4-11.64,17-22.57C31,9.67,35.77,8,38.64,7.77a5.41,5.41,0,0,1,.59.29l.19.12.11.08a1.79,1.79,0,0,1,.59.61,0,0,0,0,1,0,0,1.45,1.45,0,0,1,.78.55A.18.18,0,0,1,41,9.5Z'
        ></path>
        <path
          fill='#4c1c1a'
          d='M40.12,8.87c-3.64-.55-7.89,1.67-15.59,8.34C12.86,27.31,8.74,35.79,7.41,39.67L7,39c.29-2.15,4.2-11.53,16.91-22.53,7.59-6.56,12.61-8.31,15.65-8.19A1.79,1.79,0,0,1,40.12,8.87Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7j)'
          d='M15.53,26.54a1,1,0,0,1-.6-.21,1,1,0,0,1-.2-1.4c2.18-2.87,8.29-9.11,12.64-11.88a1.14,1.14,0,0,1,1.47.37,1,1,0,0,1-.31,1.38,62.73,62.73,0,0,0-12.2,11.34A1,1,0,0,1,15.53,26.54Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7k)'
          d='M16.26,27.91a8.4,8.4,0,0,0-3-2.64c-.61-.26.39-1.54.76-1.37A10.5,10.5,0,0,1,17.5,27C17.75,27.29,16.62,28.41,16.26,27.91Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7b)'
          d='M19.41,24.67a29.12,29.12,0,0,0-3.4-3c-.4-.19.47-1.44.9-1.2a13.08,13.08,0,0,1,3.63,3.23C20.86,24.11,19.72,25,19.41,24.67Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7l)'
          d='M22.38,21.56a14,14,0,0,0-3.32-2.92c-.62-.26.47-1.55.91-1.31a12.15,12.15,0,0,1,3.7,3.27C24.07,21.12,22.73,22,22.38,21.56Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7m)'
          d='M25.54,18.63a9.77,9.77,0,0,0-3.11-2.83c-.54-.24.47-1.47.86-1.29a9.91,9.91,0,0,1,3.54,3.22C27.19,18.25,25.86,19.07,25.54,18.63Z'
        ></path>
        <path
          fill='url(#MOzrFTcNavuSoIX1cE8m7n)'
          d='M29.09,15.89a12.05,12.05,0,0,0-1.3-1.51A7.32,7.32,0,0,0,26,13.13c-.57-.28.46-1.37,1-1.14a6.27,6.27,0,0,1,2,1.38A13.87,13.87,0,0,1,30.29,15C30.6,15.43,29.37,16.27,29.09,15.89Z'
        ></path>
      </g>
    </svg>
  );
};
