declare global {
  interface Window {
    BXLiveChat: any;
  }
}

export class BitrixService {
  private static instance: BitrixService;
  private static chatId: string;
  private static userHash: string;
  public static getInstance(): BitrixService {
    if (!BitrixService.instance) {
      BitrixService.instance = new BitrixService();
    }
    return BitrixService.instance;
  }

  openChat() {
    if (window.BXLiveChat) {
      window.BXLiveChat.open();
    }
  }

  sendMessage(msg: string) {
    if (
      window.BXLiveChat != null &&
      window.BXLiveChat.__privateMethods__ != null &&
      window.BXLiveChat.__privateMethods__.ready != null &&
      window.BXLiveChat.__privateMethods__.ready == true
    ) {
      window.BXLiveChat.__privateMethods__.addMessage(msg);
    }
  }
}

export const BitrixServiceClient = BitrixService.getInstance();
