import React from 'react';

export const VipFreeSpinIcon = (props: any) => {
  const { style } = props;
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' style={style}>
      <circle style={{ fill: '#EFF2FA' }} cx='256' cy='256' r='256' />
      <circle style={{ fill: '#D7DEED' }} cx='256' cy='256' r='238.345' />
      <path style={{ fill: '#FFE669' }} d='M256,256L64.899,145.655c19.371-33.513,47.244-61.385,80.756-80.756L256,256z' />
      <path style={{ fill: '#FFCD64' }} d='M256,35.31V256L145.655,64.899C178.104,46.018,215.786,35.31,256,35.31z' />
      <path style={{ fill: '#FFAA64' }} d='M366.345,64.899L256,256V35.31C296.214,35.31,333.896,46.018,366.345,64.899z' />
      <path style={{ fill: '#FF8C64' }} d='M447.101,145.655L256,256L366.345,64.899C399.857,84.27,427.73,112.142,447.101,145.655z' />
      <path style={{ fill: '#FF6469' }} d='M476.69,256H256l191.101-110.345C465.982,178.104,476.69,215.785,476.69,256z' />
      <path style={{ fill: '#E18CAF' }} d='M476.69,256c0,40.214-10.708,77.895-29.589,110.345L256,256H476.69z' />
      <path style={{ fill: '#C3B4FA' }} d='M447.101,366.345c-19.371,33.512-47.244,61.385-80.756,80.756L256,256L447.101,366.345z' />
      <path style={{ fill: '#7DD2F0' }} d='M366.345,447.101C333.896,465.982,296.214,476.69,256,476.69V256L366.345,447.101z' />
      <path style={{ fill: '#7DE6C3' }} d='M256,256v220.69c-40.214,0-77.896-10.708-110.345-29.589L256,256z' />
      <path style={{ fill: '#96E393' }} d='M256,256L145.655,447.101c-33.512-19.371-61.385-47.244-80.756-80.756L256,256z' />
      <path style={{ fill: '#AFE164' }} d='M256,256L64.899,366.345C46.018,333.895,35.31,296.214,35.31,256H256z' />
      <path style={{ fill: '#D7E664' }} d='M256,256H35.31c0-40.215,10.708-77.896,29.589-110.345L256,256z' />
      <circle style={{ fill: '#EFF2FA' }} cx='256' cy='256' r='39.724' />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M336.833,212.619l31.21-68.662l-68.662,31.21l17.24,7.729L206.474,293.043
	c-3.626-0.197-7.317,1.074-10.087,3.843c-5.171,5.171-5.171,13.555,0,18.727c5.171,5.172,13.555,5.171,18.727,0
	c2.77-2.77,4.041-6.461,3.843-10.087l110.147-110.147L336.833,212.619z'
      />
      <circle style={{ fill: '#D7DEED' }} cx='256' cy='256' r='17.655' />
      <g style={{ opacity: '0.5' }}>
        <circle style={{ fill: '#FFFFFF' }} cx='439.327' cy='305.126' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='72.674' cy='206.874' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='305.126' cy='72.674' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='206.874' cy='439.327' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='390.201' cy='390.201' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='121.799' cy='121.799' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='390.201' cy='121.799' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='121.799' cy='390.201' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='72.674' cy='305.126' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='439.327' cy='206.874' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='206.874' cy='72.674' r='13.241' />
        <circle style={{ fill: '#FFFFFF' }} cx='305.126' cy='439.327' r='13.241' />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
