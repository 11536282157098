import { createSliceHelper, SliceHelperType } from './create-slice-helper';
import { ActionType, LeafReducerType, ActionReducerMapType } from './reducers.types';

export function createLeafReducer(parentSliceHelper: null | SliceHelperType, sliceName: string, initialSlice: any): LeafReducerType {
  const sliceHelper = createSliceHelper(parentSliceHelper, sliceName, initialSlice);

  let actionReducerMap: ActionReducerMapType = {};

  const addActionReducerMap = (newActionReducerMap: ActionReducerMapType): void => {
    actionReducerMap = {
      ...actionReducerMap,
      ...newActionReducerMap,
    };
  };

  const reducer = (slice: any = initialSlice, action: ActionType<any>): any => {
    if (action.type in actionReducerMap) {
      return actionReducerMap[action.type](slice, action);
    }
    return slice;
  };

  return Object.assign(reducer, {
    addActionReducerMap,
    ...sliceHelper,
  });
}
