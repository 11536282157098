/**
 * @export
 *  Providers
 */
export enum PROVIDERS {
  BETSOFT = 1,
  LIVE_WAGERING = 2,
  ESPORTS = 3,
  DGS_CASINO = 4,
  HORSES = 5,
  CONCEPT = 6,
  PLATINUM = 7,
  PROPS_BUILDER = 8,
  RACEBOOK = 9,
  VIG = 10,
}
