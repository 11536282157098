import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
import { TransactionContestReducer, TransactionContestSliceType } from '../transaction-contest.reducer';
import { IGetAll } from '../../../models/get-all';
import { ITransactionContest } from '../../../models/Cashier/Transactions/TransactionContest';

const suffix = `/${TransactionContestReducer.sliceName}/app`;

const GET_TRANSACTION_CONTEST = `GET_TRANSACTION_CONTEST${suffix}`;

export const GetTransactionContestAction = createAction(GET_TRANSACTION_CONTEST);

const GET_TRANSACTION_CONTEST_SUCCESS = `GET_TRANSACTION_CONTEST_SUCCESS${suffix}`;
const GetTransactionContestSuccessAction = createAction<ITransactionContest[]>(GET_TRANSACTION_CONTEST_SUCCESS);

const GET_TRANSACTION_CONTEST_ERROR = `GET_TRANSACTION_CONTEST_ERROR${suffix}`;
const GetTransactionContestErrorAction = createAction<string>(GET_TRANSACTION_CONTEST_ERROR);

function* GetTransactionContestWatcher(): SagaIterator {
  yield takeEvery(GET_TRANSACTION_CONTEST, GetTransactionContestWorker);
}
mergeSaga(GetTransactionContestWatcher);

function* GetTransactionContestWorker(): SagaIterator {
  try {
    const result: IGetAll<ITransactionContest> = yield call(GetTransactionContestApi);
    yield put(GetTransactionContestSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(GetTransactionContestErrorAction(e));
  }
}

const GetTransactionContestApi = (): Request => {
  return vipApi(API_PATH.TRANSACTIONS.CONTEST, 'get', {});
};

const reduceHandlers = {
  [GET_TRANSACTION_CONTEST]: (slice: TransactionContestSliceType): TransactionContestSliceType => ({
    ...slice,
    isLoading: true,
    transactionContest: [],
  }),
  [GET_TRANSACTION_CONTEST_SUCCESS]: (
    slice: TransactionContestSliceType,
    action: Action<ITransactionContest[]>,
  ): TransactionContestSliceType => ({
    ...slice,
    isLoading: false,
    transactionContest: action.payload,
  }),
  [GET_TRANSACTION_CONTEST_ERROR]: (slice: TransactionContestSliceType): TransactionContestSliceType => ({
    ...slice,
    isLoading: false,
    transactionContest: [],
  }),
};

TransactionContestReducer.addActionReducerMap(reduceHandlers);
