import { createSelector } from 'reselect';
import { tpiSliceReducer, tpiSliceType } from './tpi.reducer';

export const tpiSliceSelector = (state: any): any => state[tpiSliceReducer.sliceName];
export const isLoadingSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.isLoading);
export const casinoGamesSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.casinoGames);
export const casinoCategoriesSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.casinoCategories);
export const gameUrlSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.gameUrl);
export const liveWageringUrlSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.liveWageringUrl);
export const eSportsWageringUrlSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.eSportsWageringUrl);
export const dgsCasinoUrlSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.dgsCasinoUrl);
export const horsesUrlSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.horsesUrl);
export const gameRatingsSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.gameRatings);
export const gameFavoritedUpdatedSliceSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.gameFavoriteUpdated);
export const casinoBonusesSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.casinoBonuses);
export const casinoBonusSelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.casinoBonus);
export const casinoLobbySelector = createSelector(tpiSliceSelector, (tpiSlice: tpiSliceType) => tpiSlice.casinoLobby);
