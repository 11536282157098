import { createSelector } from 'reselect';
import { MessageSelectionReducer, MessageSliceType } from './message.reducer';

export const messageSliceSelector = (state: any): any => state[MessageSelectionReducer.sliceName];

export const isLoadingMessagesSliceSelector = createSelector(
  messageSliceSelector,
  (messageSlice: MessageSliceType) => messageSlice.isLoading,
);

export const messageSelector = createSelector(messageSliceSelector, (messageSlice: MessageSliceType) => messageSlice.messages);
