export interface IMessageMarkAsRead {
  id: number;
}

export interface IMessageByUser {
  id: number;
  user_id: number;
  message_type_id: number;
  message_custom_id?: number;
  message_user_status_id: number;
  message_user_sub_status_id: number;
  title: string;
  content: string;
  status: number;
  sent_at: string;
  expires_at: string;
  viewed_at: string | null;
  created_at: string | null;
}

export enum MessageStatusEnum {
  PENDING = 1,
  SENT = 2,
  READ = 3,
  NOT_SENT = 4,
  IN_PROGRESS = 5,
  EDIT = 6,
  SCHEDULED = 7,
  FAILED = 8,
  DELETED = 9,
  EXPIRED = 10,
}
