import { IReferralPlayerData, RAFUserDto } from '../../models/RAF/raf';
import { ILoginResponse } from '../../models/login';
import { EmptyIdentityDialogFrame, IIdentityDialog, IIdentityDialogFrame } from '../../models/users';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'userSlice';

export interface UserSliceType {
  agentData: any | null;
  bonusHistory: any;
  chatInitialized: boolean;
  cognito_identity_result: number;
  error?: string;
  identity: string | null;
  identityDialog: IIdentityDialog;
  identityDialogFrame: IIdentityDialogFrame;
  inviteCode: string | null;
  inviteCodeInvalid: boolean;
  inviteDetails: any;
  inviteSuccess: boolean | null;
  isInternalResetPasswordSuccess: boolean;
  isLoading: boolean;
  isLoadingImpersonate: boolean;
  isLoadingUserInfo: boolean;
  isLoggedIn: boolean;
  isPseudoLoggedIn: boolean;
  isVerificationSuccess: boolean | null;
  is_credit: boolean;
  is_saving_friend_email: boolean;
  is_verified: boolean;
  loggedInUser?: any;
  needCashierForward: boolean;
  rafPlayers: IReferralPlayerData | null;
  request_default_invite_code: boolean;
  resetPasswordSuccess: boolean;
  runTour: boolean;
  sendOtpSuccess: boolean;
  shareable_url: string | null;
  signupSuccess: boolean;
  signupUsername: string | null;
  token: string | null;
  tourSteps: any;
  transactionHistory: any;
  userBalance: any;
  userHistory: any;
  userInfo: ILoginResponse | null;
  userRaf: RAFUserDto | null;
  userRafCode: any;
  verifyOtpSuccess: boolean;
  verifyPhone: boolean | null;
  window_opened: Window | null;
}

export const getSessionUser = () => {
  const user = localStorage.getItem('user');
  if (user !== '' && user !== null) {
    return JSON.parse(user);
  } else {
    return {};
  }
};

const getSessionToken = () => {
  const token = localStorage.getItem('token');
  if (token !== null && token !== '') {
    return true;
  } else {
    return false;
  }
};

export const initialState: UserSliceType = {
  agentData: null,
  bonusHistory: {},
  chatInitialized: false,
  cognito_identity_result: -1,
  error: undefined,
  identity: '',
  identityDialog: { message: null, open: false, result: false, playerProfileSettingsPaymentMethodBonuses: [] },
  identityDialogFrame: EmptyIdentityDialogFrame,
  inviteCode: null,
  inviteCodeInvalid: false,
  inviteDetails: {},
  inviteSuccess: null,
  isInternalResetPasswordSuccess: false,
  isLoading: false,
  isLoadingImpersonate: false,
  isLoadingUserInfo: false,
  isLoggedIn: getSessionToken(),
  isPseudoLoggedIn: false,
  isVerificationSuccess: null,
  is_credit: false,
  is_saving_friend_email: false,
  is_verified: false,
  loggedInUser: getSessionUser(),
  needCashierForward: true,
  rafPlayers: null,
  request_default_invite_code: false,
  resetPasswordSuccess: false,
  runTour: false,
  sendOtpSuccess: false,
  shareable_url: null,
  signupSuccess: false,
  signupUsername: null,
  token: null,
  tourSteps: {},
  transactionHistory: {},
  userBalance: {},
  userHistory: {},
  userInfo: null,
  userRaf: null,
  userRafCode: null,
  verifyOtpSuccess: false,
  verifyPhone: null,
  window_opened: null,
};

export const userReducer = createAndMergeSliceReducer(sliceName, initialState);
