import { IAppSettingsDto, IEmptyAppSettingsDto } from '../../../models/common';
import { createAndMergeSliceReducer } from '../create-and-merge-slice-reducer';

const sliceName = 'appSettingsSelectionSlice';

export interface appSettingsSelectionSliceeType {
  app_settings: IAppSettingsDto;
}

export const initialState: appSettingsSelectionSliceeType = {
  app_settings: IEmptyAppSettingsDto,
};

export const appSettingsSelectionReducer = createAndMergeSliceReducer(sliceName, initialState);
