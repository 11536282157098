import { createLeafReducer } from './create-leaf-reducer';
import { storeHelper } from './store-helper';
import { LeafReducerType } from './reducers.types';

/**
 * Creates and merges a new Top Leaf Reducer for a given sliceName
 */
export function createAndMergeSliceReducer(sliceName: string, initialSlice: any): LeafReducerType {
  const reducer = createLeafReducer(null, sliceName, initialSlice);
  storeHelper.mergeTopReducer(reducer);
  return reducer;
}
